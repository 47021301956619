export const GREETINGS = [
  'Hello',
  'Hi',
  'Hey',
  'Howdy',
  'Hola',
  'Bonjour',
  'Aloha',
  'नमस्ते',
  'Salaam',
  'Halo',
  'Hej',
  'Nǐ hǎo',
  'Olá',
  'こんにちは',
  'Sawubona',
  'Sannu',
  'Merhaba',
  'Sveiki',
  'Zdravo',
  'Hallå',
  'Yasou',
  'สวัสดี',
  'Sannu',
  'Hallå',
  'Yasou',
  '你好',
  'Sannu',
]