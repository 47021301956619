import { Anchor, Checkbox, Container, Group, Paper, Text, Title, Tooltip } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { useLocalStorage } from '@mantine/hooks'
import { IconCalendar, IconDatabase } from '@tabler/icons'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Empty from '../../components/Empty'
import PaymentNeed from '../../components/PaymentNeed'
import SupabaseTable from '../../components/SupabaseTable'
import TextWithEmoji from '../../components/TextWithEmoji'
import usePayment from '../../hooks/usePayment'
import { supabase } from '../../services/supabase'

export default function () {
  const [selectedProfile] = useLocalStorage<Record<string, any> | null>({
    key: 'selected-profile', defaultValue: null })
  const [date, setDate] = useState<Date | null>(dayjs().utc().toDate())
  const navigate = useNavigate()
  const [valid, setValid] = useState<boolean>(true)
  const { check } = usePayment()

  useEffect(() => {
    if (selectedProfile?.app_id) {
      check().then((value: any) => setValid(value))
    }
  }, [selectedProfile])

  useEffect(() => {
    if (selectedProfile?.role && selectedProfile?.app?.presences_read && !(selectedProfile?.app?.presences_read || []).includes(selectedProfile?.role)) {
      navigate(`/presence/${selectedProfile?.id}`)
    }
  }, [selectedProfile?.app?.presences_read])

  return valid ? <Container mb="xl" mt="xl" style={{ position: 'relative' }}>
    <Paper p="lg" withBorder mb="xl" shadow="sm" radius="md">
      <Group position="apart">
        <div>
          <Title order={2}>
            Presence
          </Title>
          <Text color="dimmed" italic size="sm" mt="xs">
            The data is updated every 5 minutes.
          </Text>
        </div>
        <DatePicker radius="md" clearable={false} maxDate={new Date()} icon={<IconCalendar size={20} />} value={date} onChange={setDate} />
      </Group>
    </Paper>

    <SupabaseTable
      query={(opt: any) => supabase
        .from('presences')
        .select(`
          last_status,
          last_status_text,
          last_status_emoji,
          est_active_duration,
          last_checked_at,
          profile_id,
          details,
          profiles!inner (
            id,
            app_id,
            name,
            integration_profile_data
          )
        `, opt?.checkCount ? { count: 'estimated', head: true } : undefined)
        .eq('profiles.app_id', selectedProfile?.app_id)
        .eq('date', dayjs(date).format('YYYY-MM-DD'))}
      dependencies={[date, selectedProfile]}
      columns={[
        {
          key: 'profiles.name',
          label: 'Name',
          minWidth: 310,
          sort: {
            foreignTable: 'profiles',
            key: 'name'
          },
          render: (value: any, row: any) => row.last_status_emoji ? <Tooltip withArrow position="right" label={<TextWithEmoji text={`${row.last_status_emoji} ${(row.last_status_text || '').replace(' (via Clockwise)', '').replace(' • Google Calendar', '')}`} useTooltip={false} />}>
            <Anchor component={Link} to={`/presence/${row.profiles.id}?date=${dayjs(date).format('YYYY-MM-DD')}_${dayjs(date).format('YYYY-MM-DD')}`}>
              {value} <TextWithEmoji text={row.last_status_emoji} useTooltip={false} />
            </Anchor>
          </Tooltip> : <Anchor component={Link} to={`/presence/${row.profiles.id}?date=${dayjs(date).format('YYYY-MM-DD')}_${dayjs(date).format('YYYY-MM-DD')}`}>
            {value}
          </Anchor>
        },
        {
          key: 'last_status',
          label: 'Online',
          sort: 'last_status',
          minWidth: 115,
          render: (value: any) => <Checkbox checked={value === 'active'} readOnly />
        },
        {
          key: 'est_active_duration',
          label: 'Active Duration',
          minWidth: 173,
          sort: 'est_active_duration',
          render: (value: any) => `${Math.floor(value / 1000 / 60 / 60)}h ${Math.floor(value / 1000 / 60 % 60)}m`
        },
        {
          key: 'last_checked_at',
          label: 'Last Checked',
          minWidth: 160,
          sort: 'last_checked_at',
          render: (value: any) => dayjs(value).format('MMM DD, HH:mm')
        }
      ]}
      empty={<Empty
        label="Data not found"
        description="No data was found on our database based on the filters."
        icon={<IconDatabase />} />}
      search={(val, q) => q.textSearch('profiles.name', val)}
      initialLoading
      pageSize={20}
    />

  </Container> : <PaymentNeed />
}