import { ActionIcon, Badge, Button, Card, Col, Grid, Group, LoadingOverlay, Menu, Text, ThemeIcon, Tooltip } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useLocalStorage } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { IconCheck, IconCircle, IconCurrencyDollar, IconInfoCircle, IconNotification, IconPencil, IconPlugConnected, IconPlus, IconRocket, IconSettings, IconTrash, IconUsers, IconX } from '@tabler/icons'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { TeamInterface } from '.'
import { supabase } from '../../../services/supabase'
import { UserProfile } from '../../../types'
import Billings from './Billings'
import DeleteTeam from './DeleteTeam'
import Form from './Form'
import Integrations from './Integrations'
import Members from './Members'
import Notifications from './Notifications'

export default function () {
  const [user] = useLocalStorage<UserProfile | null>({ key: 'user', defaultValue: null })
  const [selectedProfile, setSelectedProfile] = useLocalStorage<Record<string, any> | null>({
    key: 'selected-profile', defaultValue: null })
  const [query, setQuery] = useSearchParams()
  const [apps, setApps] = useState<any[] | null>([])
  const [open, setOpen] = useState<boolean>(false)
  const [openMembers, setOpenMembers] = useState<any>()
  const [openIntegrations, setOpenIntegrations] = useState<any>()
  const [openBillings, setOpenBillings] = useState<any>()
  const [openNotifications, setOpenNotifications] = useState<any>()
  const [loadingAppCard, setLoadingAppCard] = useState<string>()
  const form = useForm<TeamInterface>({
    initialValues: {
      id: '',
      name: '',
      base_tz: dayjs.tz.guess(),
      profiles_read: ['member', 'admin', 'superadmin'],
      presences_read: ['member', 'admin', 'superadmin'],
      leave_request_read: ['member', 'admin', 'superadmin'],
      leave_request_write: ['member', 'admin', 'superadmin'],
      leave_request_approval: ['admin', 'superadmin'],
      salary_slips_read: ['superadmin'],
      salary_slips_write: ['superadmin']
    }
  })
  const formDelete = useForm<TeamInterface>()

  const fetchApps = useCallback(async () => {
    const { data } = await supabase
      .from('apps')
      .select()
    if (data?.length) {
      query.delete('create-team')
      setQuery(query)
    }
    setApps(data)
  }, [user])

  useEffect(() => {
    if (!open) {
      form.reset()
    }
  }, [open])

  useEffect(() => {
    if (user) {
      fetchApps()
    }
  }, [user])

  useEffect(() => {
    if (query.get('create-team') === '1' && Array.isArray(apps) && !apps?.length) {
      showNotification({
        id: 'create-team-first',
        icon: <IconInfoCircle />,
        color: 'blue',
        title: 'Welcome!',
        message: 'Let\'s try to create your first team 🚀',
      })
    }
    if (query.get('open-integration') && apps?.length) {
      const app = apps.find((app) => app.id === query.get('open-integration'))
      if (app) {
        setOpenIntegrations(app)
      }
    }
  }, [query, apps])

  const getRole = (id: string) => {
    return user?.profiles?.find((profile: any) => profile.app_id === id)?.role
  }

  const activateToggle = async (app: any) => {
    setLoadingAppCard(app.id)
    await new Promise(res => setTimeout(res, 1000))

    const selected = user?.profiles?.find(
      (profile: Record<string, any>) => profile.app_id === app.id)
    if (selected) {
      setSelectedProfile({
        ...selected,
        app: app
      })
      showNotification({
        icon: <IconCheck />,
        color: 'green',
        title: 'Team selected successfully',
        message: `${app.name} has been activated`,
      })
    }
    setLoadingAppCard(undefined)
  }

  const openDeleteConfirmation = (app: any) => {
    if (selectedProfile?.app_id === app.id) {
      return showNotification({
        id: 'app-deletion-failed',
        icon: <IconX />,
        color: 'red',
        title: 'Something went wrong',
        message: 'You cannot delete your current team',
      })
    }
    formDelete.setValues(app)
  }

  return <div>
    <Grid mt="sm">
      {apps?.map((app: any) => <Col md={4} sm={6} xs={12} key={app.id}>
        <Card p="lg" withBorder style={{ overflow: 'initial', position: 'relative' }}>
          <Card.Section></Card.Section>
          <LoadingOverlay visible={loadingAppCard === app.id} />
          <Group position="apart" mt="xl" mb="xl">
            <Text weight={500} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: 'calc(100% - 119px)' }}>{app.name}</Text>
            <Badge color={(() => {
              const role = getRole(app.id)
              if (role === 'admin') {
                return 'pink'
              }
              if (role === 'superadmin') {
                return 'red'
              }
              return 'blue'
            })()} variant="light">
              {getRole(app.id) || 'member'}
            </Badge>
          </Group>
          <Group position="right">
            <Tooltip label={selectedProfile?.app_id === app.id ? 'Selected team' : 'Make active team'} position="bottom" withArrow>
              <ActionIcon mt="sm" size="lg" color={selectedProfile?.app_id === app.id ? 'green' : 'yellow'} onClick={() => activateToggle(app)}>
                {selectedProfile?.app_id === app.id ? <IconCheck size={20} /> : <IconCircle size={20} />}
              </ActionIcon>
            </Tooltip>
            {user?.profiles?.find(
              (profile: Record<string, any>) => profile.app_id === app.id)?.role === 'superadmin' ? <Menu position="bottom" withArrow>
                <Menu.Target>
                  <Button mt="sm" variant="light" color="blue" radius="md" leftIcon={<IconSettings size={18} />}>
                    Manage this team
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item icon={<IconPencil size={14} />} onClick={() => {
                    form.setValues(app)
                    setOpen(true)
                  }}>Edit</Menu.Item>
                  <Menu.Item icon={<IconCurrencyDollar size={14} />} onClick={() => setOpenBillings(app)}>
                    Billing
                  </Menu.Item>
                  <Menu.Item icon={<IconUsers size={14} />} onClick={() => setOpenMembers(app)}>
                    View members
                  </Menu.Item>
                  <Menu.Item icon={<IconPlugConnected size={14} />} onClick={() => setOpenIntegrations(app)}>
                    Integrations
                  </Menu.Item>
                  <Menu.Item icon={<IconNotification size={14} />} onClick={() => setOpenNotifications(app)}>
                    Notifications
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item color="red" icon={<IconTrash size={14} />} onClick={() => openDeleteConfirmation(app)}>Delete {app.name}</Menu.Item>
                </Menu.Dropdown>
              </Menu> : <>
                {(app.profiles_read.find((role: string) => role === user?.profiles?.find(
                  (profile: Record<string, any>) => profile.app_id === app.id)?.role) || app.profiles_read.includes('all')) && <Button mt="sm" variant="light" color="blue" radius="md" leftIcon={<IconUsers size={18} />} onClick={() => setOpenMembers(app)}>
                  View members
                </Button>}
              </>}
          </Group>
        </Card>
      </Col>)}
      <Col md={4} sm={6} xs={12}>
        <Card p="lg" withBorder>
          <Card.Section></Card.Section>
          <Group mt="xl" mb="xl">
            <ThemeIcon radius="xl"><IconPlus size={18} /></ThemeIcon>
            <Text weight={500}>
              Add your team
            </Text>
          </Group>
          <Group position="right">
            <Button
              leftIcon={<IconRocket size={18} />}
              mt="sm"
              variant="light"
              color="blue"
              fullWidth
              radius="md"
              onClick={() => setOpen(true)}>
              Create a team
            </Button>
          </Group>
        </Card>
      </Col>
    </Grid>
    <Form
      form={form}
      open={open}
      setOpen={setOpen}
      // onCreated={setOpenMembers}
      onCreatedOrUpdated={fetchApps}
      onDelete={formDelete.setValues} />
    <DeleteTeam form={formDelete} />
    <Members app={openMembers} setApp={setOpenMembers} />
    <Integrations app={openIntegrations} setApp={setOpenIntegrations} />
    <Billings app={openBillings} onClose={() => setOpenBillings(undefined)} />
    <Notifications app={openNotifications} onClose={() => setOpenNotifications(undefined)} />
  </div>
}