import { Box, Footer, Stack, Text, UnstyledButton } from '@mantine/core'
import useNavigation from '../hooks/useNavigation'

export default function () {
  const { navs } = useNavigation()

  return <Footer height={53} mt={40} pl="xs" pr="xs" style={{ textAlign: 'center' }}>
    <Box style={{ display: 'flex', flexDirection: 'row' }}>
      {navs.sort((a, b) => (a.orderBottom || 0) - (b.orderBottom || 0)).map((item: any, i: number) => <UnstyledButton
        style={{ flexGrow: 1 }} onClick={item.onClick} key={i.toString()}>
        <Stack style={{ textAlign: 'center' }} pt="sm" pb="sm">
          <Text><item.icon active={item.active} /></Text>
        </Stack>
      </UnstyledButton>)}
    </Box>
  </Footer>
}