import { Title } from '@mantine/core'
import List from './List'

export interface TeamInterface {
  id?: string,
  name: string,
  profiles_read?: string[],
  presences_read?: string[],
  leave_request_read?: string[],
  leave_request_write?: string[],
  salary_slips_read?: string[],
  salary_slips_write?: string[],
  leave_request_approval?: string[],
  base_tz?: string
}

export default function () {
  return <>
    <Title mt="xl" order={4}>
      My Teams
    </Title>
    <List />
  </>
}