import { Box, Button, Group, Paper, Text, Title, Tooltip } from '@mantine/core'
import { IconBrandSlack, IconCircleCheck, IconCircleX, IconMail } from '@tabler/icons'
import { ReactNode } from 'react'

export default function ({ profile, additonal, status }: { profile: any, breadcrumbs: { label: string, active?: boolean, link?: string }[], additonal?: ReactNode, status?: 'away' | 'active' }) {
  return <Paper p="lg" withBorder mb="xl" shadow="sm" radius="md">
    <Group position="apart">
      <Box sx={() => ({
        maxWidth: 'calc(100% - 250px)', '@media (max-width: 767px)': { maxWidth: '100%' }
      })} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <Group spacing="sm">
          <Title order={2} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', ...status ? { maxWidth: 'calc(100% - 36px)' } : {} }}>
            {profile?.name}
          </Title>
          {status && <Tooltip label={status} withArrow position="top"><Text mt="xs">{status === 'active' ? <IconCircleCheck color="teal" /> : <IconCircleX color="red" />}</Text></Tooltip>}
        </Group>
        <Text color="dimmed" italic size="sm">
          {additonal ? <Text size="sm" color="dimmed">{additonal}</Text> : profile?.integration_profile_data?.slack?.profile?.title || profile?.email}
        </Text>
      </Box>
      <Group>
        <Tooltip label={`Send an email to ${profile?.email}`} withArrow>
          <Button size="sm" variant="light" leftIcon={<IconMail size={18} />} component="a" target="_blank" href={`mailto:${profile?.email}`}>
            {/* {profile?.email} */}
            Email
          </Button>
        </Tooltip>
        {profile?.integration_profile_data?.slack?.id && <Tooltip label={status === 'away' ? `@${profile?.integration_profile_data?.slack?.profile.display_name} is away right now` : `Send a message to @${profile?.integration_profile_data?.slack?.profile.display_name}`} withArrow>
          <Button size="sm" variant="light" color={status === 'away' ? 'gray' : 'green'} leftIcon={<IconBrandSlack size={18} />} component="a" target="_blank" href={`https://slack.com/app_redirect?channel=${profile?.integration_profile_data?.slack?.id}`}>
            {/* {profile?.integration_profile_data?.slack?.profile.display_name} */}
            Send DM
          </Button>
        </Tooltip>}
      </Group>
    </Group>
  </Paper>
}