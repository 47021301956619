import Slack from './Slack'

export default function ({ name, data, onSave, setLoading }: {
  name: string,
  data: any,
  onSave?: () => void,
  setLoading: (loading: boolean) => void
}) {
  if (name.toLowerCase() === 'slack') {
    return <Slack data={data} onSave={onSave} setLoading={setLoading} />
  }
  return <></>
}