import { Button, Drawer, Group, LoadingOverlay, Text } from '@mantine/core'
import { useState } from 'react'
import { supabase } from '../../../services/supabase'

export default function ({ data, setData, onDeleted }: { data: any, setData: (data: any) => void, onDeleted?: (data: any) => void }) {
  const [loading, setLoading] = useState<boolean>(false)

  const remove = async () => {
    setLoading(true)
    await new Promise(res => setTimeout(res, 1500))
    try {
      await supabase
        .from('leave_requests')
        .delete()
        .eq('profile_id', data?.id)
      await supabase
        .from('presences')
        .delete()
        .eq('profile_id', data?.id)
      await supabase
        .from('profiles')
        .delete()
        .eq('id', data?.id)
      onDeleted?.(data)
      setData(undefined)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }
  return <Drawer padding="xl" position="right" opened={!!data} onClose={() => setData(undefined)} title="Delete confirmation">
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} />
      <Text component="p">Are you sure to delete <Text component="strong">{data?.name}</Text> <Text component="em">({data?.email})</Text>?</Text>
      <Text color="dimmed" size="sm">This action cannot be undone</Text>
      <Group position="right" mt="xl" pt="xl">
        <Button color="red" variant="light" onClick={() => setData(undefined)}>Cancel</Button>
        <Button color="red" onClick={remove}>Delete</Button>
      </Group>
    </div>
  </Drawer>
}