import { Container, Group, Stack, Text, Title } from '@mantine/core'
import { IconCreditCard } from '@tabler/icons'

export default function () {
  return <Container sx={() => ({
    minHeight: 'calc(100vh - 77px)', '@media (max-width: 767px)': { minHeight: 'calc(100vh - 130px)' }
  })} style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative' }}>
    <Stack spacing="xs">
      <Group position="center">
        <Title order={3}>
          Payment required
        </Title>
        <IconCreditCard />
      </Group>
      <Text size="sm" color="dimmed" component="p">
        Please contact the app owner to get access.
      </Text>
    </Stack>
  </Container>
}