import { Container } from '@mantine/core'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { request } from '../services/request'

export default function () {
  const [doc, setDoc] = useState<string>()

  useEffect(() => {
    request.get('/legal', { params: { type: 'terms' } }).then(async ({ data }) => {
      setDoc(data.document)
    })
  }, [])

  return <Container>
    <ReactMarkdown className="terms" remarkPlugins={[remarkGfm]}>
      {doc as string}
    </ReactMarkdown>
  </Container>
}