import { Tooltip } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import unescape from 'lodash.unescape'
import { ReactNode, useEffect } from 'react'
import reactStringReplace from 'react-string-replace'
import systemEmojis from '../emojis.json'
import { request } from '../services/request'

export default function ({ text, useTooltip = true, callback }: { text: string, useTooltip?: boolean, callback?: ReactNode }) {
  const [selectedProfile] = useLocalStorage<Record<string, any> | null>({
    key: 'selected-profile', defaultValue: null })
  const [emojis, setEmojis] = useLocalStorage<{ lastFetchAt: Date, data: Record<string, string> } | null>({
    key: 'emojis', defaultValue: null })

  const fetchEmojis = async () => {
    const { data } = await request.get('/integrations/slack/emojis', {
      params: {
        app_id: selectedProfile?.app_id
      }
    })
    setEmojis({
      data,
      lastFetchAt: new Date()
    })
  }

  useEffect(() => {
    if (selectedProfile?.app_id) {
      const emojisString = localStorage.getItem('emojis')
      const emojis = emojisString ? JSON.parse(emojisString) : null
      if (!emojis?.lastFetchAt || new Date(emojis.lastFetchAt).getTime() < new Date().getTime() - 1000 * 60 * 60 * 24) {
        setEmojis({ data: {}, lastFetchAt: new Date() })
        fetchEmojis()
      }
    }
  }, [selectedProfile])

  return <span>
    {reactStringReplace(reactStringReplace(text, /\:([0-9a-zA-Z\-_]+)\:/gi, (match, i) => {
      if (match.includes('skin-tone-')) {
        return ''
      }
      if (emojis?.data?.[match]) {
        return useTooltip ? <Tooltip withArrow label={`:${match}:`} key={i}>
          <img key={i} src={emojis?.data[match]} alt={match} style={{ width: 16, height: 16, margin: 0 }} />
        </Tooltip> : <span key={i}>
          <img key={i} src={emojis?.data[match]} alt={match} style={{ width: 16, height: 16, margin: 0 }} />
        </span>
      }
      const systemEmoji = systemEmojis.find(e => e.short_names.includes(match))
      if (systemEmoji) {
        return useTooltip ? <Tooltip withArrow label={`:${match}:`} key={i}>
          <span dangerouslySetInnerHTML={{ __html: `&#x${(systemEmoji.non_qualified || systemEmoji.unified).split('-')[0]};` }} />
        </Tooltip> : <span key={i}>
          <span dangerouslySetInnerHTML={{ __html: `&#x${(systemEmoji.non_qualified || systemEmoji.unified).split('-')[0]};` }} />
        </span>
      }
      return callback ? <span key="callback">{callback}</span> : `:${match}:`
    }), /(\&[0-9a-zA-Z\-_]+\;)/gi, (match) => {
      return unescape(match)
    })}
  </span>
}