import { Anchor, Button, Col, Container, Grid, Group, MediaQuery, Paper, Stack, Text, Title } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { IconLogout } from '@tabler/icons'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { supabase } from '../../services/supabase'
import { UserProfile } from '../../types'
import { GREETINGS } from '../../utils/Constant'
import SecurityKeys from './SecurityKeys'
import Teams from './Teams'

export default function () {
  const [user] = useLocalStorage<UserProfile | null>({ key: 'user', defaultValue: null })
  const [selectedProfile] = useLocalStorage<Record<string, any> | null>({
    key: 'selected-profile', defaultValue: null })
  const [greeting] = useState<string>(GREETINGS[Math.floor(Math.random() * GREETINGS.length)])
  const navigate = useNavigate()

  const logout = async () => {
    await supabase.auth.signOut()
    navigate('/login')
  }

  return <Container mb="xl" mt="xl">
    <Paper p="lg" withBorder mb="xl" shadow="sm" radius="md">
      <Group position="apart">
        <div>
          {/* <Breadcrumbs>
            <Anchor component={Link} to="/">Home</Anchor>
            <Anchor>Profile</Anchor>
          </Breadcrumbs> */}
          <Title order={2}>
            <Text component="span" style={{ fontWeight: 'normal' }}>
              {greeting},</Text> {user?.user_metadata?.['full_name'] || selectedProfile?.name}
          </Title>
          <Text color="dimmed" italic size="sm">{user?.email}</Text>
        </div>
        <MediaQuery smallerThan="lg" styles={{ display: 'none' }}>
          <Button color="red"
            leftIcon={<IconLogout size={20} />}
            onClick={logout}>
            Logout
          </Button>
        </MediaQuery>
      </Group>
    </Paper>
    <Teams />
    <SecurityKeys />
    <MediaQuery largerThan="lg" styles={{ display: 'none' }}>
      <Grid>
        <Col sm={12} md={8}>
          <Stack>
            <Button color="red" fullWidth
              leftIcon={<IconLogout size={20} />}
              onClick={logout }>
              Logout
            </Button>
            <Group position="center" spacing="xs">
              <Text color="dimmed" size="xs">v0.1.0</Text>
              <Text color="dimmed">&bull;</Text>
              <Anchor size="xs" component={Link} to="/terms">Terms</Anchor>
              <Text color="dimmed">&bull;</Text>
              <Anchor size="xs" component={Link} to="/privacy">Privacy</Anchor>
              <Text color="dimmed">&bull;</Text>
              <Anchor size="xs" component={Link} to="/support">Support</Anchor>
            </Group>
          </Stack>
        </Col>
      </Grid>
    </MediaQuery>
  </Container>
}