import { Button, Divider, Drawer, Group, Input, LoadingOverlay, MultiSelect, Select, Text } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { useLocalStorage } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { IconCheck, IconPencil, IconTrash, IconX } from '@tabler/icons'
import { useState } from 'react'
import { TeamInterface } from '.'
import { supabase } from '../../../services/supabase'
import { UserProfile } from '../../../types'
import timezone from '../../../timezone.json'

export default function ({ form, open, setOpen, onCreated, onCreatedOrUpdated, onDelete }: {
  form: UseFormReturnType<TeamInterface, (values: TeamInterface) => TeamInterface>,
  open: boolean,
  setOpen: (open: boolean) => void,
  onCreated?: (app: any) => void,
  onCreatedOrUpdated?: () => void,
  onDelete?: (app: any) => void
}) {
  const [user, setUser] = useLocalStorage<UserProfile | null>({ key: 'user', defaultValue: null })
  const [selectedProfile, setSelectedProfile] = useLocalStorage<Record<string, any> | null>({
    key: 'selected-profile', defaultValue: null })
  const [loading, setLoading] = useState<boolean>(false)

  const createApp = async (values: TeamInterface) => {
    if (!values.name) {
      return form.setFieldError('name', 'Name is required')
    }
    if (!values.base_tz) {
      return form.setFieldError('base_tz', 'Base timezone is required')
    }
    if (!values.profiles_read?.length) {
      return form.setFieldError('profiles_read', 'View profile is required')
    }
    if (!values.presences_read?.length) {
      return form.setFieldError('presences_read', 'View presences is required')
    }
    if (!values.leave_request_read?.length) {
      return form.setFieldError('leave_request_read', 'View leave request is required')
    }
    if (!values.leave_request_write?.length) {
      return form.setFieldError('leave_request_write', 'Write leave request is required')
    }
    if (!values.salary_slips_read?.length) {
      return form.setFieldError('salary_slips_read', 'View salary slips is required')
    }
    if (!values.salary_slips_write?.length) {
      return form.setFieldError('salary_slips_write', 'Write salary slips is required')
    }
    if (!values.leave_request_approval?.length) {
      return form.setFieldError('leave_request_approval', 'Approval leave request is required')
    }

    setLoading(true)
    await new Promise(res => setTimeout(res, 1500))
    try {
      const { data, error } = values.id
        ? await supabase.from('apps').update(values).eq('id', values.id).select()
        : await supabase.from('apps').insert({
          ...values,
          id: undefined,
          owner_id: user?.id
        }).select()
      if (!data?.length) {
        throw new Error(error?.message || 'Failed to create or update app')
      }
      onCreatedOrUpdated?.()
      setOpen(false)

      if (!values.id) {
        const { data: profiles } = await supabase.from('profiles').insert({
          app_id: data[0].id,
          user_id: user?.id,
          role: 'superadmin',
          email: user?.email as string,
          name: user?.user_metadata['full_name'] || user?.user_metadata['name'] || user?.email,
        }).select()
        setUser({
          ...user,
          profiles: [
            ...user?.profiles || [],
            ...profiles || []
          ],
        } as any)
        setSelectedProfile({
          ...profiles?.[0],
          app: {
            id: data[0].id,
            name: data[0].name
          }
        })
      }
      showNotification({
        id: 'app-creation-succeed',
        icon: <IconCheck />,
        color: 'green',
        title: 'Team created successfully',
        message: 'Your team has been created',
      })
      if (!values.id) {
        onCreated?.(data[0])
      }
    } catch (error: any) {
      showNotification({
        id: 'app-creation-failed',
        icon: <IconX />,
        color: 'red',
        title: 'Something went wrong',
        message: error.response?.data.error || error.message || 'Please reload and try again',
      })
    } finally {
      setLoading(false)
    }
  }

  return <Drawer padding="xl" size="xl" position="right" opened={open} onClose={() => setOpen(false)} title={form.values.id ? `Update ${form.values.name}` : 'Create Team'}>
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} />
      <form onSubmit={form.onSubmit(values => createApp(values))}>
        <Input.Wrapper
          required
          label="Name"
          withAsterisk
          error={form.errors.name}>
          <Input
            placeholder="BahasaLab"
            maxLength={36}
            {...form.getInputProps('name')} />
          <Text
            style={{ float: 'right' }}
            color="dimmed"
            size="xs">{form.values.name.length} / 36</Text>
        </Input.Wrapper>
        <div style={{ clear: 'both' }} />
        <Input.Wrapper
          mt="xs"
          required
          label="Base Timezone"
          withAsterisk>
          <Select
            searchable
            placeholder="Select timezone..."
            data={timezone.reduce((res: { label: string, key: string, value: string }[], item) => [
              ...res, ...item.utc.filter(i => !res.find(j => j.value === i)).map((utc: string) => ({
                label: `${utc} (UTC${item.offset >= 0 ? '+' : ''}${item.offset})`,
                value: utc,
                key: utc
              }))
            ], [])}
            {...form.getInputProps('base_tz')}
          />
        </Input.Wrapper>
        <Divider mt="xl" label="Profiles and presences" />
        <Input.Wrapper
          mt="xs"
          required
          label="Who can view team members?"
          withAsterisk>
          <MultiSelect
            placeholder="Select role..."
            data={['member', 'admin', 'superadmin']}
            {...form.getInputProps('profiles_read')}
          />
        </Input.Wrapper>
        <Input.Wrapper
          mt="md"
          required
          label="Who can view presences?"
          withAsterisk>
          <MultiSelect
            placeholder="Select role..."
            data={['member', 'admin', 'superadmin']}
            {...form.getInputProps('presences_read')}
          />
        </Input.Wrapper>
        <Divider mt="xl" label="Leave requests" />
        <Input.Wrapper
          mt="xs"
          required
          label="Who can view leave request?"
          withAsterisk>
          <MultiSelect
            placeholder="Select role..."
            data={['member', 'admin', 'superadmin']}
            {...form.getInputProps('leave_request_read')}
          />
        </Input.Wrapper>
        <Input.Wrapper
          mt="md"
          required
          label="Who can write leave request?"
          withAsterisk>
          <MultiSelect
            placeholder="Select role..."
            data={['member', 'admin', 'superadmin']}
            {...form.getInputProps('leave_request_write')}
          />
        </Input.Wrapper>
        <Input.Wrapper
          mt="md"
          required
          label="Who can approve leave request?"
          withAsterisk>
          <MultiSelect
            placeholder="Select role..."
            data={['member', 'admin', 'superadmin']}
            {...form.getInputProps('leave_request_approval')}
          />
        </Input.Wrapper>
        {/* <Divider mt="xl" label="Salary slips" />
        <Input.Wrapper
          mt="xs"
          required
          label="Who can view salary slips?"
          withAsterisk>
          <MultiSelect
            placeholder="Select role..."
            data={['member', 'admin', 'superadmin']}
            {...form.getInputProps('salary_slips_read')}
          />
        </Input.Wrapper>
        <Input.Wrapper
          mt="md"
          required
          label="Who can create salary slips?"
          withAsterisk>
          <MultiSelect
            placeholder="Select role..."
            data={['member', 'admin', 'superadmin']}
            {...form.getInputProps('salary_slips_write')}
          />
        </Input.Wrapper> */}
        <Group position="right" mt="xl">
          {form.values.id && <Button color="red" variant="light" onClick={() => {
            if (selectedProfile?.app_id === form.values.id) {
              return showNotification({
                id: 'app-deletion-failed',
                icon: <IconX />,
                color: 'red',
                title: 'Something went wrong',
                message: 'You cannot delete your current team',
              })
            }
            onDelete?.(form.values)
          }} leftIcon={<IconTrash size={18} />}>
            Delete
          </Button>}
          <Button type="submit" leftIcon={<IconPencil size={18} />}>
            {form.values.id ? 'Update' : 'Create'}
          </Button>
        </Group>
      </form>
    </div>
  </Drawer>
}