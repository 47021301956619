import { ActionIcon, Box, Col, Grid, Group, Input, Loader, Pagination, ScrollArea, Table, Text, ThemeIcon, UnstyledButton } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { PostgrestFilterBuilder } from '@supabase/postgrest-js'
import { IconSearch, IconSortAscending, IconSortDescending, IconX } from '@tabler/icons'
import get from 'lodash.get'
import { useCallback, useEffect, useState } from 'react'
interface Column {
  key: string,
  label: string,
  minWidth?: number,
  sort?: string | { key: string, foreignTable: string },
  render?: (value: any, row: any, data: any[]) => React.ReactNode
}

interface Props {
  flattenColumn?: string,
  query: (options?: { checkCount?: boolean }) => PostgrestFilterBuilder<any, any, any>,
  dependencies?: any[],
  columns?: Column[],
  initialLoading?: boolean,
  defaultOrder?: [string, { ascending: boolean, foreignTable: string }],
  pageSize?: number,
  empty?: React.ReactNode,
  search?: (value: string, client: PostgrestFilterBuilder<any, any, any>) => PostgrestFilterBuilder<any, any, any>,
  refetch?: [boolean, (val: boolean) => void]
}

export default function ({ flattenColumn, query, columns, search, refetch, defaultOrder, empty = 'No data', dependencies = [], pageSize = 10, initialLoading = true }: Props) {
  const [loading, setLoading] = useState<boolean>(initialLoading)
  const [data, setData] = useState<any[] | null>(null)
  const [count, setCount] = useState<number>(0)
  const [sort, setSort] = useState<[string, { ascending: boolean, foreignTable: string }] | undefined>(defaultOrder)
  const [page, setPage] = useState<number>(1)
  const [searchValue, setSearchValue] = useState<string | null>(null)
  const [searchValueDebounce] = useDebouncedValue(searchValue, 1000)

  const parseFlatteData = (obj: any[], flattenTo: string) => obj.reduce((res, item) => {
    return [...res, ...item[flattenTo].map((i: any, j: number) => ({
      ...j === 0 ? { ...item, __rowSpan: item[flattenTo]?.length } : Object.keys(item).reduce((res, key) => ({
        ...res, [key]: '$__' }), {}),
      ...Object.keys(i).reduce((res, key) => ({
        ...res, [`${flattenTo}__${key}`]: i[key] }), {})
    }))]
  }, [])

  const getCount = useCallback(async () => {
    let qCount = query({ checkCount: true })
    if (search && searchValueDebounce) {
      // const { filter, foreignTable } = search(searchValueDebounce)
      // qCount = qCount.or(filter, foreignTable ? { foreignTable } : undefined)
      qCount = search(searchValueDebounce, qCount)
    }
    const { count } = await qCount
    setCount(count || 0)
  }, [searchValueDebounce, ...dependencies])

  const fetch = useCallback(async () => {
    setLoading(true)
    await new Promise(resolve => setTimeout(resolve, 500))

    let q = query()
    if (search && searchValueDebounce) {
      // const { filter, foreignTable } = search(searchValueDebounce)
      // q = q.or(filter, foreignTable ? { foreignTable } : undefined)
      q = search(searchValueDebounce, q)
    }

    if (sort?.[0]) {
      // issue: https://github.com/supabase/postgrest-js/issues/198#issuecomment-1301552829
      q.order(sort[0], {
        ascending: sort[1].ascending,
        foreignTable: sort[1].foreignTable
      })
    }

    q = q.range((page - 1) * pageSize, page * pageSize - 1)

    const { data, error } = await q
    if (error) {
      setLoading(false)
      return showNotification({
        icon: <IconX />,
        color: 'red',
        title: 'Something went wrong',
        message: error.message || 'Please reload and try again',
      })
    }
    setData(data)
    setLoading(false)
  }, [query, page, sort, searchValueDebounce])

  useEffect(() => {
    if (dependencies.filter(Boolean).length === dependencies.length && page) {
      if (!defaultOrder || sort) {
        fetch()
      }
    }
  }, [...dependencies, page, sort])

  useEffect(() => {
    if (dependencies.filter(Boolean).length === dependencies.length) {
      getCount()
    }
  }, [...dependencies, searchValueDebounce])

  useEffect(() => {
    if (searchValueDebounce !== null &&dependencies.filter(Boolean).length === dependencies.length) {
      if (page !== 1) {
        setPage(1)
      } else {
        fetch()
      }
    }
  }, [...dependencies, searchValueDebounce])

  useEffect(() => {
    if (refetch?.[0]) {
      fetch()
      refetch[1](false)
    }
  }, [refetch])

  const HeadTableTitle = ({ label, field }: { label: string, field: string | { key: string, foreignTable: string } }) => <Group position="apart" spacing="xs">
    <Text>{label}</Text>
    <UnstyledButton
      pl="xs"
      onClick={() => setSort([
        typeof field === 'string' ? field : field.key,
        {
          ascending: !((typeof field === 'string' ? field : field.key) === sort?.[0] && sort?.[1].ascending),
          foreignTable: typeof field !== 'string' ? field.foreignTable : ''
        }
      ])}>
      {sort?.[0] === (typeof field === 'string' ? field : field.key) && sort?.[1].ascending
        ? <ThemeIcon size="md" variant="light" color={
          sort?.[0] === (typeof field === 'string' ? field : field.key) ? 'blue' : 'gray'}>
          <IconSortDescending size={16} />
        </ThemeIcon>
        : <ThemeIcon size="md" variant="light" color={
          sort?.[0] === (typeof field === 'string' ? field : field.key) ? 'blue' : 'gray'}>
          <IconSortAscending size={16} />
        </ThemeIcon>}
    </UnstyledButton>
  </Group>

  return <div>
    {search && <Grid mt="xl" mb="md">
      <Col sm={4} offsetSm={8} xs={12}>
        <Input radius="md" type="text"
          icon={<IconSearch size={20} />}
          rightSection={searchValue ? <ActionIcon radius="xl" size="sm" onClick={() => setSearchValue('')}>
            <IconX size={12} />
          </ActionIcon> : undefined}
          placeholder="Search..."
          value={searchValue || '' as string}
          onChange={(e: any) => setSearchValue(e.target.value)} />
      </Col>
    </Grid>}
    {loading ? <div style={{ textAlign: 'center' }}><Loader /></div> : <>
      {data?.length ? <Box>
        <ScrollArea>
          <Table striped>
            <thead>
              <tr>
                {columns?.map((column, i) => <th key={i}>
                  {column.sort ? <HeadTableTitle label={column.label} field={column.sort} /> : column.label}
                </th>)}
              </tr>
            </thead>
            <tbody>
              {flattenColumn ? parseFlatteData(data, flattenColumn)?.map((row: any, i: number) => <tr key={i}>
                {columns?.map((column, j) => get(row, column.key) !== '$__' && <td key={j} style={{ minWidth: column.minWidth || undefined }} {
                  ...row.__rowSpan && !column.key.startsWith(`${flattenColumn}__`) ? { rowSpan: row.__rowSpan } : {}
                }>
                  {column.render?.(get(row, column.key), row, data) || get(row, column.key)}
                </td>)}
              </tr>) : data?.map((row, i) => <tr key={i}>
                {columns?.map((column, j) => <td key={j} style={{ minWidth: column.minWidth || undefined }}>
                  {column.render?.(get(row, column.key), row, data) || get(row, column.key)}
                </td>)}
              </tr>)}
            </tbody>
          </Table>
        </ScrollArea>
        <Group position="right" mt="lg" mb="xl">
          <Text color="dimmed" size="xs">
            {Math.min((page - 1) * pageSize + 1, count)} - {Math.min((page + 1) * pageSize, count)} of {count} records
          </Text>
          <Pagination position="right" total={Math.ceil(count / pageSize)} page={page} onChange={setPage} />
        </Group>
      </Box> : <div style={{ textAlign: 'center' }}>
        {!loading && empty}
      </div>}
    </>}
  </div>
}