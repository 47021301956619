import { Anchor, Badge, Button, Checkbox, Col, Divider, Drawer, Grid, Group, Input, LoadingOverlay, Menu, Paper, ScrollArea, Select, Table, Tabs, Text, Tooltip } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useLocalStorage } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { IconBrandSlack, IconBriefcase, IconCheck, IconNotification, IconPencil, IconPlus, IconTie, IconTrash, IconUserCheck, IconUserOff, IconUsers, IconUserSearch, IconX } from '@tabler/icons'
import dayjs from 'dayjs'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import usePayment from '../../../hooks/usePayment'
import { supabase } from '../../../services/supabase'
import { UserProfile } from '../../../types'
import DeleteMember from './DeleteMember'
import ImportUsers from './Integration/ImportUsers'
import Notifications from './Notifications'

interface InvitationInterface {
  id?: string,
  email: string,
  role: string,
  use_email_pattern: boolean
}

interface GroupInterface {
  id?: string,
  name: string,
  app_id: string
}


export default function ({ app, setApp }: { app: any, setApp: (app: any) => void }) {
  const [user] = useLocalStorage<UserProfile | null>({ key: 'user', defaultValue: null })
  const [profiles, setProfiles] = useState<any[] | null>([])
  const [invitations, setInvitations] = useState<any[] | null>([])
  const [groups, setGroups] = useState<any[] | null>([])
  const [deleteMemberConfirmation, setDeleteMemberConfirmation] = useState<any>()
  const [openImportUsers, setOpenImportUsers] = useState<any>()
  const [openNotifications, setOpenNotifications] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [selected, setSelected] = useState<any[]>([])
  const [selectedGroup, setSelectedGroup] = useState<string>()
  const [selectedRole, setSelectedRole] = useState<string>()
  const { check } = usePayment()
  const formInvitation = useForm<InvitationInterface>({
    initialValues: {
      id: '',
      email: '',
      role: 'member',
      use_email_pattern: false
    }
  })


  const formGroup = useForm<GroupInterface>({
    initialValues: {
      id: '',
      name: '',
      app_id: app?.id
    }
  })

  useEffect(() => {
    if (app?.id) {
      fetchMembers()
    }
  }, [app])

  const fetchMembers = useCallback(async () => {
    setLoading(true)
    await new Promise(res => setTimeout(res, 500))
    await Promise.allSettled([
      (async () => {
        const { data } = await supabase
          .from('profiles')
          .select()
          .eq('app_id', app?.id)
        setProfiles((data || []).map(
          (profile: any) => ({ ...profile, roleOrder: profile.role === 'superadmin' ? 1 : profile.role === 'admin' ? 2 : 3 })
        ).sort((a: any, b: any) => a.roleOrder - b.roleOrder === 0 ? a.email.localeCompare(b.email) : a.roleOrder - b.roleOrder))
      })(),
      (async () => {
        const { data } = await supabase
          .from('invitations')
          .select()
          .eq('app_id', app?.id)
          .is('deleted_at', null)
        setInvitations((data || []).map(
          (profile: any) => ({ ...profile, roleOrder: profile.role === 'superadmin' ? 1 : profile.role === 'admin' ? 2 : 3 })
        ).sort((a: any, b: any) => a.roleOrder - b.roleOrder === 0 ? a.email.localeCompare(b.email) : a.roleOrder - b.roleOrder))
      })(),
      (async () => {
        const { data } = await supabase
          .from('groups')
          .select()
          .eq('app_id', app?.id)
        setGroups(data || [])
      })()
    ])

    setLoading(false)
  }, [app])

  const invite = async (values: InvitationInterface) => {
    if (!values.email) {
      return formInvitation.setFieldError('email', 'Email is required')
    }
    if (!values.role) {
      return formInvitation.setFieldError('role', 'Role is required')
    }

    setLoading(true)
    await new Promise(res => setTimeout(res, 1000))

    try {
      const { seats, members }: any = await check(true)
      if (members + 1 > seats) {
        return showNotification({
          title: 'Error',
          message: 'You have reached the maximum seats',
          color: 'red',
          icon: <IconX />
        })
      }
      const { data, error } = values.id
        ? await supabase.from('invitations').update({
          ...values,
          app_id: app?.id
        }).eq('id', values.id).select()
        : await supabase.from('invitations').insert({
          ...values,
          id: undefined,
          app_id: app?.id
        }).select()
      if (!data?.length) {
        throw new Error(error?.message || 'Failed to create or update invitations')
      }

      showNotification({
        id: 'invitation-succeed',
        icon: <IconCheck />,
        color: 'green',
        title: 'User invited successfully',
        message: 'User will joined after sign in to hours.online',
      })
      fetchMembers()
      formInvitation.reset()
    } catch (error: any) {
      showNotification({
        id: 'invitation-failed',
        icon: <IconX />,
        color: 'red',
        title: 'Something went wrong',
        message: error.response?.data.error || error.message || 'Please reload and try again',
      })
    } finally {
      setLoading(false)
    }
  }

  const createGroup = async (values: GroupInterface) => {
    if (!values.name) {
      return formGroup.setFieldError('name', 'Name is required')
    }

    setLoading(true)
    await new Promise(res => setTimeout(res, 1000))

    try {
      const { error } = values.id
        ? await supabase.from('groups').update({
          ...values,
          app_id: app?.id
        }).eq('id', values.id)
        : await supabase.from('groups').insert({
          ...values,
          id: undefined,
          app_id: app?.id
        })
      if (error) {
        throw new Error(error?.message || 'Failed to create or update group')
      }

      showNotification({
        id: 'group-succeed',
        icon: <IconCheck />,
        color: 'green',
        title: 'Success',
        message: 'Group created successfully',
      })
      fetchMembers()
      formGroup.reset()
    } catch (error: any) {
      showNotification({
        id: 'group-failed',
        icon: <IconX />,
        color: 'red',
        title: 'Something went wrong',
        message: error.response?.data.error || error.message || 'Please reload and try again',
      })
    } finally {
      setLoading(false)
    }
  }

  const removeGroup = async (id: string) => {
    setLoading(true)
    const { error } = await supabase
      .from('groups')
      .delete()
      .eq('id', id)
    if (error) {
      showNotification({
        id: 'group-failed',
        icon: <IconX />,
        color: 'red',
        title: 'Something went wrong',
        message: error.message || 'Please reload and try again',
      })
    }
    await fetchMembers()
    setLoading(false)
  }

  const updateGroup = async (row: GroupInterface) => {
    formGroup.setValues(row)
  }

  const suspendToggle = async (profile: any) => {
    setLoading(true)
    await new Promise(res => setTimeout(res, 1000))

    await supabase
      .from('profiles')
      .update({
        deleted_at: profile.deleted_at ? null : new Date().toISOString(),
      })
      .eq('id', profile.id)
    setProfiles((profiles || []).map((p: any) => p.id === profile.id ? { ...p, deleted_at: profile.deleted_at ? null : new Date() } : p))
    showNotification({
      icon: <IconCheck />,
      color: 'green',
      title: 'Success',
      message: `${profile.email} ${!profile.deleted_at ? 'suspended' : 'unsuspended'} successfully`,
    })
    setLoading(false)
  }

  const deleteInvitation = async (profile: any) => {
    setLoading(true)
    await new Promise(res => setTimeout(res, 1000))

    await supabase
      .from('invitations')
      .delete()
      .eq('id', profile.id)
    setInvitations((invitations|| []).filter((invitation: any) => invitation.id !== profile.id))
    showNotification({
      id: 'delete-invitation-succeed',
      icon: <IconCheck />,
      color: 'green',
      title: 'Success',
      message: `${profile.email} deleted successfully`,
    })
    setLoading(false)
  }

  const changeRole = async (profile: any, role: string) => {
    setLoading(true)
    await new Promise(res => setTimeout(res, 1000))

    await supabase
      .from('profiles')
      .update({
        role,
      })
      .eq('id', profile.id)
    setProfiles((profiles || []).map((p: any) => p.id === profile.id ? { ...p, role } : p))
    showNotification({
      icon: <IconCheck />,
      color: 'green',
      title: 'Success',
      message: `${profile.email} role changed to ${role} successfully`,
    })
    setLoading(false)
  }

  const changeGroup = async (profile: any, group: any) => {
    setLoading(true)
    await new Promise(res => setTimeout(res, 1000))

    await supabase
      .from('profiles')
      .update({
        group_id: profile.group_id === group.id ? null : group.id,
      })
      .eq('id', profile.id)
    setProfiles((profiles || []).map((p: any) => p.id === profile.id ? { ...p, group_id: profile.group_id === group.id ? null : group.id } : p))
    showNotification({
      icon: <IconCheck />,
      color: 'green',
      title: 'Success',
      message: `${profile.email} ${profile.group_id === group.id ? 'leave from' : 'joined to'} ${group.name} successfully`,
    })
    setLoading(false)
  }

  const updateSelected = async () => {
    if (selected?.length) {
      setLoading(true)
      await new Promise(res => setTimeout(res, 1000))

      await Promise.allSettled([(async () => {
        if (selectedGroup) {
          await supabase
            .from('profiles')
            .update({
              group_id: selectedGroup === '-1' ? null : selectedGroup,
            })
            .in('id', selected.map((s: any) => s.id))
        }
      })(),
      (async () => {
        if (selectedRole) {
          await supabase
            .from('profiles')
            .update({
              role: selectedRole,
            })
            .in('id', selected.map((s: any) => s.id))
        }
      })()])

      setProfiles((profiles || []).map((p: any) =>
        selected.find(s => s.id === p.id) ? {
          ...p,
          role: selectedRole || p.role,
          group_id: selectedGroup === '-1' ? null : selectedGroup || p.group_id
        } : p))

      showNotification({
        icon: <IconCheck />,
        color: 'green',
        title: 'Success',
        message: 'Updated successfully',
      })
      setLoading(false)
    }
  }

  const bulkSuspend = async () => {
    if (selected?.length) {
      setLoading(true)
      await new Promise(res => setTimeout(res, 1000))

      await Promise.allSettled(selected.map(async (profile) => {
        await supabase
          .from('profiles')
          .update({
            deleted_at: profile.deleted_at ? null : new Date().toISOString(),
          })
          .eq('id', profile.id)
      }))

      setProfiles((profiles || []).map((p: any) => selected.find(s => s.id === p.id) ? {
        ...p, deleted_at: p.deleted_at ? null : new Date() } : p))
      showNotification({
        icon: <IconCheck />,
        color: 'green',
        title: 'Success',
        message: 'Updated successfully',
      })
      setLoading(false)
    }
  }

  const isSuperadmin = (app: any) => {
    return user?.profiles?.find(
      (my: Record<string, any>) => my.app_id === app?.id)?.role === 'superadmin'
  }

  const isMyProfile = (app: any, profile: any) => {
    return user?.profiles?.find(
      (my: Record<string, any>) => my.app_id === app?.id && my.id !== profile.id)
  }

  const BuildStrikeText = ({ profile, children }: { profile: any, children: ReactElement }) => {
    return profile.deleted_at ? <Tooltip
      position="top"
      label={`Suspended at ${dayjs(profile.deleted_at).format('MMM DD, HH:mm Z')}`}
      withArrow><Text strikethrough>{children}</Text>
    </Tooltip> : <Text>{children}</Text>
  }

  return <>
    <Drawer size={800} padding="xl" position="right" opened={!!app} onClose={() => setApp(undefined)} title={`Members of ${app?.name}`}>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} />
        <Tabs defaultValue="member">
          <Tabs.List>
            <Tabs.Tab value="member">Members ({profiles?.length})</Tabs.Tab>
            {(invitations?.length || isSuperadmin(app)) && <Tabs.Tab value="invitations">Invitations ({invitations?.length})</Tabs.Tab>}
            {(groups?.length || isSuperadmin(app)) && <Tabs.Tab value="groups">Groups ({groups?.length})</Tabs.Tab>}
          </Tabs.List>
          <Tabs.Panel value="member" pt="md">
            <Group position="apart">
              <Group>
                <Select size="xs" disabled={!selected?.length} data={[
                  'member', 'admin', 'superadmin'
                ]}
                value={selectedRole}
                onChange={e => setSelectedRole(e as string)}
                clearable placeholder="Change role..." />
                <Select size="xs" disabled={!selected?.length} data={
                  [
                    { label: 'Leave from group', value: '-1' },
                    ...(groups || []).map(g => ({ label: g.name, value: g.id }))
                  ]
                }
                value={selectedGroup}
                onChange={e => setSelectedGroup(e as string)}
                clearable placeholder="Change group..." />
                <Button size="xs" disabled={!selected?.length}
                  leftIcon={<IconBriefcase size={16} />}
                  onClick={updateSelected}
                  variant="light">Update</Button>
              </Group>
              <Group>
                <Button size="xs" disabled={!selected?.length}
                  leftIcon={<IconUserOff size={16} />}
                  variant="light"
                  onClick={bulkSuspend}
                  color="yellow">Un/Suspend</Button>
                {/* <Button size="xs" disabled={!selected?.length}
                  leftIcon={<IconTrash size={16} />}
                  variant="light"
                  color="red">Delete</Button> */}
              </Group>
            </Group>
            <ScrollArea style={{ overflow: 'initial' }} mt="lg">
              <Table striped>
                <thead>
                  <tr>
                    <th>
                      <Checkbox
                        checked={selected?.length === profiles?.length}
                        indeterminate={selected?.length > 0 && selected?.length < (profiles || []).length}
                        onChange={({ target }) => setSelected(target.checked ? profiles || [] : [])} />
                    </th>
                    <th style={{ minWidth: '220px' }}>
                      <Text p="xs" size="sm">Email</Text>
                    </th>
                    <th style={{ minWidth: '210px' }}>
                      <Text p="xs" size="sm">Name</Text>
                    </th>
                    <th style={{ minWidth: '100px' }}>
                      <Text p="xs" size="sm">Role</Text>
                    </th>
                    <th style={{ minWidth: '100px' }}>
                      <Text pl="xs" pr="xs" size="sm">Group</Text>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {profiles?.map((profile: any) => <tr key={profile.id}>
                    <td>
                      <Checkbox
                        checked={!!(selected || []).find(s => s.id === profile.id)}
                        onChange={({ target }) => setSelected(vals => [...vals.map(v => v.id === profile.id ? null : v), target.checked ? profile : null].filter(Boolean))} />
                    </td>
                    <td>
                      {isSuperadmin(app) ? <Menu withArrow>
                        <Menu.Target>
                          <Text component={Anchor} color="blue">{profile.email} {!isMyProfile(app, profile) ? '(you)' : ''}</Text>
                        </Menu.Target>
                        <Menu.Dropdown>
                          <Menu.Item icon={<IconUserSearch size={14} />} disabled={profile.role === 'member'} onClick={() => changeRole(profile, 'member')}>
                            Change to member
                          </Menu.Item>
                          <Menu.Item icon={<IconBriefcase size={14} />} disabled={profile.role === 'admin'} onClick={() => changeRole(profile, 'admin')}>
                            Change to admin
                          </Menu.Item>
                          <Menu.Item icon={<IconTie size={14} />} disabled={profile.role === 'superadmin'} onClick={() => changeRole(profile, 'superadmin')}>
                            Change to superadmin
                          </Menu.Item>
                          {groups?.length && <>
                            <Menu.Divider />
                            {groups.map((group: any, i: number) => <Menu.Item key={i} icon={<IconUsers size={14} />} onClick={() => changeGroup(profile, group)}>
                              {profile.group_id === group.id ? 'Leave from' : 'Join to'} {group.name}
                            </Menu.Item>)}
                          </>}

                          <Menu.Divider />
                          <Menu.Label>Danger zone</Menu.Label>
                          <Menu.Item onClick={async () => await suspendToggle(profile)} icon={
                            profile.deleted_at ? <IconUserCheck size={14} /> : <IconUserOff size={14} />
                          } color={profile.deleted_at ? 'green' : undefined}>
                            {profile.deleted_at ? 'Unsuspend' : 'Suspend'}
                          </Menu.Item>
                          <Menu.Item
                            color="red"
                            icon={<IconTrash size={14} />}
                            onClick={() => setDeleteMemberConfirmation(profile)}>
                              Delete
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu> : <Text color="dimmed">{profile.email}</Text>}
                    </td>
                    <td>
                      <BuildStrikeText profile={profile}>
                        {profile.name}
                      </BuildStrikeText>
                    </td>
                    <td>
                      <Badge color={(() => {
                        if (profile.deleted_at) {
                          return 'gray'
                        }
                        if (profile.role === 'admin') {
                          return 'pink'
                        }
                        if (profile.role === 'superadmin') {
                          return 'red'
                        }
                        return 'blue'
                      })()} variant="light">
                        {profile.role}
                      </Badge>
                    </td>
                    <td>
                      <Text>{groups?.find(g => g.id === profile.group_id)?.name || 'N/A'}</Text>
                    </td>
                  </tr>)}
                </tbody>
              </Table>
            </ScrollArea>
          </Tabs.Panel>
          <Tabs.Panel value="invitations" pt="md">
            {isSuperadmin(app) && <Paper withBorder p="xl" mb="xl">
              <form onSubmit={formInvitation.onSubmit(values => invite(values))}>
                <Grid>
                  <Col lg={6} md={12}>
                    <Input.Wrapper
                      required
                      label="Email"
                      error={formInvitation.errors.email}
                      withAsterisk>
                      <Input
                        placeholder={formInvitation.values.use_email_pattern ? '.*@example\\.com' : 'john@doe.com'}
                        {...formInvitation.getInputProps('email', { withError: true })} />
                    </Input.Wrapper>
                    <Input.Wrapper
                      mt="md"
                      required
                      withAsterisk>
                      <Checkbox
                        description={<>You can whitelist your company email domain by using <Text component="code" italic>.*@example\.com</Text> format.</>}
                        label="Use email pattern"
                        {...formInvitation.getInputProps('use_email_pattern', { type: 'checkbox' })} />
                    </Input.Wrapper>
                  </Col>
                  <Col lg={6} md={12}>
                    <Input.Wrapper
                      required
                      label="Role"
                      withAsterisk>
                      <Select
                        placeholder="Select role..."
                        data={['member', 'admin', 'superadmin']}
                        {...formInvitation.getInputProps('role')}
                      />
                    </Input.Wrapper>
                    <Group position="right" mt="xl">
                      <Button type="submit" leftIcon={<IconPlus size={18} />}>
                        Invite
                      </Button>
                    </Group>
                  </Col>
                </Grid>
              </form>
              <Divider mt="lg" mb="lg" label="Or" labelPosition="center" />
              <div style={{ textAlign: 'center' }}>
                <Button leftIcon={<IconBrandSlack size={20} />} variant="outline" onClick={() => {
                  // openIntegration(app)
                  // setApp(undefined)
                  setOpenImportUsers({
                    app_id: app.id
                  })
                }}>
                  Import Users from Slack
                </Button>
              </div>
            </Paper>}
            {invitations?.length ? <ScrollArea style={{ overflow: 'initial' }}>
              <Table striped withColumnBorders>
                <thead>
                  <tr>
                    <td style={{ minWidth: '220px' }}>
                      <Text pl="xs" pr="xs" size="sm" component="strong">Email</Text>
                    </td>
                    <td style={{ minWidth: '117px' }}>
                      <Text pl="xs" pr="xs" size="sm" component="strong">Email Pattern</Text>
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <Text pl="xs" pr="xs" size="sm" component="strong">Role</Text>
                    </td>
                    <td style={{ minWidth: '120px' }}>
                      <Text pl="xs" pr="xs" size="sm" component="strong">Invited At</Text>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {invitations?.map((profile: any) => <tr key={profile.id}>
                    <td>
                      {isSuperadmin(app) ? <Menu withArrow>
                        <Menu.Target>
                          <Text component={Anchor} color="blue">{profile.email}</Text>
                        </Menu.Target>
                        <Menu.Dropdown>
                          <Menu.Item color="red" icon={<IconTrash size={18} />}
                            onClick={async () => await deleteInvitation(profile)}>Delete</Menu.Item>
                        </Menu.Dropdown>
                      </Menu> : <Text color="blue">{profile.email}</Text>}
                    </td>
                    <td>
                      <Checkbox checked={profile.use_email_pattern} readOnly />
                    </td>
                    <td>
                      <Badge color={(() => {
                        if (profile.deleted_at) {
                          return 'gray'
                        }
                        if (profile.role === 'admin') {
                          return 'pink'
                        }
                        if (profile.role === 'superadmin') {
                          return 'red'
                        }
                        return 'blue'
                      })()} variant="light">
                        {profile.role}
                      </Badge>
                    </td>
                    <td>
                      <Text>{dayjs(profile.created_at).format('MMM DD, HH:mm')}</Text>
                    </td>
                  </tr>)}
                </tbody>
              </Table>
            </ScrollArea> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="groups" pt="md">
            {isSuperadmin(app) && <Paper withBorder p="xl" mb="xl">
              <form onSubmit={formGroup.onSubmit(values => createGroup(values))}>
                <Group>
                  <Input.Wrapper
                    required
                    label="Name"
                    error={formGroup.errors.name}
                    withAsterisk>
                    <Input
                      placeholder="Part-time"
                      {...formGroup.getInputProps('name', { withError: true })} />
                  </Input.Wrapper>
                  <Group position="right" mt="xl">
                    <Button type="submit" leftIcon={<IconUsers size={16} />}>
                      {formGroup.values.id ? 'Update' : 'Create'}
                    </Button>
                  </Group>
                </Group>
              </form>
            </Paper>}
            {groups?.length ? <ScrollArea style={{ overflow: 'initial' }}>
              <Table striped>
                <thead>
                  <tr>
                    <td style={{ minWidth: '210px' }}>
                      <Text p="xs" size="sm" component="strong">Name</Text>
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <Text p="xs" size="sm" component="strong"></Text>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {groups?.map((group: any) => <tr key={group.id}>
                    <td>
                      {group.name} ({profiles?.filter((profile: any) => profile.group_id === group.id).length || 0})
                    </td>
                    <td>
                      <Group>
                        <Button size="xs" variant="light" color="green" leftIcon={<IconNotification size={14} />} onClick={() => setOpenNotifications(app)}>
                          Notifications
                        </Button>
                        <Button size="xs" variant="light" leftIcon={<IconPencil size={14} />} onClick={() => updateGroup(group)}>
                          Update
                        </Button>
                        <Button disabled={!!profiles?.filter((profile: any) => profile.group_id === group.id).length} size="xs" variant="light" color="red" leftIcon={<IconTrash size={14} />} onClick={() => removeGroup(group.id)}>
                          Delete
                        </Button>
                      </Group>
                    </td>
                  </tr>)}
                </tbody>
              </Table>
            </ScrollArea> : <></>}
          </Tabs.Panel>
        </Tabs>
      </div>
    </Drawer>
    <DeleteMember
      data={deleteMemberConfirmation}
      setData={setDeleteMemberConfirmation}
      onDeleted={data => setProfiles((profiles || [])?.filter((profile: any) => profile.id !== data?.id))} />
    <ImportUsers data={openImportUsers} onClose={() => setOpenImportUsers(undefined)} onFinish={() => {
      setOpenImportUsers(undefined)
    }} />
    <Notifications app={openNotifications} onClose={() => setOpenNotifications(undefined)} />
  </>
}