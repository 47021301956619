import { LoadingOverlay, Text } from '@mantine/core'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

export default function ({ data, loading, title: Title }: { data: any[], title?: React.ReactNode, loading?: boolean }) {
  const [stats, setStats] = useState<Record<string, number | string>>({})

  useEffect(() => {
    const presences = data
    if (presences?.length) {
      const getTotal = (data = presences) => data.reduce((res, item) => {
        const [active1, away1] = (item.details || []).reduce((res1: [number, number], item1: any) => {
          res1[item1.status === 'active' ? 0 : 1] += dayjs(item1.ended_at || Math.min(
            dayjs(item1.started_at).utc().endOf('day').toDate().getTime(),
            dayjs().utc().toDate().getTime()
          )).utc().diff(dayjs(item1.started_at))
          return res1
        }, [0, 0])
        return [res[0] + active1, res[1] + away1]
      }, [0, 0])

      const groupWeeks = presences
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .reduce((res, item) => {
          const week = dayjs(item.date).subtract(1, 'day').week().toString()
          return { ...res, [week]: [...res[week] || [], item] }
        }, {} as Record<number, any[]>)

      const peekWeek: { total: number, data: any[] } = { total: 0, data: [] }
      for (const week of Object.keys(groupWeeks)) {
        const [active] = getTotal(groupWeeks[week])
        if (peekWeek.total < active) {
          peekWeek.total = active
          peekWeek.data = groupWeeks[week]
        }
      }

      const weekends = presences.filter(item => dayjs(item.date).day() === 0 || dayjs(item.date).day() === 6)
      const peekWeekend: { total: number, data: any } = { total: 0, data: null }
      for (const week of weekends) {
        const [active] = getTotal([week])
        if (peekWeekend.total < active) {
          peekWeekend.total = active
          peekWeekend.data = week
        }
      }

      const [active, away] = getTotal()
      setStats({
        daysLength: presences?.length,
        weeksLength: Math.round(presences?.length / 7),
        activeTotal: active,
        awayTotal: away,
        avgActivePerDay: active / presences?.length,
        avgActivePerWeek: active / (presences?.length / 7),
        peakActiveWeek: peekWeek.total,
        peakActiveWeekRange: `${dayjs(peekWeek.data[0].date).format('D MMM')} - ${dayjs(peekWeek.data[peekWeek.data.length - 1].date).format('D MMM')}`,
        activeWeekendsTotal: getTotal(weekends)[0],
        peakActiveWeekend: peekWeekend.total,
        peakActiveWeekendDate: peekWeekend.data ? dayjs(peekWeekend.data?.date).format('D MMM') : '-',
      })
    }
  }, [data])

  return <div style={{ position: 'relative' }}>
    {Title}
    <LoadingOverlay visible={loading || false} />
    <Text component="p" mt={Title ? 'lg' : 0}>Stats in the last <strong>{stats?.daysLength} days</strong> or <strong>±{stats?.weeksLength} weeks</strong>:</Text>
    <Text component="p" size="sm" mt="md" color="dimmed" style={{ marginBottom: '3px' }}><strong>Total Active vs Away</strong></Text>
    <table>
      <tbody>
        <tr>
          <td>
            <Text size="sm" color="dimmed" component="span">total active time:</Text>
          </td>
          <td>
            <Text size="sm">
              {
                Math.floor(stats?.activeTotal as number / 60_000 / 60)
              } hrs {
                Math.floor(stats?.activeTotal as number / 60_000 % 60)
              } min
            </Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text size="sm" color="dimmed" component="span">total away time:</Text>
          </td>
          <td>
            <Text size="sm">
              {
                Math.floor(stats?.awayTotal as number / 60_000 / 60)
              } hrs {
                Math.floor(stats?.awayTotal as number / 60_000 % 60)
              } min
            </Text>
          </td>
        </tr>
      </tbody>
    </table>
    <Text component="p" size="sm" mt="md" color="dimmed" style={{ marginBottom: '3px' }}><strong>Average</strong></Text>
    <table>
      <tbody>
        <tr>
          <td>
            <Text size="sm" color="dimmed" component="span">active time/day:</Text>
          </td>
          <td>
            <Text size="sm">
              {
                Math.floor(stats?.avgActivePerDay as number / 60_000 / 60)
              } hrs {
                Math.floor(stats?.avgActivePerDay as number / 60_000 % 60)
              } min
            </Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text size="sm" color="dimmed" component="span">active time/week:</Text>
          </td>
          <td>
            <Text size="sm" color="blue" component="span">
              {
                Math.floor(stats?.avgActivePerWeek as number / 60_000 / 60)
              } hrs {
                Math.floor(stats?.avgActivePerWeek as number / 60_000 % 60)
              } min
            </Text>
          </td>
        </tr>
      </tbody>
    </table>
    <Text component="p" size="sm" mt="md" color="dimmed" style={{ marginBottom: '3px' }}><strong>Weekend Fighter</strong></Text>
    <table>
      <tbody>
        <tr>
          <td>
            <Text size="sm" color="dimmed" component="span">total active time:</Text>
          </td>
          <td>
            <Text size="sm">
              {
                Math.floor(stats?.activeWeekendsTotal as number / 60_000 / 60)
              } hrs {
                Math.floor(stats?.activeWeekendsTotal as number / 60_000 % 60)
              } min
            </Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text size="sm" color="dimmed" component="span">peak active time:</Text>
          </td>
          <td>
            <Text size="sm">
              {
                Math.floor(stats?.peakActiveWeekend as number / 60_000 / 60)
              } hrs {
                Math.floor(stats?.peakActiveWeekend as number / 60_000 % 60)
              } min <Text component="span" italic color="dimmed">on {stats?.peakActiveWeekendDate}</Text>
            </Text>
          </td>
        </tr>
      </tbody>
    </table>
    <Text component="p" size="sm" mt="md" color="dimmed" style={{ marginBottom: '3px' }}><strong>Peak Active Time of the Week</strong></Text>
    <Text size="sm">
      {
        Math.floor(stats?.peakActiveWeek as number / 60_000 / 60)
      } hrs {
        Math.floor(stats?.peakActiveWeek as number / 60_000 % 60)
      } min <Text component="span" italic color="dimmed">on {stats?.peakActiveWeekRange}</Text>
    </Text>
  </div>
}