import { Col, Container, createStyles, Grid, Group, LoadingOverlay, ScrollArea, Text, Timeline, Title } from '@mantine/core'
import { DateRangePicker } from '@mantine/dates'
import { useLocalStorage } from '@mantine/hooks'
import { IconCalendar, IconFileUnknown, IconTimelineEvent } from '@tabler/icons'
import { ArcElement, CategoryScale, Chart, Filler, Legend, LinearScale, LineElement, PointElement, Title as TitleElement, Tooltip as TooltipChart } from 'chart.js'
import dayjs from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'
import { useCallback, useEffect, useState } from 'react'
import ReactCalendarHeatmap from 'react-calendar-heatmap'
import { Line, Pie } from 'react-chartjs-2'
import { useParams, useSearchParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import PaymentNeed from '../../components/PaymentNeed'
import TextWithEmoji from '../../components/TextWithEmoji'
import UserHeader from '../../components/UserHeader'
import usePayment from '../../hooks/usePayment'
import { supabase } from '../../services/supabase'
import MonthlyStats from './MonthlyStats'

const useStyles = createStyles((theme) => ({
  empty: { fill: theme.colorScheme === 'dark' ? '#25262b' : '#ecf6ec' },
  scale0: { fill: theme.colorScheme === 'dark' ? '#25262b' : '#ecf6ec' },
  scale1: { fill: theme.colorScheme === 'dark' ? '#2f343d' : '#d7ecd7' },
  scale2: { fill: theme.colorScheme === 'dark' ? '#384351' : '#c2e3c2' },
  scale3: { fill: theme.colorScheme === 'dark' ? '#3f5666' : '#addaac' },
  scale4: { fill: theme.colorScheme === 'dark' ? '#456d7c' : '#97d197' },
  scale5: { fill: theme.colorScheme === 'dark' ? '#498b95' : '#82c881' },
  scale6: { fill: theme.colorScheme === 'dark' ? '#4caead' : '#6dbf6b' },
  scale7: { fill: theme.colorScheme === 'dark' ? '#57bfaf' : '#57b755' },
  scale8: { fill: theme.colorScheme === 'dark' ? '#67ccae' : '#48a746' },
  scale9: { fill: theme.colorScheme === 'dark' ? '#77d8ae' : '#3f923c' },
  scale10: { fill: theme.colorScheme === 'dark' ? '#89e2af' : '#357d32' },
  scale11: { fill: theme.colorScheme === 'dark' ? '#9debb3' : '#2b6829' },
  scale12: { fill: theme.colorScheme === 'dark' ? '#b2f2bb' : '#225220' },
}))

export default function () {
  Chart.register(
    ArcElement,
    TooltipChart,
    Legend,
    TitleElement,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Filler
  )

  const [selectedProfile] = useLocalStorage<Record<string, any> | null>({
    key: 'selected-profile', defaultValue: null })
  const params = useParams<{ id: string }>()
  const [profile, setProfile] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<any[]>()
  const [query, setQuery] = useSearchParams()
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null])
  const [errors, setErrors] = useState<string[]>([])
  const [timelineData, setTimelineData] = useState<any[]>()
  const [valid, setValid] = useState<boolean>(true)
  const { check } = usePayment()
  const { classes } = useStyles()

  useEffect(() => {
    if (selectedProfile?.app_id) {
      check().then((value: any) => setValid(value))
    }
  }, [selectedProfile])

  useEffect(() => {
    if (params.id) {
      (async () => {
        const { data, error } = await supabase
          .from('profiles')
          .select()
          .eq('id', params.id)
        if (data?.length) {
          setProfile(data[0])
        } else {
          if (error) {
            setErrors([...errors || [], error.message])
          } else {
            setErrors([...errors || [], 'Profile data not found.'])
          }
        }
      })()
    }
  }, [params?.id])

  useEffect(() => {
    if (params.id) {
      (async () => {
        setLoading(true)
        const { data, error } = await supabase
          .from('presences')
          .select('date, profile_id, est_active_duration, est_away_duration, details, last_status, last_status_text, last_status_emoji')
          .gte('date', dayjs().subtract(1, 'year').format('YYYY-MM-DD'))
          .eq('profile_id', params.id)
          .order('date', { ascending: true })
        if (data?.length) {
          setData(data)
        } else {
          if (error) {
            setErrors([...errors || [], error.message])
          } else {
            setErrors([...errors || [], 'Data presences not found.'])
          }
        }
        setLoading(false)
      })()
    }
  }, [params?.id])

  useEffect(() => {
    if (data?.length) {
      const el = document.querySelector('.heatmapcal-scroll .mantine-ScrollArea-viewport')
      if (el) {
        el.scrollLeft += 9999
      }
    }
  }, [data, dateRange])

  useEffect(() => {
    if (query.get('date')) {
      const [start, end] = (query.get('date') as string).split('_')
      setDateRange([dayjs(start).toDate(), dayjs(end).toDate()])
    } else {
      query.set('date', `${dayjs().format('YYYY-MM-DD')}_${dayjs().format('YYYY-MM-DD')}`)
      setQuery(query)
    }
  }, [query])

  useEffect(() => {
    if (data?.length && dateRange[0] && dateRange[1]) {
      const timelines = (data || [])
        .filter((item: any) => dayjs(item.date).isBetween(...dateRange, 'day', '[]'))
        .reduce((res, d) => [...res, ...d.details || []], []).sort((a: any, b: any) =>
          new Date(a.started_at).getTime() - new Date(b.started_at).getTime())
        .map((item: any) => {
          return { ...item, duration: dayjs.duration(new Date(
            item.ended_at || Math.min(
              dayjs(item.started_at).utc().endOf('day').utc().toDate().getTime(),
              dayjs().utc().toDate().getTime()
            )
          ).getTime() - new Date(item.started_at).getTime()) }
        })
        .reduce((res: any[], d: any) => {
          const last = res[res.length - 1]
          if (last) {
            if (d.status_emoji && d.status_emoji === last.status_emoji
              || d.status_text && d.status_text === last.status_text
              || d.status_emoji === last.status_emoji && d.status_text === last.status_text && (
                d.duration.asMinutes() < 60
                || last.data.reduce((res: Duration, i: any) => res.add(i.duration), dayjs.duration(0)).asMinutes() < 60
              )) {
              const data = [...last.data || [], d]
              const active = data.reduce((res, i) => res + (i.status === 'active' ? i.duration.asMilliseconds() : 0), 0)
              const away = data.reduce((res, i) => res + (i.status === 'away' ? i.duration.asMilliseconds() : 0), 0)
              return [...res.slice(0, res.length - 1), {
                status: active > away ? 'active' : 'away',
                active_duration: active,
                away_duration: away,
                status_text: d.status_text,
                status_emoji: d.status_emoji,
                data
              }]
            }
          }
          return [...res, {
            status: d.status,
            active_duration: d.status === 'active' ? d.duration.asMilliseconds() : 0,
            away_duration: d.status === 'away' ? d.duration.asMilliseconds() : 0,
            status_text: d.status_text,
            status_emoji: d.status_emoji,
            data: [d]
          }]
        }, [])
      setTimelineData(timelines)
    }
  }, [data, dateRange])

  const changeDateRange = (vals: [Date | null, Date | null]) => {
    if (vals[0] && vals[1]) {
      query.set('date', vals.map(x => dayjs(x).format('YYYY-MM-DD')).join('_'))
      setQuery(query)
    }
  }

  const getDuration = useCallback((status: 'active' | 'away' = 'active', startedAt: Date | null = dateRange[0], endedAt: Date | null = dateRange[1]) => {
    const activeDuration = (data || [])
      .filter((item: any) => dayjs(item.date).isBetween(startedAt, endedAt, 'day', '[]'))
      .reduce((res, item) => [...res, ...item.details || []], [])
      .filter((item: any) => item.status === 'active')
      .reduce((res: number, detail: any) => {
        return res += dayjs(detail.ended_at || Math.min(
          dayjs(detail.started_at).utc().endOf('day').toDate().getTime(),
          dayjs().utc().toDate().getTime()
        )).utc().diff(dayjs(detail.started_at))
      }, 0)
    if (status === 'active') return activeDuration
    return dayjs(endedAt).endOf('day').diff(dayjs(startedAt).startOf('day')) - activeDuration
  }, [dateRange, data])

  return valid ? <Container mb="xl" mt="xl">
    {errors?.length ? <div style={{ textAlign: 'center', marginTop: '140px' }}>
      <Title order={3}>
        Something wrong 😱
      </Title>
      {errors.map((error, index) => <Text size="sm" color="dimmed" component="p" key={index}>{error}</Text>)}
    </div> : <>
      <UserHeader
        profile={profile}
        breadcrumbs={[
          { label: 'Home', link: '/' },
          { label: 'Presence', link: '/presence' },
          { label: profile?.name, active: true },
        ]}
        status={data?.[data?.length - 1]?.last_status}
        additonal={data?.[data?.length - 1]?.last_status_emoji &&
          <TextWithEmoji text={`${data?.[data?.length - 1].last_status_emoji} ${
            (data?.[data?.length - 1].last_status_text || '')
              .replace(' (via Clockwise)', '')
              .replace(' • Google Calendar', '')}`} />} />

      <ReactTooltip />
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} />
        <ScrollArea className="heatmapcal-scroll">
          <ReactCalendarHeatmap
            startDate={dayjs().subtract(1, 'year').format('YYYY-MM-DD')}
            endDate={dayjs().format('YYYY-MM-DD')}
            onClick={(val: any) => val?.count ? changeDateRange([val.date, val.date]) : undefined}
            values={(data || []).map(item => ({
              date: dayjs(item.date).format('YYYY-MM-DD'),
              count: getDuration('active', new Date(item.date), new Date(item.date))
              // count: item.est_active_duration
            }))}
            classForValue={(value: any) => {
              if (!value) {
                return classes.empty
              }
              return classes[`scale${Math.round(value.count / 86400_000 * 12)}` as keyof typeof classes]
            }}
            tooltipDataAttrs={(value: any) => {
              return { 'data-tip': value.date ? `${dayjs(value.date).format('MMM D')}: ${
                Math.floor(value.count / 60_000 / 60)
              }h ${
                Math.floor(value.count / 60_000 % 60)
              }m` : '' }
            }}
          />
        </ScrollArea>
      </div>

      <Grid mt="lg" style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} />
        <Col md={4} sm={12} pb="lg">
          <MonthlyStats loading={loading} data={data?.filter(
            (item: any) => dayjs(item.date).isAfter(dayjs().subtract(1, 'month'))) || []} />
        </Col>
        <Col md={8} sm={12}>
          <DateRangePicker allowSingleDateInRange value={dateRange} onChange={changeDateRange} placeholder="Pick date..." clearable={false} maxDate={
            dateRange[0] && !dateRange[1] ? new Date(
              Math.min(
                new Date().getTime(),
                dayjs(dateRange[0]).add(1, 'week').toDate().getTime()
              )) : new Date()
          } icon={<IconCalendar />} />
          <ScrollArea>
            <Line height={60} style={{ marginTop: '20px', paddingBottom: '10px', marginBottom: '2px', minWidth: '534.5px' }} options={{
              plugins: {
                legend: { display: false },
                tooltip: { callbacks: { label: (context) => {
                  return context.raw == '1' ? 'active' : 'away'
                } } }
              },
              scales: { y: { ticks: {
                callback: (value) => {
                  if (value === 1) return 'active'
                  if (value === 0) return 'away'
                } } } } }} data={{
              labels: dateRange.filter(Boolean).length === 2 ?
                dayjs.duration(dayjs(dateRange[1]).diff(dayjs(dateRange[0]))).asDays() === 0
                  ? Array.from(Array(
                    Math.round(dayjs.duration(dayjs(dateRange[1]).endOf('day').diff(dayjs(dateRange[0]).startOf('day'))).asMinutes())
                  ).keys()).map(i => dayjs(dateRange[0]).utc(false).startOf('day').add(i, 'minutes').format('HH:mm'))
                  : Array.from(Array(
                    Math.round(dayjs.duration(dayjs(dateRange[1]).endOf('day').diff(dayjs(dateRange[0]).startOf('day'))).asHours()) * 2
                  ).keys()).map(i => dayjs(dateRange[0]).utc(false).startOf('day').add(1, 'day').add(i * 30, 'minutes').format('MMM DD, HH:mm'))
                : [],
              datasets: [
                {
                  label: 'Active',
                  fill: true,
                  data: dateRange.filter(Boolean).length === 2 ?
                    dayjs.duration(dayjs(dateRange[1]).diff(dayjs(dateRange[0]))).asDays() === 0
                      ? Array.from(Array(
                        Math.round(dayjs.duration(dayjs(dateRange[1]).endOf('day').diff(dayjs(dateRange[0]).startOf('day'))).asMinutes())
                      ).keys()).map(i => {
                        const buildDate = dayjs(
                          dayjs(dateRange[0]).toDate().toISOString()
                        ).startOf('day').add(i, 'minutes')
                        const details = (data || [])
                          .filter((d: any) => dayjs(d.date).isBetween(...dateRange, 'day', '[]'))
                          .reduce((res, d) => [...res, ...d.details || []], [])
                        const found = (details || []).find((d: any) => buildDate.isBetween(new Date(d.started_at), new Date(
                          d.ended_at || Math.min(
                            new Date().getTime(),
                            dayjs(d.started_at).endOf('day').toDate().getTime()
                          )
                        ), 'minutes', '[]'))
                        return found?.status === 'active' ? 1 : 0
                      })
                      : Array.from(Array(
                        Math.round(dayjs.duration(dayjs(dateRange[1]).endOf('day').diff(dayjs(dateRange[0]).startOf('day'))).asHours()) * 2
                      ).keys()).map(i => {
                        const buildDate = dayjs(
                          dayjs(dateRange[0]).toDate().toISOString()
                        ).startOf('day').add(i * 30, 'minutes')
                        const details = (data || [])
                          .filter((d: any) => dayjs(d.date).isBetween(...dateRange, 'day', '[]'))
                          .reduce((res, d) => [...res, ...d.details || []], [])
                        const found = (details || []).find((d: any) => buildDate.isBetween(new Date(d.started_at), new Date(
                          d.ended_at || Math.min(
                            new Date().getTime(),
                            dayjs(d.started_at).utc().endOf('day').toDate().getTime()
                          )
                        ), 'minutes', '[]'))
                        return found?.status === 'active' ? 1 : 0
                      })
                    : [],
                  borderColor: 'rgb(53, 162, 235)',
                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
              ]
            }} />
          </ScrollArea>
          <Grid mt="lg">
            <Col xs={12} sm={5} mb="xl">
              <Pie style={{ maxWidth: '259px', marginLeft: 'auto', marginRight: 'auto' }} options={{
                plugins: {
                  legend: { position: 'top' },
                  tooltip: { callbacks: { label: (context) => {
                    return ` ${context.label}: ${
                      Math.floor(context.parsed / 60_000 / 60)
                    } hrs ${
                      Math.floor(context.parsed / 60_000 % 60)
                    } min`
                  } } }
                }
              }} data={{
                labels: [
                  'Active',
                  'Away',
                ],
                datasets: [{
                  label: 'Active vs Away (in minutes)',
                  data: [
                    getDuration('active'),
                    getDuration('away'),
                  ],
                  backgroundColor: [
                    'rgb(54, 162, 235)',
                    'rgb(255, 99, 132)',
                  ],
                  hoverOffset: 4
                }]
              }} />
            </Col>
            <Col xs={12} sm={7}>
              <Container>
                <Timeline lineWidth={6} bulletSize={26} mb="xl">
                  {timelineData?.map((item: any, i: number) =>
                    <Timeline.Item key={i} bullet={item.status_emoji ? <TextWithEmoji text={item.status_emoji} callback={<IconFileUnknown size={16} />} /> : <IconTimelineEvent size={16} />} title={<Group position="apart">
                      <Group spacing="xs">
                        <Text>
                          {dayjs(item.data?.[0].started_at).format('HH:mm')} - {
                            item.data?.[item.data?.length - 1].ended_at
                              ? dayjs(item.data?.[item.data?.length - 1].ended_at).subtract(1, 'minute').format('HH:mm')
                              : dayjs(item.data?.[item.data?.length - 1].started_at).utc().endOf('day').add(-1 * new Date().getTimezoneOffset(), 'minutes').isBefore(dayjs())
                                ? dayjs(item.data?.[item.data?.length - 1].started_at).utc().endOf('day').add(-1 * new Date().getTimezoneOffset(), 'minutes').format('HH:mm')
                                : 'now'}
                        </Text>
                      </Group>
                      <Text>
                        {dayjs(item.data?.[0].started_at).format('ddd, MMM D')}
                      </Text>
                    </Group>}>
                      {item.status_emoji ? <Text color="dimmed" size="sm" key="description">
                        Status changed to: <Text color="dimmed" component="span" inherit>
                          <TextWithEmoji text={`${item.status_emoji} ${item.status_text?.replace(' (via Clockwise)', '').replace(' • Google Calendar', '')}`} />
                        </Text>
                      </Text> : <Text color="dimmed" size="sm" key="description">
                        Most likely {item.status} during this period
                      </Text>}
                      <Text size="xs" mt={4} key="duration">
                        {item.active_duration ? <Text component="span">{dayjs.duration(item.active_duration).humanize()} active</Text> : ''}
                        {item.active_duration && item.away_duration ? <Text component="span"> and </Text> : ''}
                        {item.away_duration ? <Text component="span">{dayjs.duration(item.away_duration).humanize()} away</Text> : ''}
                      </Text>
                    </Timeline.Item>)
                  }
                </Timeline>
              </Container>
            </Col>
          </Grid>
        </Col>
      </Grid>
    </>}
  </Container> : <PaymentNeed />
}