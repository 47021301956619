import { Accordion, Button, Checkbox, Col, Grid, Group, Input, Popover, Text, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useLocalStorage } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { Icon2fa, IconCheck, IconFingerprint, IconKey, IconPlus, IconX } from '@tabler/icons'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import useWebAuthn from '../../hooks/useWebAuthn'
import { supabase } from '../../services/supabase'
import { UserProfile } from '../../types'

interface SecurityKeyInterface {
  name: string,
  is_passkey?: boolean
}

export default function () {
  const [user] = useLocalStorage<UserProfile | null>({ key: 'user', defaultValue: null })
  const [keys, setKeys] = useState<any[] | null>([])
  const form = useForm<SecurityKeyInterface>({
    initialValues: {
      name: '',
      is_passkey: false
    }
  })
  const { register } = useWebAuthn()

  const fetchSecretKeys = async () => {
    const { data } = await supabase
      .from('security_keys')
      .select()
      .not('credential_id', 'is', null)
    setKeys(data?.filter((k: any) => k.credential_id) || [])
  }

  useEffect(() => {
    if (user) {
      fetchSecretKeys()
    }
  }, [user])

  const addSecurityKey = async (values: SecurityKeyInterface) => {
    if (!values.name) {
      return form.setFieldError('name', 'Name is required')
    }
    try {
      await register(values.name, values.is_passkey)
      form.reset()
      fetchSecretKeys()
      showNotification({
        id: 'verification-succeed',
        icon: <IconCheck />,
        color: 'green',
        title: 'Verification succeed',
        message: 'Your key has been added successfully',
      })
    } catch (error: any) {
      showNotification({
        id: 'verification-failed',
        icon: <IconX />,
        color: 'red',
        title: 'Verification failed',
        message: error.response?.data.error || error.message || 'Please reload and try again',
      })
    }
  }

  return <>
    <Title mt="xl" order={4}>
      Security Keys
    </Title>
    <Grid mt="sm" mb="xl">
      <Col md={8} sm={12}>
        <Accordion variant="contained">
          {keys?.map((key: any, i: number) => <Accordion.Item value={i.toString()} key={i}>
            <Accordion.Control icon={key.is_passkey ? <IconFingerprint size={20} /> : <Icon2fa size={20} />}>
              <Group position="apart">
                <Text>{key.name}</Text>
                <Text component="span" color="dimmed" italic>({key.credential_id.slice(0, 10)}...)</Text>
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              <Group>
                <Text size="sm" color="dimmed">Added at: {dayjs(key.created_at).format('MMM DD, YYYY HH:mm')}</Text>
                <Popover withArrow width={200}>
                  <Popover.Target>
                    <Button color="red" size="xs" radius="md">Remove</Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Text component="p">
                      Are you sure you want to remove <Text component="strong">{key.name}</Text>?
                    </Text>
                    <Button variant="light" color="blue" radius="md" size="sm" fullWidth onClick={async () => {
                      await supabase
                        .from('security_keys')
                        .delete()
                        .eq('id', key.id)
                      setKeys(keys?.filter((k: any) => k.id !== key.id))
                    }}>
                      Yes, I'm confirm
                    </Button>
                  </Popover.Dropdown>
                </Popover>
              </Group>
              <Text mt="lg" mb="lg" size="sm" style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}>ID: <Text size="sm" component="code">{key.credential_id}</Text></Text>
              <Text size="sm" component="code" style={{ whiteSpace: 'break-spaces' }}>-----BEGIN PUBLIC KEY-----</Text><br />
              <Text size="sm" component="code" style={{ whiteSpace: 'break-spaces' }}>{window.btoa(key.details.credentialPublicKey.data)}</Text><br />
              <Text size="sm" component="code" style={{ whiteSpace: 'break-spaces' }}>-----END PUBLIC KEY-----</Text>
            </Accordion.Panel>
          </Accordion.Item>)}
          <Accordion.Item value="999" key={999}>
            <Accordion.Control icon={<IconPlus size={20} />}>
              Add a secret key
            </Accordion.Control>
            <Accordion.Panel>
              <form onSubmit={form.onSubmit(values => addSecurityKey(values))}>
                <Input.Wrapper
                  required
                  label="Name"
                  withAsterisk
                  error={form.errors.name}>
                  <Input
                    placeholder="yubikey-1027"
                    maxLength={20}
                    mb="sm"
                    {...form.getInputProps('name')} />
                  <Text
                    style={{ float: 'right' }}
                    color="dimmed"
                    size="xs">{form.values.name.length} / 20</Text>
                </Input.Wrapper>
                <Input.Wrapper>
                  <Checkbox
                    label="Use as a passkey"
                    description="Enable this for passwordless authentication"
                    {...form.getInputProps('is_passkey', { type: 'checkbox' })}
                  />
                </Input.Wrapper>
                <div style={{ clear: 'both' }} />
                <div style={{ textAlign: 'right' }}>
                  <Button mt="md" type="submit" leftIcon={<IconKey size={18} />}>
                    Register
                  </Button>
                </div>
              </form>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Grid>
  </>
}