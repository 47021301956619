import { Anchor, Badge, Box, Button, Container, Group, Paper, Popover, Text, Title } from '@mantine/core'
import { RangeCalendar } from '@mantine/dates'
import { useLocalStorage } from '@mantine/hooks'
import { IconDatabase, IconMailbox } from '@tabler/icons'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Empty from '../../components/Empty'
import PaymentNeed from '../../components/PaymentNeed'
import SupabaseTable from '../../components/SupabaseTable'
import usePayment from '../../hooks/usePayment'
import { supabase } from '../../services/supabase'
import Form from './Form'

export default function () {
  const [selectedProfile] = useLocalStorage<Record<string, any> | null>({
    key: 'selected-profile', defaultValue: null })
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [refetch, setRefetch] = useState<boolean>(false)
  const navigate = useNavigate()
  const [valid, setValid] = useState<boolean>(true)
  const { check } = usePayment()

  useEffect(() => {
    if (selectedProfile?.app_id) {
      check().then((value: any) => setValid(value))
    }
  }, [selectedProfile])

  useEffect(() => {
    if (selectedProfile?.role && selectedProfile?.app?.leave_request_read && !(selectedProfile?.app?.leave_request_read || []).includes(selectedProfile?.role)) {
      navigate(`/leave/${selectedProfile?.id}`)
    }
  }, [selectedProfile?.app?.leave_request_read])

  return valid ? <Container mb="xl" mt="xl" style={{ position: 'relative' }}>
    <Paper p="lg" withBorder mb="xl" shadow="sm" radius="md">
      <Group position="apart">
        <div>
          <Title order={2}>
            Leave Requests
          </Title>
          <Text color="dimmed" italic size="sm" mt="xs">
            All submitted requests are shown below.
          </Text>
        </div>
        {(selectedProfile?.app?.leave_request_write || []).includes(selectedProfile?.role) && <Button onClick={() => setOpenForm(true)} variant="light" color="pink" leftIcon={<IconMailbox size={18} />}>
          Submit Leave Request
        </Button>}
      </Group>
    </Paper>

    <SupabaseTable
      flattenColumn="leave_requests"
      query={opt => supabase
        .from('profiles')
        .select(`
          id,
          app_id,
          name,
          integration_profile_data,
          leave_requests!leave_requests_profile_id_fkey!inner (
            id,
            year,
            started_at,
            ended_at,
            status,
            duration_in_days,
            created_at
          )
        `, opt?.checkCount ? { count: 'estimated', head: true } : undefined)
        .eq('app_id', selectedProfile?.app_id)
        .order('created_at', { ascending: false, foreignTable: 'leave_requests' })}
      dependencies={[selectedProfile?.app_id]}
      columns={[
        {
          key: 'name',
          label: 'Name',
          minWidth: 300,
          sort: 'name',
          render: (value: any, row: any) => <Anchor component={Link} to={`/leave/${row?.id}`}>
            {value}
          </Anchor>
        },
        {
          key: 'leave_requests__started_at',
          label: 'Date Range',
          minWidth: 160,
          render: (value: any, row: any) => <div style={{ margin: 'auto' }}>
            <Popover withArrow>
              <Popover.Target>
                <Anchor>
                  {/* {dayjs(value).format('YYYY, MMM DD, HH:mm')} &mdash; {dayjs(row.leave_requests__ended_at).format('MMM DD, HH:mm')} */}
                  {dayjs(value).format('YYYY, MMM')} {dayjs(value).format('D')}{(() => {
                    const result = `${dayjs(value).format('MMM') !== dayjs(row.leave_requests__ended_at).format('MMM')
                      ? dayjs(row.leave_requests__ended_at).format('MMM') : ''} ${
                      dayjs(value).format('D') !== dayjs(row.leave_requests__ended_at).format('D')
                        ? dayjs(row.leave_requests__ended_at).format('D') : ''}`
                    return result.replaceAll(' ', '') ? ` - ${result}` : ''
                  })()}
                </Anchor>
              </Popover.Target>
              <Popover.Dropdown>
                <Box>
                  <RangeCalendar styles={{
                    cell: {
                      padding: '0 !important',
                      border: 'none !important'
                    },
                    weekdayCell: {
                      border: 'none !important'
                    },
                    month: {
                      backgroundColor: 'inherit !important'
                    }
                  }} value={[new Date(value), new Date(row.leave_requests__ended_at)]} initialMonth={new Date(value)} onChange={() => {}} />
                </Box>
              </Popover.Dropdown>
            </Popover>
          </div>
        },
        {
          key: 'leave_requests__status',
          label: 'Status',
          minWidth: 120,
          render: (value: any) => <Text>
            {(() => {
              switch (value) {
                case 'pending':
                  return <Badge color="yellow">Pending</Badge>
                case 'approved':
                  return <Badge color="green">Approved</Badge>
                case 'rejected':
                  return <Badge color="red">Rejected</Badge>
                default:
                  return <Badge color="gray">Unknown</Badge>
              }
            })()}
          </Text>
        },
        {
          key: 'leave_requests',
          label: 'Total Days',
          minWidth: 100,
          render: (value: any) => {
            const data = value.reduce((res: any, leave: any) => {
              return { ...res, [leave.year.toString()]: [...res[leave.year.toString()] || [], leave] }
            }, {})
            return Object.keys(data).map((year: string) => <Text key={year}>
              <Text component="span">{data[year].reduce((res: number, item: any) => res + item.duration_in_days, 0)}</Text>
              <Text component="span" italic color="dimmed"> ({year})</Text>
            </Text>)
          }
        }
      ]}
      empty={<Empty
        label="Data not found"
        description="No data was found on our database based on the filters."
        icon={<IconDatabase />} />}
      search={(val, q) => q.textSearch('name', val)}
      initialLoading
      pageSize={20}
      refetch={[refetch, setRefetch]}
    />

    <Form open={openForm} setOpen={setOpenForm} onFinish={() => setRefetch(true)} />
  </Container> : <PaymentNeed />
}