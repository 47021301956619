import { Button, Col, Container, Grid, LoadingOverlay, Text, Title, UnstyledButton, useMantineTheme } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { IconFingerprint, IconX } from '@tabler/icons'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useWebAuthn from '../hooks/useWebAuthn'
import { supabase } from '../services/supabase'
import { UserProfile } from '../types'

export default function () {
  const [user] = useLocalStorage<UserProfile | null>({ key: 'user', defaultValue: null })
  const [profile] = useLocalStorage<Record<string, any> | null>({ key: 'selected-profile', defaultValue: null })
  const { authPassLess, loading } = useWebAuthn()
  const navigate = useNavigate()
  const theme = useMantineTheme()

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])

  const googleLoginNormal = () => {
    document.querySelector('.login-google')?.setAttribute('src', `/google-login/btn_google_signin_${theme.colorScheme}_normal_web@2x.png`)
  }

  const googleLoginPressed = () => {
    document.querySelector('.login-google')?.setAttribute('src', `/google-login/btn_google_signin_${theme.colorScheme}_pressed_web@2x.png`)
  }

  const googleLoginFocus = () => {
    document.querySelector('.login-google')?.setAttribute('src', `/google-login/btn_google_signin_${theme.colorScheme}_focus_web@2x.png`)
  }

  return <Container sx={_theme => ({
    minHeight: 'calc(100vh - 77px)', '@media (max-width: 767px)': { minHeight: 'calc(100vh - 77px)' }
  })} style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative' }}>
    <LoadingOverlay visible={loading} />
    <Title order={2} mb="xl">
      {profile?.app?.name && user ? `Welcome back to ${profile.app.name}!` : 'Welcome to hours.online!'}
    </Title>
    <Grid>
      <Col xs={12} sm={8} offsetSm={2}>
        <Text color="dimmed" mb="md">
          Sign in with your Google account or use your Passkey. You can enable 2FA or Passkey in your profile after signing in.
        </Text>
      </Col>
    </Grid>
    <UnstyledButton mt="md" style={{ textAlign: 'center' }} onFocus={googleLoginFocus} onBlur={googleLoginNormal} onClick={() => {
      googleLoginPressed()
      supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${process.env.REACT_APP_REDIRECT_URL}?first-login=1`,
          queryParams: {
            prompt: 'consent'
          }
        }
      })
    }}>
      <img className="login-google" style={{ width: '50%', height: 'auto' }} src={`/google-login/btn_google_signin_${theme.colorScheme}_normal_web@2x.png`} />
    </UnstyledButton>
    <Button mt="xs" color="blue" leftIcon={<IconFingerprint />} variant="light" onClick={async () => {
      try {
        const link = await authPassLess()
        return window.location.replace(link)
      } catch (error: any) {
        return showNotification({
          id: 'verification-failed',
          icon: <IconX />,
          color: 'red',
          title: 'Verification failed',
          message: error.message,
        })
      }
    }}>
      Sign in with a Passkey
    </Button>
  </Container>
}