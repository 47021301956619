import { Anchor, Box, Button, Col, Container, Grid, Group, Input, LoadingOverlay, Paper, Select, Text, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useLocalStorage } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { IconArrowRight, IconBriefcase, IconCheck, IconNotification, IconUsers } from '@tabler/icons'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import usePayment from '../../hooks/usePayment'
import { request } from '../../services/request'
import { supabase } from '../../services/supabase'
import timezone from '../../timezone.json'
import { UserProfile } from '../../types'
import Slack from '../Profile/Teams/Integration/Slack'
import Members from '../Profile/Teams/Members'
import Notifications from '../Profile/Teams/Notifications'

export default function () {
  const [query, setQuery] = useSearchParams()
  const [user, setUser] = useLocalStorage<UserProfile | null>({ key: 'user', defaultValue: null })
  const [_selectedProfile, setSelectedProfile] = useLocalStorage<Record<string, any> | null>({
    key: 'selected-profile', defaultValue: null })
  const [billingData] = useLocalStorage<Record<string, any> | null>({
    key: 'billing-detail', defaultValue: null })
  const [step, setStep] = useState<number>(0)
  const [app, setApp] = useState<any>()
  const [slack, setSlack] = useState<any>()
  const [openMembers, setOpenMembers] = useState<any>()
  const [openNotifications, setOpenNotifications] = useState<any>()
  const [mainLoading, setMainLoading] = useState<boolean>(true)
  const { checkout } = usePayment()

  const form = useForm({
    initialValues: {
      name: '',
      base_tz: dayjs.tz.guess(),
      profiles_read: ['member', 'admin', 'superadmin'],
      presences_read: ['member', 'admin', 'superadmin'],
      leave_request_read: ['member', 'admin', 'superadmin'],
      leave_request_write: ['member', 'admin', 'superadmin'],
      leave_request_approval: ['admin', 'superadmin'],
      salary_slips_read: ['superadmin'],
      salary_slips_write: ['superadmin']
    }
  })

  useEffect(() => {
    if (user) {
      setMainLoading(true)
      const st = query.get('step')
      const app = query.get('app_id')
      if (!st) {
        setStep(0)
        setMainLoading(false)
      } else {
        if (app) {
          supabase.from('apps')
            .select('id, name, owner_id')
            .eq('id', app)
            .eq('owner_id', user?.id)
            .then(({ data }) => {
              if (data?.length) {
                setApp(data[0])
                if (st === '1') {
                  setStep(1)
                  setMainLoading(false)
                } else if (st === '2') {
                  supabase.from('billings')
                    .select('app_id')
                    .eq('app_id', app)
                    .then(({ data }) => {
                      if (data?.length) {
                        setStep(2)
                        setMainLoading(false)
                      } else {
                        setStep(1)
                        setMainLoading(false)
                      }
                    })
                } else if (st === '3') {
                  request.get('/integrations/list', {
                    params: { app_id: app }
                  }).then(({ data }) => {
                    if (data.find((d: any) => d.integrations?.name?.toLowerCase() === 'slack')) {
                      setSlack(data.find((d: any) => d.integrations?.name?.toLowerCase() === 'slack'))
                      setStep(3)
                      setMainLoading(false)
                    } else {
                      setStep(2)
                      setMainLoading(false)
                    }
                  }).catch(() => {
                    setStep(2)
                    setMainLoading(false)
                  })
                }
              } else {
                setStep(0)
                setMainLoading(false)
              }
            })
        } else {
          setStep(0)
          setMainLoading(false)
        }
      }
    }
  }, [query, user])

  useEffect(() => {
    if (step || app?.id) {
      if (step) {
        query.set('step', step.toString())
      }
      if (app?.id) {
        query.set('app_id', app.id)
      }
      setQuery(query)
    }
  }, [step, app])

  useEffect(() => {
    if (billingData) {
      setStep(2)
    }
  }, [billingData])

  const createTeam = async (values: any) => {
    if (!values.name) {
      return form.setFieldError('name', 'Team name is required')
    }

    const { data, error } = await supabase
      .from('apps').insert({
        ...values,
        id: undefined,
        owner_id: user?.id
      }).select()
    if (error) {
      return form.setFieldError('name', error.message)
    }
    const { data: profiles } = await supabase.from('profiles').insert({
      app_id: data[0].id,
      user_id: user?.id,
      role: 'superadmin',
      email: user?.email as string,
      name: user?.user_metadata['full_name'] || user?.user_metadata['name'] || user?.email,
    }).select()
    setUser({
      ...user,
      profiles: [
        ...user?.profiles || [],
        ...profiles || []
      ],
    } as any)
    setSelectedProfile({
      ...profiles?.[0],
      app: {
        id: data[0].id,
        name: data[0].name
      }
    })
    showNotification({
      title: 'Success',
      message: 'Team created successfully',
      color: 'green',
      icon: <IconCheck />
    })
    setStep(1)
    setApp(data[0])
  }

  return <Container sx={() => ({
    minHeight: 'calc(100vh - 16px)', '@media (max-width: 767px)': { minHeight: 'calc(100vh - 120px)' }
  })} style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative' }}>
    <LoadingOverlay visible={mainLoading} />

    <Group spacing={0}>
      <Text style={{ fontSize: '36px' }} color={step === 0 ? 'blue' : 'dimmed'}>&bull;</Text>
      <Text style={{ fontSize: '36px' }} color={step === 1 ? 'blue' : 'dimmed'}>&bull;</Text>
      <Text style={{ fontSize: '36px' }} color={step === 2 ? 'blue' : 'dimmed'}>&bull;</Text>
      <Text style={{ fontSize: '36px' }} color={step === 3 ? 'blue' : 'dimmed'}>&bull;</Text>
    </Group>

    {step === 0 && <Box sx={() => ({
      width: '60%', '@media (max-width: 767px)': { width: '90%' }
    })}>
      <Title order={3} mb="md">What's your team name? 🤔</Title>
      <form onSubmit={form.onSubmit(values => createTeam(values))}>
        <Input.Wrapper label="Team name" required withAsterisk error={form.errors.name}>
          <Input placeholder="Your awesome team name..." {...form.getInputProps('name')} />
        </Input.Wrapper>
        <Input.Wrapper
          mt="xs"
          required
          label="Base Timezone"
          withAsterisk>
          <Select
            searchable
            placeholder="Select timezone..."
            data={timezone.reduce((res: { label: string, key: string, value: string }[], item) => [
              ...res, ...item.utc.filter(i => !res.find(j => j.value === i)).map((utc: string) => ({
                label: `${utc} (UTC${item.offset >= 0 ? '+' : ''}${item.offset})`,
                value: utc,
                key: utc
              }))
            ], [])}
            {...form.getInputProps('base_tz')}
          />
        </Input.Wrapper>
        <Group position="right" mt="xl">
          <Button type="submit" color="blue" rightIcon={<IconArrowRight />}>
            Create
          </Button>
        </Group>
      </form>
    </Box>}

    {step === 1 && <Box style={{ textAlign: 'center' }} sx={() => ({
      width: '60%', '@media (max-width: 767px)': { width: '90%' }
    })}>
      <Title order={3} mb="md">How many people do you have? 🤓</Title>
      <Text component="p" color="dimmed">
        You can buy seat(s) for your team. Each seat costs $3.9 per month with a 30-day free trial.
      </Text>
      <Button color="blue" rightIcon={<IconArrowRight />} onClick={() => checkout(app?.id)}>
        Buy seat(s)
      </Button>
    </Box>}

    {step === 2 && <Box style={{ textAlign: 'center' }} sx={() => ({
      width: '60%', '@media (max-width: 767px)': { width: '90%' }
    })}>
      <Title order={3} mb="md">Yay! Let&apos;s try to connect with your workspace 🥳</Title>
      <Text component="p" color="dimmed">
        You can connect with your Slack workspace to invite users and more.
      </Text>
      <Anchor component="a" href={`https://slack.com/oauth/v2/authorize?client_id=274004635299.4322262933249&scope=chat:write,chat:write.public,emoji:read,users.profile:read,users:read,users:read.email,channels:read,groups:read,mpim:read,im:read&user_scope=&state=${app?.id}`}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></Anchor>
      {/* <Anchor component="a" href={
        `https://slack.com/oauth/v2/authorize?scope=chat%3Awrite%2Cchat%3Awrite.customize%2Cchat%3Awrite.public%2Cemoji%3Aread%2Cusers.profile%3Aread%2Cusers%3Aread%2Cusers%3Aread.email&user_scope=&redirect_uri=https%3A%2F%2Fhours.online%2Fapi%2Fv1%2Fintegrations%2Fslack%2Fconnect&client_id=274004635299.4322262933249&state=${app?.id || query.get('app_id')}_${encodeURIComponent(`${location.origin}/setup?step=3&app_id=${query.get('app_id')}`)}`} style={{ alignItems: 'center', color: '#fff', backgroundColor: '#4A154B', border: 0, borderRadius: '4px', display: 'inline-flex', fontFamily: 'Lato, sans-serif', fontSize: '16px', fontWeight: 600, height: '42px', justifyContent: 'center', textDecoration: 'none', width: '178px' }}><svg xmlns="http://www.w3.org/2000/svg" style={{ height: '14px', width: '14px', marginRight: '12px' }} viewBox="0 0 122.8 122.8"><path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path><path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path><path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path><path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path></svg>
        Add to Slack
      </Anchor> */}
    </Box>}

    {step === 3 && <Box sx={() => ({
      width: '100%', '@media (max-width: 767px)': { width: '90%' }
    })}>
      <Title order={3} mb="md" style={{ textAlign: 'center' }}>
        Almost done! 🎉
      </Title>
      <Grid gutter="xl">
        <Col xs={12} sm={8} offsetSm={2}>
          <Paper p="xl" mt="md" withBorder>
            <Text component="p" color="dimmed">
              Please select the channel where you want to receive notifications.
            </Text>
            <Slack data={slack} includeImportButton={false} />
          </Paper>
          <Text component="p" pt="lg" color="dimmed">
            And, you can invite your team members from Slack and setup notifications.
          </Text>
          <Group mt="xl">
            <Button variant="light" onClick={() => setOpenNotifications(app)} leftIcon={<IconNotification size={20} />}>
              Setup Notifications
            </Button>
            <Button variant="light" color="teal" onClick={() => setOpenMembers(app)} leftIcon={<IconUsers size={20} />}>
              Invite Users
            </Button>
          </Group>
          <Text component="p" color="dimmed" mt="xl">
            Or, you can setup it later from the profile page.
          </Text>
          <Box mt="md">
            <Button variant="subtle" component={Link} to="/profile" leftIcon={<IconBriefcase size={20} />} rightIcon={<IconArrowRight size={20} />}>
              Go to Profile
            </Button>
          </Box>
        </Col>
      </Grid>
    </Box>}

    <Members app={openMembers} setApp={setOpenMembers} />
    {/* <Drawer padding="xl" size={800} position="right" opened={!!openIntegrations} onClose={() => setOpenIntegrations(undefined)} title="Setup Notifications">
      <div style={{ position: 'relative', minHeight: '80px' }}>
        <LoadingOverlay visible={loading} />
        <Slack data={slack} setLoading={setLoading} />
      </div>
    </Drawer> */}
    <Notifications app={openNotifications} onClose={() => setOpenNotifications(undefined)} />
  </Container>
}