import { Anchor, Button, Divider, Drawer, Group, Input, Loader, LoadingOverlay, NumberInput, Paper, Select, Text, Textarea, UnstyledButton } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { IconBell, IconCheck, IconInfoCircle, IconX } from '@tabler/icons'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { supabase } from '../../../../services/supabase'

interface NotificationRule {
  id?: string,
  app_id: string,
  group_id?: string,
  daily_report_to_channel?: string,
  daily_report_to_user?: string,
  daily_report_time?: Date,
  min_hours_day?: number,
  weekly_report_to_channel?: string,
  weekly_report_to_user?: string,
  weekly_report_day?: number,
  weekly_report_time?: Date,
  min_hours_week?: number,
  leave_request_submitted?: string,
  leave_request_approved?: string,
  leave_request_rejected?: string,
  overtime_notification?: string,
  overtime_hours?: number,
  groups?: {
    name: string
  }
}

export default function ({ data, onClose, onSave }: {
  data: NotificationRule,
  onClose: () => void,
  onSave?: () => void
}) {
  const [loading, setLoading] = useState<boolean>(false)
  const [groups, setGroups] = useState<any[]>()
  const [openHelpMessageDaily, setOpenHelpMessageDaily] = useState<boolean>(false)
  const [openHelpMessageWeekly, setOpenHelpMessageWeekly] = useState<boolean>(false)
  const [openHelpMessageOvertime, setOpenHelpMessageOvertime] = useState<boolean>(false)
  const [openHelpMessageLeaveReq, setOpenHelpMessageLeaveReq] = useState<boolean>(false)
  // const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false)
  const [dailyTime, setDailyTime] = useState<string | null>()
  const [weeklyTime, setWeeklyTime] = useState<string | null>()

  const form = useForm<NotificationRule>()

  useEffect(() => {
    if (data?.app_id) {
      supabase
        .from('groups')
        .select('id, name')
        .then(({ data }) => {
          setGroups((data || [])?.map((ch: any) => ({ value: ch.id, label: ch.name })))
        })
    }
  }, [data])

  useEffect(() => {
    if (data) {
      setDailyTime((() => {
        const time = data?.daily_report_time
        if (!time) return '09:00'
        return dayjs(time).format('HH:mm')
      })())
      setWeeklyTime((() => {
        const time = data?.weekly_report_time
        if (!time) return '09:00'
        return dayjs(time).format('HH:mm')
      })())
      form.setValues({
        min_hours_day: 8,
        min_hours_week: 40,
        daily_report_to_user: `Good day, {{user}}! :wave:

Here's the recap of your presence yesterday *{{yesterday}}*:

{{user_list}}

You have < {{min_work_hours_per_day}} hrs active duration in Slack yesterday :cry:`,
        daily_report_to_channel: `Good day, guys! :wave:

Here's the recap of the team members who worked less than the minimum number of hours yesterday *{{yesterday}}*:

{{user_list}}

Have a nice day! :smile:`,
        weekly_report_day: 1,
        weekly_report_to_user: `Good day, {{user}}! :wave:

Here's the recap of your presence last week *{{week_ago}}*:

{{user_list}}

You have < {{min_work_hours_per_week}} hrs active duration in Slack last week :cry:`,
        weekly_report_to_channel: `Good day, guys! :wave:

Here's the recap of the team members who worked less than the minimum number of hours last week *{{week_ago}}*:

{{user_list}}

Have a nice day! :smile:`,
        leave_request_submitted: `Hello, guys! :wave:

{{user}} has submitted a leave request for {{date_range}}, with a reason: {{reason}}. Please review and approve/reject it: {{link}}`,
        leave_request_approved: `Hello, {{user}}! :wave:

Your leave request for {{date_range}} has been *approved*, with a comment: {{comment}} :tada:

View more at: {{link}}`,
        leave_request_rejected: `Hello, {{user}}! :wave:

Your leave request for {{date_range}} has been *rejected*, with a comment: {{comment}} :cry:

View more at: {{link}}`,
        overtime_hours: 9,
        overtime_notification: `Hello {{user}}! :wave:

I noticed that you have been at our office (“Slack”) for more than {{max_work_hours_per_day}} hours today.

While we appreciate you for working hard to achieve our shared-goal, I would like to remind you that your body need some rest :sleeping:

So please take your rest if you can :nyancat:`,
        ...data
      })
    } else {
      form.reset()
    }
  }, [data])

  const submit = async (values: NotificationRule) => {
    if (!values.app_id) {
      return form.setFieldError('app_id', 'App is required')
    }
    setLoading(true)
    await new Promise(res => setTimeout(res, 1000))
    try {
      const { data: currents } = form.values.group_id
        ? await supabase
          .from('notifications')
          .select('*')
          .eq('group_id', form.values.group_id)
          .eq('app_id', data.app_id)
        : await supabase
          .from('notifications')
          .select('*')
          .is('group_id', null)
          .eq('app_id', data.app_id)
      if (currents?.length && currents[0].id !== values.id) {
        showNotification({
          icon: <IconX />,
          color: 'red',
          title: 'Error',
          message: 'Notification rule already exists for this group'
        })
        return form.setFieldError('group_id', 'Notification rule already exists for this group')
      }
      const buildDailyReportTime = () => {
        if (!dailyTime) return undefined
        const [hour, minute] = dailyTime.split(':')
        return dayjs().set('hours', Number(hour)).set('minutes', Number(minute)).toDate().toISOString()
      }
      const buildWeeklyReportTime = () => {
        if (!weeklyTime) return undefined
        const [hour, minute] = weeklyTime.split(':')
        return dayjs().set('hours', Number(hour)).set('minutes', Number(minute)).toDate().toISOString()
      }
      data?.id ? await supabase
        .from('notifications')
        .update({
          ...values,
          groups: undefined,
          daily_report_time: buildDailyReportTime(),
          weekly_report_time: buildWeeklyReportTime(),
        })
        .eq('id', data.id) : await supabase
        .from('notifications')
        .insert({
          ...values,
          groups: undefined,
          daily_report_time: buildDailyReportTime(),
          weekly_report_time: buildWeeklyReportTime(),
        })
      showNotification({
        icon: <IconCheck />,
        color: 'green',
        title: 'Success',
        message: 'Notification rule has been saved',
      })
      // form.reset()
      onSave?.()
    } catch (error: any) {
      console.error(error)
      showNotification({
        icon: <IconX />,
        color: 'red',
        title: 'Something went wrong',
        message: error.response?.data.error || error.message || 'Please reload and try again',
      })
    } finally {
      setLoading(false)
    }
  }

  return <Drawer padding="xl" size={800} position="right" opened={!!data} onClose={onClose} title={data?.id ? `Notifications: ${data?.groups?.name || 'Default'}` : 'Create Notification Rule'}>
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} />
      <form onSubmit={form.onSubmit(values => submit(values))}>
        <Input.Wrapper
          mt="sm"
          label="Group">
          <Select
            searchable
            clearable
            placeholder="Leave blank to make it default for all..."
            data={groups || []}
            defaultValue={data?.group_id}
            rightSection={groups === undefined && <Loader size={20} />}
            {...form.getInputProps('group_id')} />
        </Input.Wrapper>
        <Divider mt="xl" label={<Text size="xs">
          <Group spacing={4}>
            <Text>Daily Report</Text>
            <UnstyledButton onClick={() => setOpenHelpMessageDaily(true)}><IconInfoCircle color="gray" size={14} /></UnstyledButton>
          </Group>
        </Text>} labelPosition="left" />

        <Paper mt="xs" pb="lg">
          <Group align="start" spacing="sm" mt="sm">
            {!form.values.group_id && <Input.Wrapper
              label="Time"
              defaultValue="09:00"
              error={form.errors['daily_report_time']}>
              <Select
                searchable
                data={Array.from(Array(288).keys()).map(i => {
                  const hour = Math.floor(i * 5 / 60).toString().padStart(2, '0')
                  const minute = (i * 5 % 60).toString().padStart(2, '0')
                  return `${hour}:${minute}`
                })}
                value={dailyTime}
                onChange={setDailyTime}
              />
            </Input.Wrapper>}
            <Input.Wrapper
              label="Min Work Hour Per Day"
              error={form.errors['min_hours_day']}>
              <NumberInput
                {...form.getInputProps('min_hours_day')} />
            </Input.Wrapper>
          </Group>
          <Group align="start" spacing="sm" mt="sm">
            {!form.values.group_id && <Input.Wrapper
              style={{ flexGrow: 1 }}
              label="Message to Channel"
              error={form.errors['daily_report_to_channel']}>
              <Textarea
                autosize
                {...form.getInputProps('daily_report_to_channel')} />
            </Input.Wrapper>}
            <Input.Wrapper
              style={{ flexGrow: 1 }}
              label="Message to User"
              error={form.errors['daily_report_to_user']}>
              <Textarea
                autosize
                {...form.getInputProps('daily_report_to_user')} />
            </Input.Wrapper>
          </Group>
        </Paper>

        <Divider mt="xl" label={<Text size="xs">
          <Group spacing={4}>
            <Text>Weekly Report</Text>
            <UnstyledButton onClick={() => setOpenHelpMessageWeekly(true)}><IconInfoCircle color="gray" size={14} /></UnstyledButton>
          </Group>
        </Text>} labelPosition="left" />
        <Paper mt="xs" pb="lg">
          <Group align="start" spacing="sm" mt="sm">
            {!form.values.group_id && <Input.Wrapper
              label="Day"
              error={form.errors['weekly_report_day']}>
              <Select
                data={[
                  { label: 'Monday', value: 1 },
                  { label: 'Tuesday', value: 2 },
                  { label: 'Wednesday', value: 3 },
                  { label: 'Thursday', value: 4 },
                  { label: 'Friday', value: 5 },
                  { label: 'Saturday', value: 6 },
                  { label: 'Sunday', value: 0 }
                ]}
                {...form.getInputProps('weekly_report_day')} />
            </Input.Wrapper>}
            {!form.values.group_id && <Input.Wrapper
              label="Time"
              error={form.errors['weekly_report_time']}>
              <Select
                searchable
                data={Array.from(Array(288).keys()).map(i => {
                  const hour = Math.floor(i * 5 / 60).toString().padStart(2, '0')
                  const minute = (i * 5 % 60).toString().padStart(2, '0')
                  return `${hour}:${minute}`
                })}
                value={weeklyTime}
                onChange={setWeeklyTime}
              />
            </Input.Wrapper>}
            <Input.Wrapper
              label="Min Work Hour Per Week"
              error={form.errors['min_hours_week']}>
              <NumberInput
                {...form.getInputProps('min_hours_week')} />
            </Input.Wrapper>
          </Group>
          <Group align="start" spacing="sm" mt="sm">
            {!form.values.group_id && <Input.Wrapper
              style={{ flexGrow: 1 }}
              label="Message to Channel"
              error={form.errors['weekly_report_to_channel']}>
              <Textarea
                autosize
                {...form.getInputProps('weekly_report_to_channel')} />
            </Input.Wrapper>}
            <Input.Wrapper
              style={{ flexGrow: 1 }}
              label="Message to User"
              error={form.errors['weekly_report_to_user']}>
              <Textarea
                autosize
                {...form.getInputProps('weekly_report_to_user')} />
            </Input.Wrapper>
          </Group>
        </Paper>

        <Divider mt="xl" label={<Text size="xs">
          <Group spacing={4}>
            <Text>Overtime</Text>
            <UnstyledButton onClick={() => setOpenHelpMessageOvertime(true)}><IconInfoCircle color="gray" size={14} /></UnstyledButton>
          </Group>
        </Text>} labelPosition="left" />
        <Paper mt="xs" pb="lg">
          <Group mt="sm">
            <Input.Wrapper
              label="Max Work Hour"
              error={form.errors['overtime_hours']}>
              <NumberInput
                {...form.getInputProps('overtime_hours')} />
            </Input.Wrapper>
          </Group>
          <Input.Wrapper
            mt="sm"
            label="Message"
            error={form.errors['overtime_notification']}>
            <Textarea
              autosize
              {...form.getInputProps('overtime_notification')} />
          </Input.Wrapper>
        </Paper>

        <Divider mt="xl" label={<Text size="xs">
          <Group spacing={4}>
            <Text>Leave Request</Text>
            <UnstyledButton onClick={() => setOpenHelpMessageLeaveReq(true)}><IconInfoCircle color="gray" size={14} /></UnstyledButton>
          </Group>
        </Text>} labelPosition="left" />
        <Paper mt="xs" pb="lg">
          <Input.Wrapper
            mt="sm"
            label="Submitted request"
            error={form.errors['leave_request_submitted']}>
            <Textarea
              autosize
              placeholder={form.values.leave_request_submitted}
              {...form.getInputProps('leave_request_submitted')} />
          </Input.Wrapper>
          <Group align="start" spacing="sm" mt="sm">
            <Input.Wrapper
              style={{ flexGrow: 1 }}
              label="Approved message"
              error={form.errors['leave_request_approved']}>
              <Textarea
                autosize
                {...form.getInputProps('leave_request_approved')} />
            </Input.Wrapper>
            <Input.Wrapper
              style={{ flexGrow: 1 }}
              label="Rejected message"
              error={form.errors['leave_request_rejected']}>
              <Textarea
                autosize
                {...form.getInputProps('leave_request_rejected')} />
            </Input.Wrapper>
          </Group>
        </Paper>
        <Group mt="xl" position="right">
          <Button leftIcon={<IconX size={20} />} variant="subtle" color="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" leftIcon={<IconBell size={20} />}>
            {data?.id ? 'Update' : 'Create'}
          </Button>
        </Group>
      </form>
      <Drawer size="xl" padding="xl" position="right" opened={openHelpMessageDaily} onClose={() => setOpenHelpMessageDaily(false)} title="What message we can write?">
        <Text component="p">
          The daily reports will be sent to the channel you selected on the setup page and to the user who had work hours less than the minimum work hours per day. The reports will be sent from Tuesday to Saturday which will give you reports of the previous day.
        </Text>
        <Text>
          Available variables:
        </Text>
        <Text>
          <ul>
            <li><code>{'{{user}}'}</code><Text italic mb="xs" color="dimmed" size="sm">Mention the user eg. @johndoe</Text></li>
            <li><code>{'{{yesterday}}'}</code><Text italic mb="xs" color="dimmed" size="sm">The yesterday date eg. 2021-01-30</Text></li>
            <li><code>{'{{min_work_hours_per_day}}'}</code><Text italic mb="xs" color="dimmed" size="sm">A min work hours per day eg. 4</Text></li>
            <li><code>{'{{user_list}}'}</code><Text italic mb="xs" color="dimmed" size="sm">The user list who works less than minimum hours</Text></li>
          </ul>
        </Text>
        <Text>Example</Text>
        <Text>
          <ul>
            <li>
              <Text component="strong">To channel:</Text>
              <Text component="p" p="md" style={{ fontFamily: 'monospace' }} sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
                Good day, guys! :wave:
                <br /><br />
                Here's the recap of the team presence who less than {'{{min_work_hours_per_day}}'} hrs yesterday *{'{{yesterday}}'}*:
                <br /><br />
                {'{{user_list}}'}
                <br /><br />
                Have a nice day! :smile:
              </Text>
              <Text component="p">will be rendered as:</Text>
              <Text component="p" p="md" sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
                Good day, guys! 👋
                <br /><br />
                Here's the recap of the team presence who less than 4 hrs yesterday <Text component="strong">2022-11-08</Text>:
                <br /><br />
                • @John was active for <Anchor href="#">1 hrs 15 min</Anchor><br />
                • @Jane was active for <Anchor href="#">1 hrs 19 min</Anchor>
                <br /><br />
                Have a nice day! 😀
              </Text>
            </li>
            <li>
              <Text component="strong">To user:</Text>
              <Text component="p" p="md" style={{ fontFamily: 'monospace' }} sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
                Good day, {'{{user}}'}! :wave:
                <br /><br />
                Here's the recap of your presence yesterday *{'{{yesterday}}'}*:
                <br /><br />
                {'{{user_list}}'}
                <br /><br />
                You have &lt; {'{{min_work_hours_per_day}}'} hrs active duration in Slack yesterday :cry:
              </Text>
              <Text component="p">will be rendered as:</Text>
              <Text component="p" p="md" sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
                Good day, @John! 👋
                <br /><br />
                Here's the recap of your presence yesterday <Text component="strong">2022-11-08</Text>:
                <br /><br />
                • @John was active for <Anchor href="#">1 hrs 15 min</Anchor>
                <br /><br />
                You have &lt; 4 hrs active duration in Slack yesterday 😢
              </Text>
            </li>
          </ul>
        </Text>
      </Drawer>
      <Drawer size="xl" padding="xl" position="right" opened={openHelpMessageWeekly} onClose={() => setOpenHelpMessageWeekly(false)} title="What message we can write?">
        <Text component="p">
          The weekly reports will be sent to the channel you selected on the setup page and to the user who had work hours less than the minimum work hours per week. The reports will be sent every day you selected on the setup page which will give you reports of the previous week.
        </Text>
        <Text>
          Available variables:
        </Text>
        <Text>
          <ul>
            <li><code>{'{{user}}'}</code><Text italic mb="xs" color="dimmed" size="sm">Mention the user eg. @johndoe</Text></li>
            <li><code>{'{{yesterday}}'}</code><Text italic mb="xs" color="dimmed" size="sm">The yesterday date eg. 2021-01-30</Text></li>
            <li><code>{'{{min_work_hours_per_week}}'}</code><Text italic mb="xs" color="dimmed" size="sm">A min work hours per week eg. 40</Text></li>
            <li><code>{'{{user_list}}'}</code><Text italic mb="xs" color="dimmed" size="sm">The user list who works less than minimum hours</Text></li>
            <li><code>{'{{week_ago}}'}</code><Text italic mb="xs" color="dimmed" size="sm">A week range ago eg. 2021-01-24 - 2021-01-30</Text></li>
          </ul>
        </Text>
        <Text>Example</Text>
        <Text>
          <ul>
            <li>
              <Text component="strong">To channel:</Text>
              <Text component="p" p="md" style={{ fontFamily: 'monospace' }} sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
                Good day, guys! :wave:
                <br /><br />
                Here's the recap of the team presence who less than {'{{min_work_hours_per_week}}'} hrs last week *{'{{week_ago}}'}*:
                <br /><br />
                {'{{user_list}}'}
                <br /><br />
                Have a nice day! :smile:
              </Text>
              <Text component="p">will be rendered as:</Text>
              <Text component="p" p="md" sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
                Good day, guys! 👋
                <br /><br />
                Here's the recap of the team presence who less than 40 hrs last week <Text component="strong">2022-11-02 - 2022-11-08</Text>:
                <br /><br />
                • @John was active for <Anchor href="#">31 hrs 15 min</Anchor><br />
                • @Jane was active for <Anchor href="#">21 hrs 19 min</Anchor>
                <br /><br />
                Have a nice day! 😀
              </Text>
            </li>
            <li>
              <Text component="strong">To user:</Text>
              <Text component="p" p="md" style={{ fontFamily: 'monospace' }} sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
                Good day, {'{{user}}'}! :wave:
                <br /><br />
                Here's the recap of your presence last week *{'{{week_ago}}'}*:
                <br /><br />
                {'{{user_list}}'}
                <br /><br />
                You have &lt; {'{{min_work_hours_per_week}}'} hrs active duration in Slack last week :cry:
              </Text>
              <Text component="p">will be rendered as:</Text>
              <Text component="p" p="md" sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
                Good day, @John! 👋
                <br /><br />
                Here's the recap of your presence last week <Text component="strong">2022-11-02 - 2022-11-08</Text>:
                <br /><br />
                • @John was active for <Anchor href="#">31 hrs 15 min</Anchor>
                <br /><br />
                You have &lt; 40 hrs active duration in Slack last week 😢
              </Text>
            </li>
          </ul>
        </Text>
      </Drawer>
      <Drawer size="xl" padding="xl" position="right" opened={openHelpMessageOvertime} onClose={() => setOpenHelpMessageOvertime(false)} title="What message we can write?">
        <Text component="p">
          The overtime reports will be sent to the user who had work hours more than the maximum work hours per day. The reports will be sent every time the users have more than the maximum work hours per day.
        </Text>
        <Text>
          Available variables:
        </Text>
        <Text>
          <ul>
            <li><code>{'{{user}}'}</code><Text italic mb="xs" color="dimmed" size="sm">Mention the user eg. @johndoe</Text></li>
            <li><code>{'{{today}}'}</code><Text italic mb="xs" color="dimmed" size="sm">The today date eg. 2021-01-31</Text></li>
            <li><code>{'{{max_work_hours_per_day}}'}</code><Text italic mb="xs" color="dimmed" size="sm">A max work hours per day eg. 9</Text></li>
          </ul>
        </Text>
        <Text>Example:</Text>
        <Text component="p" p="md" style={{ fontFamily: 'monospace' }} sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
          Hello {'{{user}}'}! :wave:
          <br /><br />
          I noticed that you have been at our office (“Slack”) for more than {'{{max_work_hours_per_day}}'} hours today.
          <br /><br />
          While we appreciate you for working hard to achieve our shared-goal, I would like to remind you that your body need some rest :sleeping:
          <br /><br />
          So please take your rest if you can :nyancat:
        </Text>
        <Text component="p">will be rendered as:</Text>
        <Text component="p" p="md" sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
          Good day, @John! 👋
          <br /><br />
          I noticed that you have been at our office (“Slack”) for more than 40 hours today.
          <br /><br />
          While we appreciate you for working hard to achieve our shared-goal, I would like to remind you that your body need some rest 😴
          <br /><br />
          So please take your rest if you can :nyancat:
        </Text>
      </Drawer>
      <Drawer size="xl" padding="xl" position="right" opened={openHelpMessageLeaveReq} onClose={() => setOpenHelpMessageLeaveReq(false)} title="What message we can write?">
        <Text component="p">
          The leave request reports will be sent to the channel you selected on the setup page and to the user who requested the leave. The reports will be sent every time the user request leave and the request has been approved or rejected.
        </Text>
        <Text>
          Available variables:
        </Text>
        <Text>
          <ul>
            <li><code>{'{{user}}'}</code><Text italic mb="xs" color="dimmed" size="sm">Mention the user eg. @johndoe</Text></li>
            <li><code>{'{{date_range}}'}</code><Text italic mb="xs" color="dimmed" size="sm">The date range of leave request eg. 2022, Nov 11, 00:00 - Nov 13, 23:59 +07:00</Text></li>
            <li><code>{'{{reason}}'}</code><Text italic mb="xs" color="dimmed" size="sm">The reason of leave eg. sick leave</Text></li>
            <li><code>{'{{comment}}'}</code><Text italic mb="xs" color="dimmed" size="sm">Comment from supervisors eg. get well soon!</Text></li>
            <li><code>{'{{link}}'}</code><Text italic mb="xs" color="dimmed" size="sm">A link to the details page eg. https://hours.online/leave/asd123-...</Text></li>
          </ul>
        </Text>
        <Text>Example:</Text>
        <Text>
          <ul>
            <li>
              <Text component="strong">Submitted request:</Text>
              <Text component="p" p="md" style={{ fontFamily: 'monospace' }} sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
                Hello, guys! :wave:
                <br /><br />
                {'{{user}}'} has submitted a leave request for {'{{date_range}}'}, with a reason: {'{{reason}}'}. Please review and approve/reject it: {'{{link}}'}
              </Text>
              <Text component="p">will be rendered as:</Text>
              <Text component="p" p="md" sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
                Hello, guys! 👋
                <br /><br />
                @John has submitted a leave request for 2022, Nov 11, 00:00 - Nov 13, 23:59 +07:00, with a reason: sick leave. Please review and approve/reject it: https://hours.online/leave/asd123-...
              </Text>
            </li>
            <li>
              <Text component="strong">Approved message:</Text>
              <Text component="p" p="md" style={{ fontFamily: 'monospace' }} sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
                Hello, {'{{user}}'}! :wave:
                <br /><br />
                Your leave request for {'{{date_range}}'} has been *approved*, with a comment: {'{{comment}}'} :tada:
                <br /><br />
                View more at: {'{{link}}'}
              </Text>
              <Text component="p">will be rendered as:</Text>
              <Text component="p" p="md" sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
                Hello, @John! 👋
                <br /><br />
                Your leave request for 2022, Nov 11, 00:00 - Nov 13, 23:59 +07:00 has been *approved*, with a comment: get well soon! 🎉
                <br /><br />
                View more at: https://hours.online/leave/asd123-...
              </Text>
            </li>
            <li>
              <Text component="strong">Rejected message:</Text>
              <Text component="p" p="md" style={{ fontFamily: 'monospace' }} sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
                Hello, {'{{user}}'}! :wave:
                <br /><br />
                Your leave request for {'{{date_range}}'} has been *rejected*, with a comment: {'{{comment}}'} :cry:
                <br /><br />
                View more at: {'{{link}}'}
              </Text>
              <Text component="p">will be rendered as:</Text>
              <Text component="p" p="md" sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] })}>
                Hello, @John! 👋
                <br /><br />
                Your leave request for 2022, Nov 11, 00:00 - Nov 13, 23:59 +07:00 has been *rejected*, with a comment: nope 😢
                <br /><br />
                View more at: https://hours.online/leave/asd123-...
              </Text>
            </li>
          </ul>
        </Text>
      </Drawer>
    </div>
  </Drawer>
}