import { ActionIcon, Burger, Button, Group, Header, MediaQuery, Menu, Text, UnstyledButton, useMantineTheme } from '@mantine/core'
import { useLocalStorage, useWindowScroll } from '@mantine/hooks'
import { IconCurrencyDollar, IconHelp, IconListCheck, IconListDetails, IconLogin, IconMoon, IconSun } from '@tabler/icons'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserProfile } from '../types'

export default function ({ scrollToPricing }: { scrollToPricing: any }) {
  const navigate = useNavigate()
  const [opened, setOpened] = useState<boolean>(false)
  const [user] = useLocalStorage<UserProfile | null>({ key: 'user', defaultValue: null })
  const [scheme, setScheme] = useLocalStorage({ key: 'color-scheme', defaultValue: 'light' })
  const theme = useMantineTheme()
  const [{ y }] = useWindowScroll()

  return <Header height={60} withBorder={!!user && location.pathname !== '/'} pt="sm" pl="xl" pr="xl" style={location.pathname === '/' && y < 30 ? {
    position: 'relative', background: theme.colors.blue[theme.colorScheme === 'light' ? 2 : 6]
  } : undefined}>
    <Group position="apart">
      <UnstyledButton onClick={() => navigate('/')}>
        <Group spacing={0}>
          <img src="/logo192.png" alt="logo" style={{
            height: 35, marginRight: '10px' }} />
          <Group spacing={6}>
            <Text size={22} weight="bolder" style={{ color: theme.colorScheme === 'light' ? 'black' : 'white' }}>
              hours.online
            </Text>
            <Text size={12} style={{ color: theme.colorScheme === 'light' ? 'black' : 'white' }}>Beta</Text>
          </Group>
        </Group>
      </UnstyledButton>
      <MediaQuery smallerThan={330} styles={{ display: 'none' }}>
        <Group>
          {!user && <>
            <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
              <Group>
                <Button variant="subtle" sx={theme => ({ color: theme.colorScheme === 'light' ? '#000' : '#fff' })} leftIcon={<IconCurrencyDollar size={20} />} onClick={() => {
                  navigate('/')
                  setTimeout(scrollToPricing, 800)
                }}>
                  Pricing
                </Button>
                <Button variant="subtle" sx={theme => ({ color: theme.colorScheme === 'light' ? '#000' : '#fff' })} leftIcon={<IconListDetails size={20} />} onClick={() => navigate('/terms')}>
                  Terms
                </Button>
                <Button variant="subtle" sx={theme => ({ color: theme.colorScheme === 'light' ? '#000' : '#fff' })} leftIcon={<IconListCheck size={20} />} onClick={() => navigate('/privacy')}>
                  Privacy
                </Button>
                <Button variant="subtle" sx={theme => ({ color: theme.colorScheme === 'light' ? '#000' : '#fff' })} leftIcon={<IconHelp size={20} />} onClick={() => navigate('/support')}>
                  Support
                </Button>
                <Button variant={theme.colorScheme === 'light' ? 'light' : 'filled'} leftIcon={<IconLogin size={20} />} onClick={() => navigate('/login')}>
                  Login
                </Button>
              </Group>
            </MediaQuery>
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Menu opened={opened} onChange={setOpened} shadow="md"
                width={160} closeOnClickOutside closeOnItemClick>
                <Menu.Target>
                  <Burger size="sm" opened={opened} onClick={() => setOpened(!opened)} />
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item icon={<IconCurrencyDollar size={20} />} onClick={() => location.replace('/#pricing')}>
                    Pricing
                  </Menu.Item>
                  <Menu.Item icon={<IconListDetails size={20} />} onClick={() => navigate('/terms')}>
                    Terms
                  </Menu.Item>
                  <Menu.Item icon={<IconListCheck size={20} />} onClick={() => navigate('/privacy')}>
                    Privacy
                  </Menu.Item>
                  <Menu.Item icon={<IconHelp size={20} />} onClick={() => navigate('/support')}>
                    Support
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item color="blue" icon={<IconLogin size={20} />} onClick={() => navigate('/login')}>
                    Login
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
              {/* <ActionIcon size="lg" variant={theme.colorScheme === 'light' ? 'light' : 'filled'} onClick={() => navigate('/login')} color="blue">
                <IconLogin size={22} />
              </ActionIcon> */}
            </MediaQuery>
          </>}
          <ActionIcon size="lg" variant="subtle" sx={theme => ({ color: theme.colorScheme === 'light' ? '#000' : '#fff' })} onClick={() => setScheme(scheme === 'light' ? 'dark' : 'light')}>
            {scheme === 'light' ? <IconMoon size={20} /> : <IconSun size={20} />}
          </ActionIcon>
        </Group>
      </MediaQuery>
    </Group>
  </Header>
}