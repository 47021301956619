import { Text, ThemeIcon } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { IconListDetails, IconLogin, IconUser, IconUserOff } from '@tabler/icons'
import { MouseEventHandler, ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserProfile } from '../types'

type Items = {
  key: string,
  label?: string | ReactElement,
  icon: ({ active = true }: { active: boolean }) => React.ReactNode,
  active?: boolean,
  orderBottom?: number,
  onClick: MouseEventHandler<HTMLButtonElement> }[]

export default function (): { navs: Items } {
  const navigate = useNavigate()
  const [user] = useLocalStorage<UserProfile | null>({ key: 'user', defaultValue: null })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return {
    navs: [
      // {
      //   key: '/',
      //   label: <Text style={{ minWidth: '70px' }}>Home</Text>,
      //   active: location.pathname === '/',
      //   icon: <ThemeIcon color="green">
      //     <IconHome size={16} /></ThemeIcon>,
      //   onClick: () => navigate('/')
      // },
      ...user ? [
        ...user?.profiles?.length ? [
          {
            key: '/presence',
            label: 'Presence',
            orderBottom: 1,
            active: location.pathname.startsWith('/presence'),
            icon: ({ active = true }: { active: boolean }) => <ThemeIcon variant={active ? 'filled' : 'light'} color="blue">
              <IconListDetails size={16} /></ThemeIcon>,
            onClick: () => navigate('/presence')
          },
          {
            key: '/leave',
            orderBottom: 0,
            label: <Text>Leave</Text>,
            active: location.pathname.startsWith('/leave'),
            icon: ({ active = true }: { active: boolean }) => <ThemeIcon variant={active ? 'filled' : 'light'} color="grape">
              <IconUserOff size={16} /></ThemeIcon>,
            onClick: () => navigate('/leave')
          },
          // {
          //   key: '/salary',
          //   label: <Text>Salary</Text>,
          //   active: location.pathname.startsWith('/salary'),
          //   icon: <ThemeIcon color="green">
          //     <IconCurrencyDollar size={16} /></ThemeIcon>,
          //   onClick: () => navigate('/salary')
          // }
        ] : [],
        {
          key: '/profile',
          orderBottom: 2,
          label: <Text style={{ minWidth: '70px' }}>My Profile</Text>,
          active: location.pathname.startsWith('/profile'),
          icon: ({ active = true }: { active: boolean }) => <ThemeIcon variant={active ? 'filled' : 'light'} color="red">
            <IconUser size={16} /></ThemeIcon>,
          onClick: () => navigate('/profile')
        }
      ] : [
        {
          key: '/login',
          label: <Text style={{ minWidth: '50px' }}>Sign in</Text>,
          active: location.pathname.startsWith('/login'),
          icon: ({ active = true }: { active: boolean }) => <ThemeIcon variant={active ? 'filled' : 'light'}>
            <IconLogin size={16} /></ThemeIcon>,
          onClick: () => navigate('/login')
        }
      ]
    ]
  }
}