import { Button, Drawer, Group, Input, LoadingOverlay, Select, Textarea } from '@mantine/core'
import { DatePicker, TimeInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { useLocalStorage } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { IconCalendar, IconCheck, IconClock, IconSend, IconX } from '@tabler/icons'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { request } from '../../services/request'
import { supabase } from '../../services/supabase'

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  onFinish?: () => void
}

interface LeaveRequest {
  profile_id: string,
  started_at: Date | null,
  started_time_at: Date,
  ended_at: Date | null,
  ended_time_at: Date,
  reason?: string
}

export default function ({ open, setOpen, onFinish }: Props) {
  const [selectedProfile] = useLocalStorage<Record<string, any> | null>({
    key: 'selected-profile', defaultValue: null })
  const [loading, setLoading] = useState<boolean>(false)
  const [profiles, setProfiles] = useState<{ value: string, label: string }[]>([])
  const form = useForm<LeaveRequest>({
    initialValues: {
      profile_id: selectedProfile?.id,
      started_at: null,
      started_time_at: dayjs().startOf('day').toDate(),
      ended_at: null,
      ended_time_at: dayjs().endOf('day').toDate()
    }
  })

  useEffect(() => {
    if (!profiles.length && (selectedProfile?.app?.leave_request_approval || []).includes(selectedProfile?.role)) {
      supabase
        .from('profiles')
        .select('id, name')
        .then(({ data }) => {
          setProfiles((data || []).map(({ id, name }) => ({ label: name, value: id })))
          // setProfiles((data || []).map(({ id }) => id))
        })
    }
  }, [profiles, selectedProfile])

  const submit = async (values: LeaveRequest) => {
    const { profile_id: profileId, started_at: startedAt, started_time_at: startedTime, ended_at: endedAt, ended_time_at: endedTime, reason } = values
    if (startedAt && startedTime && endedAt && endedTime) {
      setLoading(true)
      await new Promise(res => setTimeout(res, 1000))

      const started = dayjs(startedAt)
        .set('hour', startedTime.getHours())
        .set('minute', startedTime.getMinutes())
      const ended = dayjs(endedAt)
        .set('hour', endedTime.getHours())
        .set('minute', endedTime.getMinutes())
      const { error } = await supabase
        .from('leave_requests')
        .insert({
          year: started.year(),
          started_at: started.toDate().toISOString(),
          ended_at: ended.toDate().toISOString(),
          reason: reason || null,
          profile_id: profileId || selectedProfile?.id
        })
      if (error) {
        showNotification({
          title: 'Something went wrong',
          message: error.message,
          color: 'red',
          icon: <IconX />
        })
      } else {
        setOpen(false)
        form.reset()
        showNotification({
          title: 'Success',
          message: 'Your leave request has been submitted',
          color: 'green',
          icon: <IconCheck />
        })
        try {
          await request.post('/integrations/slack/pushMessage', {
            user: `<@${selectedProfile?.integration_profile_data.slack?.id}>`,
            date_range: `${started.format('YYYY, MMM D, HH:mm')} - ${ended.format('MMM D, HH:mm Z')}`,
            reason: reason || '-',
            link: `${process.env.REACT_APP_REDIRECT_URL}/leave/${selectedProfile?.id}`
          }, {
            params: {
              app_id: selectedProfile?.app_id,
              notification_type: 'leave_request_submitted',
              to: 'channel'
            }
          })
        } catch (error) {
          // ignore
        }
        onFinish?.()
      }
      setLoading(false)
    }
  }

  return <Drawer padding="xl" size="xl" position="right" opened={open} onClose={() => setOpen(false)} title="Submit Leave Request">
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} />
      <form onSubmit={form.onSubmit(values => submit(values))}>
        {(selectedProfile?.app?.leave_request_approval || []).includes(selectedProfile?.role) && <Input.Wrapper
          label="Select user"
          required
          withAsterisk>
          <Select
            searchable
            data={profiles}
            defaultValue={selectedProfile?.id}
            {...form.getInputProps('profile_id')} />
        </Input.Wrapper>}
        <Group align="end" mt="md">
          <Input.Wrapper
            label="Started at"
            error={form.errors.started_at}
            withAsterisk
            required>
            <DatePicker
              // style={{ minWidth: 224 }}
              excludeDate={date => dayjs(date).day() === 0 || dayjs(date).day() === 6}
              clearable={false}
              icon={<IconCalendar />}
              inputFormat="MMM DD, YYYY"
              // minDate={new Date()}
              // maxDate={dayjs().endOf('year').toDate()}
              {...form.getInputProps('started_at')} />
          </Input.Wrapper>
          <Input.Wrapper
            error={form.errors.started_time_at}
            withAsterisk
            required>
            <TimeInput
              icon={<IconClock />}
              {...form.getInputProps('started_time_at')} />
          </Input.Wrapper>
        </Group>
        <Group align="end" mt="md">
          <Input.Wrapper
            label="Ended at"
            error={form.errors.ended_at}
            withAsterisk
            required>
            <DatePicker
              // style={{ minWidth: 224 }}
              clearable={false}
              excludeDate={date => dayjs(date).day() === 0 || dayjs(date).day() === 6}
              icon={<IconCalendar />}
              inputFormat="MMM DD, YYYY"
              minDate={form.values.started_at}
              // maxDate={dayjs().endOf('year').toDate()}
              {...form.getInputProps('ended_at')} />
          </Input.Wrapper>
          <Input.Wrapper
            error={form.errors.ended_time_at}
            withAsterisk
            required>
            <TimeInput
              icon={<IconClock />}
              {...form.getInputProps('ended_time_at')} />
          </Input.Wrapper>
        </Group>
        <Input.Wrapper
          mt="md"
          label="Reason"
          error={form.errors.reason}>
          <Textarea
            placeholder="Type your reason here..."
            {...form.getInputProps('reason')} />
        </Input.Wrapper>
        <Group position="right" mt="xl">
          <Button type="submit" leftIcon={<IconSend size={18} />}>
            Submit
          </Button>
        </Group>
      </form>
    </div>
  </Drawer>
}