import { Accordion, Anchor, Image, Text } from '@mantine/core'
import { Link } from 'react-router-dom'
import { ReactComponent as HowHoursWorks } from '../svg/how-hours-works.svg'

export default function ({ open, setOpen, refSlack }: { open?: string, setOpen?: (open: string) => void, refSlack?: any }) {
  return <Accordion defaultValue="0" value={open} onChange={e => setOpen?.(e as string)}>
    <Accordion.Item value="0">
      <Accordion.Control>
        How does it work?
      </Accordion.Control>
      <Accordion.Panel>
        <Text component="p" color="dimmed">
          We are using Slack's API to retrieve your team's presence status. We
          are also using Slack's API to send a reminder to your teams like daily
          and weekly reports to the main channel. For future development, we will
          add more integrations like Microsoft Teams, Google Hangouts, and many more.
        </Text>
        <Text component="p" color="dimmed">
          Here&apos;re how hours.online calculate the user's presence duration compare to
          the actual working time:
        </Text>
        <HowHoursWorks style={{ width: '100%', height: 'auto' }} />
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="1">
      <Accordion.Control>
        What is the continuous presence system?
      </Accordion.Control>
      <Accordion.Panel>
        <Text component="p" color="dimmed">
          The continuous presence system is an attendance method that is always
          checking the presence of your team. This is different from the clock-in
          or clock-out system, where you need to manually present at that time to
          be marked as present. That is not effective because it is easy to cheat
          or abuse. With the continuous presence system, you can see the presence
          of your team in real-time, so you can see if they are working or not.
        </Text>
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="4">
      <Accordion.Control>
        Is it really safe to use this tool?
      </Accordion.Control>
      <Accordion.Panel>
        <Text component="p" color="dimmed">
          Absolutely, yes. We are not reading your messages or any sensitive data
          from your Slack workspace. We are only reading your team's presence, user status,
          and user profile to enhance our capabilities.
        </Text>
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="2">
      <Accordion.Control>
        How can I use this tool?
      </Accordion.Control>
      <Accordion.Panel>
        <Text component="p" color="dimmed">
          Simply, <Anchor component={Link} to="/login">log in</Anchor> to this platform,
          create a team, connect your Slack workspace, and invite your team to join the
          team. You can also invite your team to join the team from Slack. After that,
          you can see the presence of your team in real time.
        </Text>
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="3">
      <Accordion.Control>
        Can I reserve more seats for my team?
      </Accordion.Control>
      <Accordion.Panel>
        <Text component="p" color="dimmed">
          Yes, you can. You can reserve more seats for your team and you can invite
          more people to join your team in the future.
        </Text>
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="5">
      <Accordion.Control>
        I want to book a demo, how can I do that?
      </Accordion.Control>
      <Accordion.Panel>
        <Text component="p" color="dimmed">
          You can book a demo with <Anchor component="a" target="_blank" href="https://calendar.app.google/GBuaZJcU6GAzwRvBA">Gilang</Anchor>.
        </Text>
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="6" ref={refSlack}>
      <Accordion.Control>
        How do I add to my Slack workspace?
      </Accordion.Control>
      <Accordion.Panel>
        <Text component="p" color="dimmed">
          After creating a team, you can go to the team's settings and click the Integration menu.
        </Text>
        <Image src="/CleanShot 2022-12-02 at 10.32.03@2x.png" style={{ width: '100%', maxWidth: '480px' }} />
        <Text component="p" color="dimmed">
          Then, you can click the Connect button to connect your Slack workspace.
        </Text>
        <Image src="/CleanShot 2022-12-02 at 10.33.50@2x.png" style={{ width: '100%', maxWidth: '640px' }} />
        {/* <Text component="p" color="dimmed">
        You can reconnect your Slack workspace anytime by clicking the Reconnect button and updating all the notifications to your workspace. Also, you can import your team members from your Slack workspace by clicking the Import Users button.
        </Text>
        <Image src="/CleanShot 2022-12-02 at 10.32.17@2x.png" style={{ width: '100%', maxWidth: '760px' }} /> */}
        <Text component="p" color="dimmed">
          After that, you can import users and set up all the notifications to your Slack workspace.
        </Text>
        <Image src="/CleanShot 2022-12-02 at 13.18.52@2x.png" style={{ width: '100%', maxWidth: '760px' }} />
      </Accordion.Panel>
    </Accordion.Item>
  </Accordion>
}