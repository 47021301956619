import { ActionIcon, Anchor, Box, Button, Card, Code, Col, Container, CopyButton, Divider, Grid, Group, Image, Stack, Text, Title, Tooltip, useMantineTheme } from '@mantine/core'
import { useLocalStorage, useScrollIntoView } from '@mantine/hooks'
import { IconArrowRight, IconBrandSlack, IconCalendarTime, IconCheck, IconCopy, IconDeviceMobile, IconLock, IconMoon, IconRocket, IconSun } from '@tabler/icons'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'
import { useState } from 'react'
import Particles from 'react-particles'
import { Link, useNavigate } from 'react-router-dom'
import { loadFull } from 'tsparticles'
import Faq from '../components/Faq'
import { ReactComponent as Schedule } from '../svg/plan-schedule.svg'
import { UserProfile } from '../types'

export default function ({ refPricing }: { refPricing: any }) {
  const [user] = useLocalStorage<UserProfile | null>({ key: 'user', defaultValue: null })
  const [_, setScheme] = useLocalStorage({ key: 'color-scheme', defaultValue: 'light' })
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const [openFaq, setOpenFaq] = useState<string>('0')
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>()

  return <Box>
    <motion.div
      style={{ position: 'relative' }}
      whileInView={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ ease: 'easeIn', duration: 0.5 }}>
      <Particles id="tsparticles" init={loadFull} options={{
        'style': {
          'position': 'absolute'
        },
        'particles': {
          'number': {
            'value': 80,
            'density': {
              'enable': true,
              'value_area': 700
            }
          },
          'color': {
            'value': '#ffffff'
          },
          'shape': {
            'type': 'circle',
            'stroke': {
              'width': 0,
              'color': '#000000'
            },
            'polygon': {
              'nb_sides': 5
            },
          },
          'opacity': {
            'value': 0.3,
            'random': false,
            'anim': {
              'enable': false,
              'speed': 0.1,
              'opacity_min': 0.1,
              'sync': false
            }
          },
          'size': {
            'value': 3,
            'random': true,
            'anim': {
              'enable': false,
              'speed': 10,
              'size_min': 0.1,
              'sync': false
            }
          },
          'line_linked': {
            'enable': true,
            'distance': 150,
            'color': '#ffffff',
            'opacity': 0.3,
            'width': 1
          },
          'move': {
            'enable': true,
            'speed': 2,
            'direction': 'none',
            'random': false,
            'straight': false,
            'out_mode': 'out',
            'bounce': false,
            'attract': {
              'enable': false,
              'rotateX': 600,
              'rotateY': 1200
            }
          }
        },
        'interactivity': {
          'detect_on': 'canvas',
          'events': {
            'onhover': {
              'enable': true,
              'mode': 'grab'
            },
            'onclick': {
              'enable': true,
              'mode': 'push'
            },
            'resize': true
          },
          'modes': {
            'grab': {
              'distance': 140,
              'line_linked': {
                'opacity': 1
              }
            },
            'bubble': {
              'distance': 400,
              'size': 40,
              'duration': 2,
              'opacity': 8,
              'speed': 3
            },
            'repulse': {
              'distance': 200,
              'duration': 0.4
            },
            'push': {
              'particles_nb': 4
            },
            'remove': {
              'particles_nb': 2
            }
          }
        },
        'retina_detect': true
      }} />
      <Box style={{
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.blue[theme.colorScheme === 'light' ? 2 : 6],
        color: 'black' }} pt="15vh" pb="20vh">
        <Grid align="center">
          <Col sm={12} md={5.5} offsetMd={0.5} order={2} orderMd={1} style={{ textAlign: 'left', color: theme.colorScheme === 'light' ? 'black' : 'white' }}>
            <Container pt="xl">
              <Text sx={_ => ({ fontSize: '48px', '@media (max-width: 755px)': { fontSize: '36px' } })}>
                Work from anywhere.
              </Text>
              <Text sx={_ => ({ fontSize: '48px', '@media (max-width: 755px)': { fontSize: '36px' } })}>
                Trust your team with no worries.
              </Text>
              <Text component="p" sx={theme => ({ fontSize: '20px', color: theme.colorScheme === 'light' ? '#536471' : '#dee2e6', '@media (max-width: 755px)': { fontSize: '17px' } })}>
                hours.online is a simple, straightforward, and useful tool to help you monitor and manage your team's presence with a continuous presence system.
              </Text>
              <Group mt="xl">
                <motion.div whileHover={{ scale: 1.2 }}>
                  <Button variant={theme.colorScheme === 'light' ? 'light' : 'filled'} onClick={() => {
                    if (!user) {
                      sessionStorage.setItem('next', '/presence')
                      navigate('/login')
                    } else {
                      navigate('/presence')
                    }
                  }} size="md" leftIcon={<IconRocket size={22} />}>Let&apos;s go!</Button>
                </motion.div>
                <Button variant="subtle" size="md" component="a" href="https://calendar.app.google/GBuaZJcU6GAzwRvBA" target="_blank" leftIcon={<IconCalendarTime size={22} />}>
                  Book a demo
                </Button>
              </Group>
            </Container>
          </Col>
          <Col sm={12} md={6} order={1} orderMd={2} style={{ textAlign: 'center' }}>
            <Container>
              <motion.div whileHover={{ scale: 1.2 }}>
                <Schedule style={{ width: '100%', height: 'auto', maxWidth: '520px' }} />
              </motion.div>
            </Container>
          </Col>
        </Grid>
      </Box>
      {/* <ParticlesBg type="cobweb" bg={true} /> */}
    </motion.div>

    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ ease: 'easeIn', duration: 0.5 }}>
      <Box pt="10vh" pb="5vh">
        <Container style={{ textAlign: 'center' }}>
          <Title order={3} mb="lg" mt="xl">✨ Look at this beautiful insight from your team's presence! 🥰</Title>
          <motion.div whileHover={{ scale: 1.3 }}>
            <Image pb="lg" src={`/presence-details-${theme.colorScheme}.png`} alt="presence details" style={{ width: '100%', height: 'auto', textAlign: 'center', margin: '0 auto' }} />
          </motion.div>
          <Text component="p" color="dimmed" mt="xl">
            Are you worried about your team's presence? Did they really work from their home? hours.online is here to help you with that. With a simple and straightforward interface, you can easily monitor your team's presence historically.
          </Text>
          <Group align="center" style={{ width: '100%', justifyContent: 'center' }} spacing="xs">
            <Text component="p" color="dimmed">
              ⚡️ Try to take a look at another theme
            </Text>
            <IconArrowRight color="gray" size={18} />
            <Tooltip label="Click to change the theme!" position="bottom" withArrow>
              <ActionIcon size="md" variant="light" color="blue" onClick={() => setScheme(theme.colorScheme === 'light' ? 'dark' : 'light')}>
                {theme.colorScheme === 'light' ? <IconMoon size={18} /> : <IconSun size={18} />}
              </ActionIcon>
            </Tooltip>
          </Group>
        </Container>
        {/* <ul>
          <li>Say good bye to clock-in/clock-out</li>
        </ul> */}
      </Box>
    </motion.div>

    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ ease: 'easeIn', duration: 0.5 }}>
      <Box pt="10vh" pb="5vh">
        <Container>
          <Title order={2} mb="xl">🏝️ Need a staycation?</Title>
          <Text component="p" color="dimmed">
            hours.online is an all-in-one attendance system that you can request leave from your supervisors on the platform with an intuitive interface. So, you can take a staycation without worrying about your attendance.
          </Text>
          <motion.div whileHover={{ scale: 1.3 }}>
            <Image alt="leave details" pt="xl" mt="xl" src="/leavedetails.png" style={{ width: '100%', height: 'auto', textAlign: 'center', margin: '0 auto' }} />
          </motion.div>
        </Container>
      </Box>
    </motion.div>

    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ ease: 'easeIn', duration: 0.5 }}>
      <Box pt="10vh" pb="5vh">
        <Container>
          <Grid gutter="xl">
            <Col xs={12} sm={4} style={{ textAlign: 'center' }}>
              <IconCalendarTime size={76} />
              <Text component="p" color="dimmed">
                Retrieve your team's presence status every 5 minutes. So you can see the latest presence and timeline of your team's activity.
              </Text>
            </Col>
            <Col xs={12} sm={4} style={{ textAlign: 'center' }}>
              <IconDeviceMobile size={76} />
              <Text component="p" color="dimmed">
                We are really prioritizing the best user experience of this tool, so you can easily access it from any device, anywhere, and anytime.
              </Text>
            </Col>
            <Col xs={12} sm={4} style={{ textAlign: 'center' }}>
              <IconLock size={76} />
              <Text component="p" color="dimmed">
                This is built with the latest security technology. We also don't read your messages or any sensitive data, so you can be sure that your data is safe.
              </Text>
            </Col>
          </Grid>
          <Box style={{ textAlign: 'center' }} mt="xl">
            <Title order={2}>...and many upcoming features ✨</Title>
            <Text component="p" color="dimmed">
              We are still building many features to help you manage your team internally.
            </Text>
          </Box>
        </Container>
      </Box>
    </motion.div>

    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ ease: 'easeIn', duration: 0.5 }}>
      <Box pt="10vh" pb="5vh">
        <Container>
          <Grid align="center">
            <Col xs={12} sm={6} orderSm={1} order={2}>
              <motion.div whileHover={{ scale: 1.3 }}>
                <Image alt="slack notification" src="/slack.png" style={{ width: '100%', height: 'auto', textAlign: 'center', margin: '0 auto', maxWidth: '340px' }} />
              </motion.div>
            </Col>
            <Col xs={12} sm={6} orderSm={2} order={1} mb="xl">
              <Group mb="lg"align="center">
                <IconBrandSlack size={38} />
                <Title order={2} style={{ width: 'calc(100% - 54px)' }}>Prevent your employees from burnout 🥵</Title>
              </Group>
              <Text component="p" color="dimmed" mt="xl">
                hours.online is integrated with Slack, so you can easily monitor your team's presence from the Slack status. You can also set up a reminder for your team to take a break from work.
              </Text>
              <Anchor mt="xl" onClick={() => {
                setOpenFaq('6')
                scrollIntoView({ alignment: 'center' })
              }}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></Anchor>
            </Col>
          </Grid>
        </Container>
      </Box>
    </motion.div>

    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ ease: 'easeIn', duration: 0.5 }}>
      <Box pt="10vh" pb="5vh">
        <Container>
          <Title order={2} mb="md">🤔 Frequently Asked Questions</Title>
          <Faq open={openFaq} setOpen={setOpenFaq} refSlack={targetRef} />
        </Container>
      </Box>
    </motion.div>

    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ ease: 'easeIn', duration: 0.5 }}>
      <Box pt="10vh" pb="5vh" id="pricing" ref={refPricing}>
        <Container>
          <Grid>
            <Col xs={12} sm={6} offsetSm={3}>
              <motion.div whileHover={{ scale: 1.1 }}>
                <Card shadow="sm" pt="lg" pl="lg" pr="lg" pb="xs" radius="md" withBorder>
                  <Card.Section></Card.Section>
                  <Title order={4} mt="xl" mb="md" style={{ textAlign: 'center' }}>Pricing</Title>
                  <Divider />
                  <Group align="center" style={{ justifyContent: 'center' }} mt="lg" mb="xl">
                    <Text component="span" size={22} mt={36}>$</Text>
                    <Text component="span" size={79} className="strikethrough" color="red">&nbsp;7.9&nbsp;</Text>
                    <Stack spacing="xs" mt={-38}>
                      <Text component="span" size={39}>3.9</Text>
                      <Text component="span" size="md">/user/month</Text>
                    </Stack>
                  </Group>
                  <Button size="md" fullWidth onClick={() => {
                    if (!user) {
                      sessionStorage.setItem('next', '/presence')
                      navigate('/login')
                    } else {
                      navigate('/presence')
                    }
                  }} variant={theme.colorScheme === 'light' ? 'light' : 'filled'} rightIcon={<IconRocket />}>Try now!</Button>
                  <Text component="p" color="dimmed" size="sm" style={{ textAlign: 'center' }}>
                    Free for 30 days. Cancel anytime.
                  </Text>
                </Card>
              </motion.div>
              {dayjs().isBefore(dayjs('2022-12-07')) && <>
                <Text color="dimmed" size="sm" pt="xl" style={{ textAlign: 'center' }}>
                  Get 20% off before 7 December 2022 🎉
                </Text>
                <Group align="center" style={{ justifyContent: 'center' }}>
                  <Code>HOURSBETALAUNCH</Code>
                  <CopyButton value="HOURSBETALAUNCH">
                    {({ copied, copy }) =>
                      <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                        {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
                      </ActionIcon>}
                  </CopyButton>
                </Group>
              </>}
            </Col>
          </Grid>
        </Container>
      </Box>
    </motion.div>

    <Box pt="10vh">
      <Divider size={.1} />
      <Container>
        <Group position="apart" spacing="xs" mt="lg" mb="sm">
          <Text color="dimmed" size="xs">hours.online &copy; {new Date().getFullYear()}</Text>
          <Group>
            <Anchor size="xs" component={Link} to="/terms">Terms</Anchor>
            <Text color="dimmed">&bull;</Text>
            <Anchor size="xs" component={Link} to="/privacy">Privacy</Anchor>
            <Text color="dimmed">&bull;</Text>
            <Anchor size="xs" component={Link} to="/support">Support</Anchor>
          </Group>
        </Group>
      </Container>
    </Box>
    <Anchor style={{ position: 'fixed', bottom: '13px', right: '20px' }} href="https://www.producthunt.com/posts/hours-online?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-hours&#0045;online" target="_blank"><img src={`https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=369233&theme=${theme.colorScheme}`} alt="hours&#0046;online - Trust&#0032;your&#0032;team&#0032;with&#0032;no&#0032;worries | Product Hunt" style={{ width: '250px', height: '54px' }} width="250" height="54" /></Anchor>
  </Box>
}