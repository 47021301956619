import { Button, Checkbox, Group, Input, LoadingOverlay, NumberInput, Select, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useLocalStorage } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { IconCheck, IconPencil, IconX } from '@tabler/icons'
import dayjs from 'dayjs'
import { useState } from 'react'
import { request } from '../../services/request'
import { supabase } from '../../services/supabase'

interface LeaveRequest {
  year: number,
  status: 'pending' | 'approved' | 'rejected',
  comment?: string,
  count_as_paid_leave: boolean,
  duration_in_days: number
}

export default function ({ data, onFinish }: { data?: any, onFinish?: () => void }) {
  const [selectedProfile] = useLocalStorage<Record<string, any> | null>({
    key: 'selected-profile', defaultValue: null })
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<LeaveRequest>({
    initialValues: {
      count_as_paid_leave: true,
      ...data,
      duration_in_days: data?.duration_in_days || Math.ceil(dayjs(data?.ended_at).diff(dayjs(data?.started_at)) / 8.64e+7),
      comment: data?.comment || '',
    }
  })

  const submit = async (values: LeaveRequest) => {
    const { year, status, comment, duration_in_days: duration, count_as_paid_leave: countAsPaidLeave } = values
    if (status) {
      setLoading(true)
      await new Promise(res => setTimeout(res, 1000))

      const { error } = await supabase
        .from('leave_requests')
        .update({
          year,
          status,
          comment,
          duration_in_days: status === 'approved' ? duration : 0,
          count_as_paid_leave: status === 'approved' && countAsPaidLeave,
          updated_by: selectedProfile?.id
        })
        .eq('id', data?.id)
      if (error) {
        showNotification({
          title: 'Something went wrong',
          message: error.message,
          color: 'red',
          icon: <IconX />
        })
      } else {
        form.reset()
        showNotification({
          title: 'Success',
          message: 'Your approval has been submitted',
          color: 'green',
          icon: <IconCheck />
        })
        try {
          await request.post('/integrations/slack/pushMessage', {
            user: `<@${data?.profiles.integration_profile_data?.slack?.id}>`,
            date_range: `${dayjs(data?.started_at)?.format('YYYY, MMM D, HH:mm')} - ${dayjs(data?.ended_at)?.format('MMM D, HH:mm Z')}`,
            reason: data?.reason || '-',
            comment: comment || '-',
            link: `${process.env.REACT_APP_REDIRECT_URL}/leave/${data?.profiles?.id}`
          }, {
            params: {
              app_id: selectedProfile?.app_id,
              notification_type: `leave_request_${status}`,
              to: 'user'
            }
          })
        } catch (error) {
          // ignore
          console.log(error)
        }
        onFinish?.()
      }
      setLoading(false)
    }
  }

  return <div style={{ position: 'relative' }}>
    <LoadingOverlay visible={loading} />
    <form onSubmit={form.onSubmit(values => submit(values))}>
      <Input.Wrapper
        required
        label="Year period"
        withAsterisk>
        <NumberInput
          {...form.getInputProps('year')}
        />
      </Input.Wrapper>
      <Input.Wrapper
        required
        mt="md"
        label="Status"
        withAsterisk>
        <Select
          placeholder="Select status..."
          data={[
            { label: 'Pending', value: 'pending' },
            { label: 'Approved', value: 'approved' },
            { label: 'Rejected', value: 'rejected' }
          ]}
          {...form.getInputProps('status')}
        />
      </Input.Wrapper>
      {form.values.status === 'approved' && <>
        <Input.Wrapper
          mt="md"
          label="Duration">
          <NumberInput
            {...form.getInputProps('duration_in_days')}
          />
        </Input.Wrapper>
        <Input.Wrapper mt="sm">
          <Checkbox
            label="Count as paid leave"
            {...form.getInputProps('count_as_paid_leave', { type: 'checkbox' })}
          />
        </Input.Wrapper>
      </>}
      <Input.Wrapper
        mt="md"
        label="Comment">
        <Textarea
          placeholder="Write a comment here... (optional)"
          {...form.getInputProps('comment')}
        />
      </Input.Wrapper>
      <Group position="right" mt="xl">
        <Button type="submit" leftIcon={<IconPencil size={18} />}>
          Update
        </Button>
      </Group>
    </form>
  </div>
}