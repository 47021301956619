import { Accordion, Badge, Button, Drawer, Group, LoadingOverlay, Stack, Text } from '@mantine/core'
import { IconCalendar, IconCreditCard, IconPlus, IconUser, IconWallet } from '@tabler/icons'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import usePayment from '../../../hooks/usePayment'

export default function ({ app, onClose }: { app: any, onClose: () => void }) {
  const [loading, setLoading] = useState<boolean>(false)
  const [seats, setSeats] = useState<[number, number]>([0, 0])
  const [subs, setSubs] = useState<any[]>([])
  const { checkout, portal, check, subscriptions } = usePayment()

  useEffect(() => {
    if (app) {
      (async () => {
        setLoading(true)
        await Promise.allSettled([
          (async () => {
            const { seats, members }: any = await check(true, app.id)
            setSeats([seats, members])
          })(),
          (async () => {
            const data = await subscriptions(app.id)
            setSubs(data)
          })()
        ])
        setLoading(false)
      })()
    }
  }, [app])

  return <Drawer padding="xl" size="xl" position="right" opened={!!app} onClose={onClose} title="Billing">
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} />
      <Group position="apart">
        <Text component="p">
          Available seats: {seats?.[1]} / {seats?.[0]}
        </Text>
        <Button size="sm" onClick={() => checkout(app?.id)} leftIcon={<IconPlus size={18} />}>Buy seats</Button>
      </Group>
      <Accordion mt="md">
        {subs.map(({ subscription: sub, card, customer }) => <Accordion.Item key={sub.id} value={sub.id}>
          <Accordion.Control>
            <Group position="apart">
              <Stack spacing={0}>
                <Text>
                  ***{card.last4}: {sub.subscription_items.reduce((res: number, i: any) => res + i.quantity, 0)} seat(s)
                </Text>
                <Text size="xs" color="dimmed">
                  Next billing: {sub.next_billing_at ? dayjs(sub.next_billing_at * 1000).format('MMM D, YYYY') : '-'}
                </Text>
              </Stack>
              <Badge color={(() => {
                switch (sub.status) {
                  case 'active':
                    return 'green'
                  case 'cancelled':
                    return 'red'
                  case 'future':
                    return 'blue'
                  case 'in_trial':
                    return 'yellow'
                  case 'non_renewing':
                    return 'orange'
                  case 'paused':
                    return 'pink'
                  default:
                    return 'gray'
                }
              })()}>
                {sub.status.replace(/_/g, ' ')}
              </Badge>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Group>
              <IconUser size={22} />
              <Text color="dimmed" style={{ width: 'calc(100% - 38px)' }}>
                {customer.first_name} {customer.last_name} ({customer.email})
              </Text>
            </Group>
            <Group mt="sm">
              <IconCreditCard size={22} />
              <Text color="dimmed" style={{ width: 'calc(100% - 38px)' }}>
                {card.masked_number}
              </Text>
            </Group>
            <Group mt="sm">
              <IconCalendar size={22} />
              <Text color="dimmed" style={{ width: 'calc(100% - 38px)' }}>
                Subscribed at: {dayjs(sub.created_at * 1000).format('YYYY, MMM DD HH:mm')}
              </Text>
            </Group>
          </Accordion.Panel>
        </Accordion.Item>)}
      </Accordion>
      <Button fullWidth mt="xl" variant="light" onClick={portal} leftIcon={<IconWallet size={18} />}>
        Manage billing account
      </Button>
    </div>
  </Drawer>
}