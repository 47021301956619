import { useLocalStorage } from '@mantine/hooks'
import { useEffect } from 'react'
import { request } from '../services/request'
import { supabase } from '../services/supabase'

export default function () {
  const [selectedProfile] = useLocalStorage<Record<string, any> | null>({
    key: 'selected-profile', defaultValue: null })
  const [_, setBillingData] = useLocalStorage<Record<string, any> | null>({
    key: 'billing-detail', defaultValue: null })

  useEffect(() => {
    setBillingData(null)
  }, [])

  const checkout = (appId?: string) => {
    supabase
      .from('profiles')
      .select('app_id')
      .eq('app_id', appId || selectedProfile?.app_id)
      .then(({ data }) => {
        const el = document.getElementById((data?.length || 0) > 1 ? 'cb-checkout' : 'cb-checkout-trial')
        if (el) {
          el.click()
        }
      })
  }

  const portal = () => {
    const el = document.getElementById('cb-portal')
    if (el) {
      el.click()
    }
  }

  const register = () => {
    const el = document.createElement('script')
    el.onload = () => {
      (window as any).Chargebee.registerAgain()
      const instance = (window as any).Chargebee.getInstance()
      instance.setCheckoutCallbacks(() => {
        return {
          success: async (hostedPageId: string) => {
            const profileString = localStorage.getItem('selected-profile')
            const profile = JSON.parse(profileString || '{}')
            const { data: { content } } = await request.get('/billing/checkoutDetails', {
              params: {
                id: hostedPageId,
                app_id: profile?.app_id,
              }
            })

            await supabase
              .from('billings')
              .insert({
                app_id: profile?.app_id,
                email: content?.customer?.email,
                subscription_id: content?.subscription?.id,
              })
            setBillingData(content)
          }
        }
      })
    }
    el.setAttribute('data-cb-site', 'automa')
    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js')
    document.body.appendChild(el)
  }

  const check = async (returnNumbers: boolean = false, appId?: string) => {
    const { data: { seats, members } } = await request.get('/billing/checkPayment', {
      params: {
        app_id: appId || selectedProfile?.app_id,
      }
    })
    return returnNumbers ? { seats, members } : seats >= members
  }

  const subscriptions = async (appId?: string) => {
    const { data } = await request.get('/billing/subscriptions', {
      params: {
        app_id: appId || selectedProfile?.app_id,
      }
    })
    return data
  }

  return { register, checkout, portal, check, subscriptions }
}