import { Box, Button, Col, Container, Grid, Input, Text, Textarea, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconMail } from '@tabler/icons'
import Faq from '../components/Faq'

export default function () {
  const form = useForm({
    initialValues: {
      subject: '',
      body: ''
    }
  })

  const send = (values: any) => {
    if (values.subject && values.body) {
      window.open(`mailto:gilang@bahasa.ai
        ?subject=${encodeURIComponent(values.subject)}
        &body=${encodeURIComponent(values.body)}
      `)
    }
  }

  return <Box>
    <Grid>
      <Col xs={12} sm={8} offsetSm={2} lg={6} offsetLg={3} xl={4} offsetXl={4}>
        <Container mt="xl" mb="xl" sx={() => ({
          minHeight: 'calc(100vh - 220px)', '@media (max-width: 767px)': { minHeight: 'calc(100vh - 200px)' }
        })} style={{
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'center',
          justifyContent: 'center',
          position: 'relative' }}>
          <Title order={2} mb="md">
            Support
          </Title>
          <Text color="dimmed" mb="xl">
            We're here to help. Please fill out the form below and we'll get back to you as soon as possible.
          </Text>
          <Box>
            <form onSubmit={form.onSubmit(values => send(values))}>
              <Input.Wrapper withAsterisk required>
                <Input placeholder="What you want to ask?" {...form.getInputProps('subject')} />
              </Input.Wrapper>
              <Input.Wrapper withAsterisk required mt="sm">
                <Textarea autosize minRows={3} placeholder="Please describe more about the problem here..." {...form.getInputProps('body')} />
              </Input.Wrapper>
              <Input.Wrapper mt="lg" style={{ textAlign: 'right' }}>
                <Button type="submit" leftIcon={<IconMail size={18} />}>Send</Button>
              </Input.Wrapper>
            </form>
          </Box>
        </Container>
      </Col>
      <Container mt="xl">
        <Title order={2} mb="md">Frequently Asked Questions</Title>
        <Faq />
      </Container>
    </Grid>
  </Box>
}