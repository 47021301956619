import { AppShell, Box, ColorScheme, LoadingOverlay, MantineProvider } from '@mantine/core'
import { useLocalStorage, useScrollIntoView, useViewportSize } from '@mantine/hooks'
import { NotificationsProvider } from '@mantine/notifications'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import Footer from './components/Footer'
import Header from './components/Header'
import Navbar from './components/Navbar'
import useAuth from './hooks/useAuth'
import usePayment from './hooks/usePayment'
import Home from './pages/Home'
import Leave from './pages/Leave'
import LeaveDetails from './pages/Leave/Details'
import Login from './pages/Login'
import Onboarding from './pages/Onboarding'
import Presence from './pages/Presence'
import PresenceDetails from './pages/Presence/Details'
import Privacy from './pages/Privacy'
import Profile from './pages/Profile'
import Support from './pages/Support'
import Terms from './pages/Terms'

import './App.css'
import './index.css'

function App() {
  dayjs.extend(duration)
  dayjs.extend(weekOfYear)
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(isBetween)
  dayjs.extend(relativeTime)

  const { user, loading, startAuth } = useAuth()
  const [scheme] = useLocalStorage({ key: 'color-scheme', defaultValue: 'light' })
  const { width } = useViewportSize()
  const { register } = usePayment()
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>()

  useEffect(() => {
    startAuth()
  }, [])

  useEffect(() => {
    if (user) {
      register()
    }
  }, [user])

  const Main = <Box sx={_theme => ({
    minHeight: 'calc(100vh - 77px)', '@media (max-width: 767px)': { minHeight: 'calc(100vh - 130px)' }
  })}>
    <Routes>
      <Route path="/" element={<Home refPricing={targetRef} />} />
      <Route path="/setup" element={<Onboarding />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/support" element={<Support />} />
      <Route path="/login" element={<Login />} />
      <Route path="/presence">
        <Route index element={<Presence />} />
        <Route path=":id" element={<PresenceDetails />} />
      </Route>
      <Route path="/leave">
        <Route index element={<Leave />} />
        <Route path=":id" element={<LeaveDetails />} />
      </Route>
      <Route path="/profile">
        <Route index element={<Profile />} />
      </Route>
    </Routes>
  </Box>

  return <MantineProvider theme={{ colorScheme: scheme as ColorScheme }} withGlobalStyles withNormalizeCSS>
    <NotificationsProvider position="top-right">
      <div style={{ position: 'relative' }}>
        <LoadingOverlay key="0" visible={loading} />
        <AppShell key="1"
          styles={{
            main: {
              minHeight: 'calc(100vh - 77px)',
              overflowX: 'hidden',
              paddingLeft: 'calc(var(--mantine-navbar-width, 0px) + 0px)',
              paddingTop: location.pathname === '/' ? '0' : 'calc(var(--mantine-header-height, 0px) + 0px)',
              // paddingTop: '0',
              paddingRight: 'calc(var(--mantine-aside-width, 0px) + 0px)'
            }
          }}
          navbar={user && width >= 1200 ? <Navbar /> : undefined}
          header={!user || width < 1200 ? <Header scrollToPricing={scrollIntoView} /> : undefined}
          footer={user && width < 1200 ? <Footer /> : undefined}>
          {Main}
        </AppShell>
      </div>
    </NotificationsProvider>
  </MantineProvider>
}

export default App
