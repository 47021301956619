import { Button, Checkbox, Drawer, Group, LoadingOverlay, ScrollArea, Table } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { IconCheck, IconDatabaseImport, IconX } from '@tabler/icons'
import { useEffect, useState } from 'react'
import { request } from '../../../../services/request'

export default function ({ data,  onClose, onFinish }: { data: {
  app_id: string,
  integration_id: string
}, onClose: () => void, onFinish?: () => void }) {
  const [loading, setLoading] = useState<boolean>(false)
  const [users, setUsers] = useState<any[]>([])
  const [selected, setSelected] = useState<any[]>([])

  useEffect(() => {
    if (data?.app_id) {
      (async () => {
        try {
          setLoading(true)
          const { data: resp } = await request.get('/integrations/slack/members', {
            params: {
              app_id: data.app_id,
              integration_id: data.integration_id
            }
          })
          setUsers(resp)
        } catch (error: any) {
          showNotification({
            icon: <IconX />,
            color: 'red',
            title: 'Something went wrong',
            message: error.response?.data.error || error.message || 'Please reload and try again',
          })
          onClose()
        } finally {
          setLoading(false)
        }
      })()
    }
  }, [data])

  const addMembers = async () => {
    setLoading(true)
    await new Promise(res => setTimeout(res, 1000))
    try {
      await request.post('/integrations/slack/addMembers', { users: selected }, {
        params: {
          app_id: data?.app_id,
          integration_id: data?.integration_id,
        }
      })
      showNotification({
        icon: <IconCheck />,
        color: 'green',
        title: 'Success',
        message: 'Users have been imported from Slack',
      })
      onFinish?.()
    } catch (error: any) {
      showNotification({
        icon: <IconX />,
        color: 'red',
        title: 'Something went wrong',
        message: error.response?.data.error || error.message || 'Please reload and try again',
      })
    } finally {
      setLoading(false)
    }
  }

  return <Drawer padding="xl" size={560} position="right" opened={!!data} onClose={onClose} title="Import Users">
    <div style={{ position: 'relative', minHeight: '80px' }}>
      <LoadingOverlay visible={loading} />
      <ScrollArea>
        <Table>
          <thead>
            <tr>
              <th>
                <Checkbox
                  checked={!!selected?.length}
                  indeterminate={selected?.length > 0 && selected?.length < users?.length}
                  onChange={({ target: { checked } }) => checked ? setSelected(users) : setSelected([])} />
              </th>
              <th>ID</th>
              <th style={{ minWidth: '110px' }}>Email</th>
              <th style={{ minWidth: '110px' }}>Name</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => <tr key={user.id}>
              <td>
                <Checkbox
                  checked={!!selected?.find(s => s.id === user.id)}
                  onChange={({ target: { checked } }) => setSelected([
                    ...selected.filter(s => s.id !== user.id), checked ? user : null
                  ].filter(Boolean))} />
              </td>
              <td>{user.id}</td>
              <td>{user.profile.email}</td>
              <td>{user.name}</td>
            </tr>)}
          </tbody>
        </Table>
      </ScrollArea>
      <Group position="right" mt="lg">
        <Button disabled={!selected?.length} onClick={addMembers} leftIcon={<IconDatabaseImport size={16} />}>
          Import
        </Button>
      </Group>
    </div>
  </Drawer>
}