import { useLocalStorage } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { AuthApiError, User } from '@supabase/supabase-js'
import { IconCheck, IconX } from '@tabler/icons'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { request } from '../services/request'
import { supabase } from '../services/supabase'
import { UserProfile } from '../types'
import useWebAuthn from './useWebAuthn'

export default function () {
  const [user, setUser] = useLocalStorage<UserProfile | null>({ key: 'user', defaultValue: null })
  const [_selectedProfile, setSelectedProfile] = useLocalStorage<Record<string, any> | null>({
    key: 'selected-profile', defaultValue: null })
  const navigate = useNavigate()
  const { auth } = useWebAuthn()
  const [loading, setLoading] = useState<boolean>(false)
  const [query, setQuery] = useSearchParams()

  const _done = async (user: User) => {
    await request.patch('/integrations/slack/updateUserProfile')

    const selectedProfile = JSON.parse(localStorage.getItem('selected-profile') || '{}')
    let { data } = await supabase
      .from('profiles')
      .select()
      .eq('user_id', user.id)

    // checking invitations
    const { data: invitations } = data?.length
      ? await supabase
        .from('invitations')
        .select()
        .is('deleted_at', null)
        .not('app_id', 'in', `(${(data.map((p: any) => p.app_id) || []).join(',')})`)
      : await supabase
        .from('invitations')
        .select()
        .is('deleted_at', null)

    if (invitations?.length) {
      const { data: createdProfiles } = await supabase
        .from('profiles')
        .insert(invitations?.map((i: any) => ({
          invitation_id: i.id as string,
          app_id: i.app_id as string,
          user_id: user.id as string,
          role: i.role as string,
          email: user?.email as string,
          name: user?.user_metadata['full_name'] || user?.user_metadata['name'] || user?.email
        }))).select()
      if (createdProfiles?.length) {
        data = (data || []).concat(createdProfiles)
        await supabase
          .from('invitations')
          .update({ deleted_at: new Date().toISOString() })
          .in('id', createdProfiles
            .map((c: any) => c.invitation_id).filter(Boolean))
      }
    }

    // continue selecting profile
    let selected = selectedProfile ? data?.find((p: any) => p.id === selectedProfile.id) : data?.[0]
    selected = selected || data?.[0]
    setSelectedProfile({
      ...selected || {},
      app: (
        await supabase
          .from('apps')
          .select('id, name, profiles_read, presences_read, leave_request_read, leave_request_write, leave_request_approval, salary_slips_read, salary_slips_write')
          .eq('id', selected?.app_id)
          .single()
      ).data
    })
    setUser({
      ...user,
      profiles: data || []
    })

    if (!data?.length) {
      navigate('/setup')
    } else if (sessionStorage.getItem('next')) {
      navigate(sessionStorage.getItem('next') as string)
      sessionStorage.removeItem('next')
    }
  }

  useEffect(() => {
    (async () => {
      const { error } = await supabase.auth.getUser()
      const { data } = await supabase.auth.getSession()
      if (data?.session) {
        Cookies.set('access_token', data.session.access_token, { expires: 1 })
        Cookies.set('refresh_token', data.session.refresh_token, { expires: 1 })
      }
      if (error instanceof AuthApiError) {
        console.error(error)
        await supabase.auth.signOut()
      }
    })()
  }, [supabase])

  return {
    user,
    loading,
    startAuth: useCallback(async () => {
      supabase.auth.onAuthStateChange(async (event, session) => {
        setLoading(true)
        if (event === 'SIGNED_IN' && session && (!localStorage.getItem('user') || localStorage.getItem('user') === 'null')) {
          const { user, ...otherSession } = session
          Cookies.set('access_token', otherSession.access_token, { expires: 1 })
          Cookies.set('refresh_token', otherSession.refresh_token, { expires: 1 })

          const { data: keys } = await supabase
            .from('security_keys')
            .select('id, last_verified_at')
            .eq('user_id', user.id)
            .not('credential_id', 'is', null)
          if (keys?.length) {
            try {
              const verification = await auth(keys, query.get('first-login') === '1')
              query.delete('first-login')
              setQuery(query)

              if (verification?.verified) {
                showNotification({
                  id: 'verification-succeed',
                  icon: <IconCheck />,
                  color: 'green',
                  title: 'Verification succeed',
                  message: 'Authentication has been verified successfully',
                })
              }
              await _done(user)
            } catch (error: any) {
              query.delete('first-login')
              setQuery(query)
              await supabase.auth.signOut()
              showNotification({
                id: 'verification-failed',
                icon: <IconX />,
                color: 'red',
                title: 'Verification failed',
                message: error.response?.data.error || error.message || 'Please reload and try again',
              })
            }
          } else {
            await _done(user)
          }
        } else if (event === 'SIGNED_OUT') {
          setUser(null)
          Cookies.remove('access_token')
          Cookies.remove('refresh_token')
          if (!['/', '/login', '/terms', '/privacy', '/support'].includes(location.pathname)) {
            sessionStorage.setItem('next', `${location.pathname}${location.search}`)
            navigate('/login')
          }
        }
        setLoading(false)
      })
    }, [localStorage, location]),
  }
}