import { ActionIcon, Anchor, Badge, Button, Container, Divider, Drawer, Group, Paper, Stack, Text, Title, Tooltip } from '@mantine/core'
import { RangeCalendar } from '@mantine/dates'
import { useLocalStorage } from '@mantine/hooks'
import { IconBrandSlack, IconCalendarEvent, IconDatabase, IconMail, IconMailbox, IconMessage, IconStatusChange, IconTextCaption, IconTimelineEvent, IconUserCheck, IconUserX } from '@tabler/icons'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Empty from '../../components/Empty'
import PaymentNeed from '../../components/PaymentNeed'
import SupabaseTable from '../../components/SupabaseTable'
import UserHeader from '../../components/UserHeader'
import usePayment from '../../hooks/usePayment'
import { supabase } from '../../services/supabase'
import ApprovalForm from './ApprovalForm'
import Form from './Form'

export default function () {
  const [selectedProfile] = useLocalStorage<Record<string, any> | null>({
    key: 'selected-profile', defaultValue: null })
  const [profile, setProfile] = useState<any>()
  const [errors, setErrors] = useState<string[]>([])
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [details, setDetails] = useState<any>()
  const [refetch, setRefetch] = useState<boolean>(false)
  const params = useParams()
  const [valid, setValid] = useState<boolean>(true)
  const { check } = usePayment()

  useEffect(() => {
    if (selectedProfile?.app_id) {
      check().then((value: any) => setValid(value))
    }
  }, [selectedProfile])

  useEffect(() => {
    if (params.id) {
      (async () => {
        const { data, error } = await supabase
          .from('profiles')
          .select()
          .eq('id', params.id)
        if (data?.length) {
          setProfile(data[0])
        } else {
          if (error) {
            setErrors([...errors || [], error.message])
          } else {
            setErrors([...errors || [], 'Profile data not found.'])
          }
        }
      })()
    }
  }, [params?.id])

  return valid ? <Container mb="xl" mt="xl">
    {errors?.length ? <div style={{ textAlign: 'center', marginTop: '140px' }}>
      <Title order={3}>
        Something wrong 😱
      </Title>
      {errors.map((error, index) => <Text size="sm" color="dimmed" component="p" key={index}>{error}</Text>)}
    </div> : <UserHeader profile={profile} breadcrumbs={[
      { label: 'Home', link: '/' },
      { label: 'Leave Requests', link: '/leave' },
      { label: profile?.name, active: true },
    ]} />}
    {selectedProfile?.id === params.id && (selectedProfile?.app?.leave_request_write || []).includes(selectedProfile?.role) && <Group position="right" mb="lg">
      <Button onClick={() => setOpenForm(true)} variant="light" color="pink" leftIcon={<IconMailbox size={18} />}>
        Submit Leave Request
      </Button>
    </Group>}

    <SupabaseTable
      query={opt => supabase
        .from('leave_requests')
        .select(`
          id,
          year,
          started_at,
          ended_at,
          status,
          reason,
          comment,
          duration_in_days,
          count_as_paid_leave,
          created_at,
          updated_at,
          profiles:profiles!leave_requests_profile_id_fkey!inner (
            id,
            app_id,
            name,
            integration_profile_data
          ),
          updated_by:profiles!leave_requests_updated_by_fkey!outter (
            id,
            app_id,
            name,
            email,
            integration_profile_data
          )
        `, opt?.checkCount ? { count: 'estimated', head: true } : undefined)
        .eq('profiles.app_id', profile?.app_id)
        .eq('profiles.app_id', selectedProfile?.app_id)
        .eq('profiles.id', profile?.id)}
      dependencies={[profile?.app_id, selectedProfile?.app_id, profile?.id]}
      columns={[
        {
          key: 'started_at',
          label: 'Date Range',
          minWidth: 160,
          sort: 'started_at',
          render: (value: any, row: any) => <Anchor onClick={() => setDetails(row)}>
            {dayjs(value).format('YYYY, MMM')} {dayjs(value).format('D')}{(() => {
              const result = `${dayjs(value).format('MMM') !== dayjs(row.ended_at).format('MMM')
                ? dayjs(row.ended_at).format('MMM') : ''} ${
                dayjs(value).format('D') !== dayjs(row.ended_at).format('D')
                  ? dayjs(row.ended_at).format('D') : ''}`
              return result.replaceAll(' ', '') ? ` - ${result}` : ''
            })()}
          </Anchor>
        },
        {
          key: 'year',
          label: 'Year',
          minWidth: 100,
          sort: 'year'
        },
        {
          key: 'status',
          label: 'Status',
          sort: 'status',
          minWidth: 140,
          render: (value: any) => {
            switch (value) {
              case 'pending':
                return <Badge color="yellow">Pending</Badge>
              case 'approved':
                return <Badge color="green">Approved</Badge>
              case 'rejected':
                return <Badge color="red">Rejected</Badge>
              default:
                return <Badge color="gray">Unknown</Badge>
            }
          }
        },
        {
          key: 'reason',
          label: 'Reason',
          minWidth: 260,
          sort: 'reason',
          render: val => <Text style={{ wordBreak: 'break-word' }}>{val}</Text>
        },
      ]}
      empty={<Empty
        label="Something wrong"
        description="No data was found."
        icon={<IconDatabase />} />}
      initialLoading
      pageSize={20}
      refetch={[refetch, setRefetch]}
      defaultOrder={['created_at', { ascending: false, foreignTable: '' }]}
    />

    <Drawer padding="xl" size="xl" position="right" opened={!!details} onClose={() => setDetails(undefined)} title="Leave Details">
      <div style={{ position: 'relative' }}>
        {/* <LoadingOverlay visible={loading} /> */}
        <Group>
          <IconStatusChange />
          <div>
            {(() => {
              switch (details?.status) {
                case 'pending':
                  return <Badge color="yellow">Pending</Badge>
                case 'approved':
                  return <Badge color="green">Approved</Badge>
                case 'rejected':
                  return <Badge color="red">Rejected</Badge>
                default:
                  return <Badge color="gray">Unknown</Badge>
              }
            })()}
            {details?.count_as_paid_leave && <Text component="span" color="dimmed">&nbsp;(paid leave)</Text>}
          </div>
        </Group>

        <Group mt="lg" align="start">
          <IconCalendarEvent />
          <div>
            <div>
              <Text mb="sm">
                {dayjs(details?.started_at).format('MMM DD, HH:mm')} - {dayjs(details?.ended_at).format('MMM DD, HH:mm')}
                {details?.duration_in_days ? <Text component="span" color="dimmed">&nbsp;({details?.duration_in_days}d)</Text> : ''}
              </Text>
              <RangeCalendar value={[new Date(details?.started_at), new Date(details?.ended_at)]} initialMonth={new Date(details?.started_at)} onChange={() => {}} />
            </div>
          </div>
        </Group>

        <Group mt="lg">
          <IconTimelineEvent />
          <div>
            <Text size="sm">
              Submitted at:
            </Text>
            <Text>
              {dayjs(details?.created_at).format('MMM DD, HH:mm')}
            </Text>
          </div>
        </Group>

        <Group mt="lg">
          <IconTextCaption />
          <div style={{ width: 'calc(100% - 44px)' }}>
            <Text size="sm">
              Reason:
            </Text>
            <Text italic style={{ wordBreak: 'break-word' }}>
              {details?.reason || '-'}
            </Text>
          </div>
        </Group>

        {details?.status !== 'pending' && <Group mt="lg">
          {details?.status === 'approved' ? <IconUserCheck /> : <IconUserX />}
          <div>
            <Text size="sm">
              <Text component="span" style={{ textTransform: 'capitalize' }}>{details?.status}</Text> by:
            </Text>
            <Text>
              <Group>
                <Text component="span">{details?.updated_by?.name}</Text>
                <Tooltip withArrow label={`Ask @${details?.updated_by.integration_profile_data?.slack?.profile.display_name} via Slack`}>
                  <ActionIcon component="a" target="_blank" href={`https://slack.com/app_redirect?channel=${details?.updated_by.integration_profile_data?.slack?.id}`}><IconBrandSlack size={22} /></ActionIcon>
                </Tooltip>
                <Tooltip withArrow label={`Ask ${details?.updated_by.email} via email`}>
                  <ActionIcon component="a" target="_blank" href={`mailto:${details?.updated_by.email}`}><IconMail size={22} /></ActionIcon>
                </Tooltip>
              </Group>
            </Text>
          </div>
        </Group>}

        {details?.status !== 'pending' && details?.comment && <Group mt="lg">
          <IconMessage />
          <div>
            <Text size="sm">
              Comment:
            </Text>
            <Text>
              {details?.comment}
            </Text>
          </div>
        </Group>}
        <Text size="sm" color="dimmed" mt="xl" italic>
          Only {selectedProfile?.app?.leave_request_approval.join(' or ')} can approve/reject the leave request.
        </Text>
        {(selectedProfile?.app?.leave_request_approval || []).includes(selectedProfile?.role) && <Stack mt="sm">
          <Divider label="Approval area" />
          <Paper withBorder p="lg">
            <ApprovalForm data={details} onFinish={() => {
              setRefetch(true)
              setDetails(undefined)
            }} />
          </Paper>
        </Stack>}
      </div>
    </Drawer>
    <Form open={openForm} setOpen={setOpenForm} onFinish={() => setRefetch(true)} />
  </Container> : <PaymentNeed />
}