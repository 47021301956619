import { startAuthentication, startRegistration } from '@simplewebauthn/browser'
import { AuthenticationCredentialJSON, RegistrationCredentialJSON } from '@simplewebauthn/typescript-types'
import dayjs from 'dayjs'
import { useState } from 'react'
import { v4 } from 'uuid'
import { request } from '../services/request'
import { supabase } from '../services/supabase'

export default function () {
  const [loading, setLoading] = useState<boolean>(false)

  const register = async (name: string, isPasskey: boolean = false) => {
    setLoading(true)

    const { data: options } = await request.post('/security/register', { name, is_passkey: isPasskey })
    const resp: RegistrationCredentialJSON = await startRegistration(options)

    const { data: verification } = await request.post('/security/verifyRegister', resp)
    if (!verification.verified) {
      setLoading(false)
      throw verification
    }
    setLoading(false)
  }

  const auth = async (keys?: any[], force?: boolean) => {
    setLoading(true)
    if (!force && keys?.length && keys?.find((k: any) =>
      dayjs.duration(
        new Date().getTime() - new Date(k.last_verified_at).getTime()
      ).asHours() < 15)) {
      return setLoading(false)
    }

    const { data: options } = await request.post('/security/auth')
    const resp: AuthenticationCredentialJSON = await startAuthentication(options)

    const { data: verification } = await request.post('/security/verifyAuth', resp)
    if (!verification.verified) {
      setLoading(false)
      throw verification
    }
    if (keys?.length) {
      await supabase.from('security_keys').update({
        last_verified_at: new Date().toISOString()
      }).in('id', keys?.map(k => k.id))
    }
    setLoading(false)
    return verification
  }

  const authPassLess = async () => {
    setLoading(true)
    const uuid = v4()
    try {
      const { data: options } = await request.post('/security/experimental/auth', {}, {
        params: { window_id: uuid }
      })
      const resp: AuthenticationCredentialJSON = await startAuthentication(options)

      const { data: verification } = await request.post('/security/experimental/verifyAuth', resp, {
        params: { window_id: uuid }
      })
      if (!verification.verified) {
        setLoading(false)
        throw verification
      }
      setLoading(false)
      return verification.action_link
    } catch (error) {
      setLoading(false)
      throw error
    }

  }

  return { loading, register, auth, authPassLess }
}