import { Anchor, Box, Button, Drawer, Group, Input, Loader, Select, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { IconCheck, IconPlugConnected, IconPlugOff, IconQuestionMark, IconUsers, IconX } from '@tabler/icons'
import { useEffect, useState } from 'react'
import { request } from '../../../../services/request'
import ImportUsers from './ImportUsers'

interface SlackRuleConfigs {
  token: string,
  team_id?: string,
  channel?: string
}

export default function ({ data, includeImportButton = true, onSave, setLoading }: {
  data: {
    app_id: string,
    integration_id: string,
    configs?: SlackRuleConfigs
  },
  includeImportButton?: boolean,
  onSave?: () => void,
  setLoading?: (loading: boolean) => void
}) {
  const [channels, setChannels] = useState<any[]>([])
  const [openHelp, setOpenHelp] = useState<boolean>(false)
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false)
  const [openImportUsers, setOpenImportUsers] = useState<any>()

  const form = useForm<SlackRuleConfigs>()

  useEffect(() => {
    if (data?.app_id) {
      request.get('/integrations/slack/channels', {
        params: {
          app_id: data.app_id
        }
      }).then(({ data }) => {
        setChannels(data?.map((ch: any) => ({ value: ch.id, label: ch.name })))
      })
    }
  }, [data])

  useEffect(() => {
    form.setValues(data?.configs || {})
  }, [data?.configs])

  const submit = async (values: SlackRuleConfigs) => {
    if (!values?.channel) {
      return form.setFieldError('notifications.channel', 'Channel is required')
    }
    setLoading?.(true)
    await new Promise(res => setTimeout(res, 1000))
    try {
      await request.post(`/integrations/${data?.configs ? 'update' : 'create'}`, values, {
        params: {
          app_id: data?.app_id,
          integration_id: data?.integration_id,
        }
      })
      showNotification({
        icon: <IconCheck />,
        color: 'green',
        title: 'Success',
        message: 'Slack integration has been enabled',
      })
      form.reset()
      onSave?.()
    } catch (error: any) {
      console.error(error)
      showNotification({
        icon: <IconX />,
        color: 'red',
        title: 'Something went wrong',
        message: error.response?.data.error || error.message || 'Please reload and try again',
      })
    } finally {
      setLoading?.(false)
    }
  }

  const disable = async () => {
    setLoading?.(true)
    await new Promise(res => setTimeout(res, 1000))
    try {
      await request.delete('/integrations/delete', {
        params: {
          app_id: data?.app_id,
          integration_id: data?.integration_id,
        }
      })
      showNotification({
        icon: <IconCheck />,
        color: 'green',
        title: 'Success',
        message: 'Slack integration has been disabled',
      })
      setOpenDeleteConfirmation(false)
      onSave?.()
    } catch (error: any) {
      showNotification({
        icon: <IconX />,
        color: 'red',
        title: 'Something went wrong',
        message: error.response?.data.error || error.message || 'Please reload and try again',
      })
    } finally {
      setLoading?.(false)
    }
  }

  return <>
    {data?.configs ? <Box>
      {includeImportButton && <Button size="sm" variant="light" leftIcon={<IconUsers size={12} />} onClick={() => setOpenImportUsers(data)}>
        Import users
      </Button>}
    </Box> : <Group position="apart" spacing="xs">
      <Button size="sm" variant="subtle" color="dimmed" onClick={() => setOpenHelp(true)} rightIcon={<IconQuestionMark size={16} />}>
        Help
      </Button>
      <Anchor component="a" href={`https://slack.com/oauth/v2/authorize?client_id=274004635299.4322262933249&scope=chat:write,chat:write.public,emoji:read,users.profile:read,users:read,users:read.email,channels:read,groups:read,mpim:read,im:read&user_scope=&state=${data?.app_id}`}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></Anchor>
    </Group>}
    <form onSubmit={form.onSubmit(values => submit(values))}>
      {data?.configs?.token && <>
        <Input.Wrapper
          mt="sm"
          label="Channel announcement"
          withAsterisk
          required>
          <Select
            searchable
            data={channels}
            defaultValue={data?.configs?.channel}
            rightSection={!channels?.length && <Loader size={20} />}
            {...form.getInputProps('channel')} />
        </Input.Wrapper>
        <Group mt="xl" position="right">
          {data?.configs && <Button onClick={() => setOpenDeleteConfirmation(true)} variant="light" color="red" leftIcon={<IconPlugOff size={20} />}>
            Disable
          </Button>}
          <Button type="submit" leftIcon={<IconPlugConnected size={20} />}>
            {data?.configs ? 'Update' : 'Enable'}
          </Button>
        </Group>
      </>}
    </form>
    <Drawer size="xl" padding="xl" position="right" opened={openHelp} onClose={() => setOpenHelp(false)} title="How to connect with Slack?">
      <Text component="p">
        <ol>
          <li>
            Click <Anchor href={`https://slack.com/oauth/v2/authorize?client_id=274004635299.4322262933249&scope=chat:write,chat:write.public,emoji:read,users.profile:read,users:read,users:read.email,channels:read,groups:read,mpim:read,im:read&user_scope=&state=${data?.app_id}`}>Add to Slack</Anchor> to add hours.online app to your Slack workspace.
          </li>
          <li>
            After you click the link, you will be redirected to Slack. Log in to your workspace and read what hours.online app is able to view and will do.
          </li>
          <li>
            Select your primary channel to receive daily, and weekly reports and other notifications.
          </li>
          <li>
            Click <Text color="blue" component="span">Allow</Text> to grant hours.online app to access your Slack workspace.
          </li>
          <li>
            You will be redirected to hours.online app and you can set up your notifications.
          </li>
        </ol>
      </Text>
    </Drawer>
    <Drawer size="xl" padding="xl" position="right" opened={openDeleteConfirmation} onClose={() => setOpenDeleteConfirmation(false)} title="Disable confirmation">
      <Text component="p">
        Are you sure to remove this configuration?
      </Text>
      <Text size="sm" color="dimmed">
        This action can not be undone.
      </Text>
      <Group mt="xl" position="right">
        <Button onClick={() => setOpenDeleteConfirmation(false)} variant="light" color="red">
          Cancel
        </Button>
        <Button onClick={disable} color="red">
          Delete
        </Button>
      </Group>
    </Drawer>
    <ImportUsers data={openImportUsers} onClose={() => setOpenImportUsers(undefined)} onFinish={() => {
      setOpenDeleteConfirmation(false)
      onSave?.()
    }} />
  </>
}