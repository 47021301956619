import { Button, Drawer, Group } from '@mantine/core'
import { IconPencil, IconPlus, IconTrash } from '@tabler/icons'
import { useState } from 'react'
import SupabaseTable from '../../../../components/SupabaseTable'
import { supabase } from '../../../../services/supabase'
import Form from './Form'

export default function ({ app, onClose }: { app: any, onClose: () => void }) {
  const [openForm, setOpenForm] = useState<any>()
  const [refetch, setRefetch] = useState<boolean>(false)

  const remove = async (id: string) => {
    await supabase
      .from('notifications')
      .delete()
      .eq('id', id)
    setRefetch(true)
  }

  return <Drawer padding="xl" size="xl" position="right" opened={!!app} onClose={onClose} title="Notifications">
    <div style={{ position: 'relative' }}>
      <Group mb="lg" position="left">
        <Button size="sm" onClick={() => setOpenForm({ app_id: app?.id })} leftIcon={<IconPlus size={18} />}>Add notification</Button>
      </Group>
      <SupabaseTable
        query={(opt: any) => supabase
          .from('notifications')
          .select(`
            *,
            "groups" (
              name
            )
          `, opt?.checkCount ? { count: 'estimated', head: true } : undefined)
          .eq('app_id', app.id)}
        dependencies={[app?.id]}
        columns={[
          {
            key: 'groups.name',
            label: 'Group',
            render: (value) => value ?? 'Default'
          },
          {
            key: 'id',
            label: '',
            render: (value, row) => <Group>
              <Button leftIcon={<IconPencil size={14} />} size="xs" variant="light" onClick={() => setOpenForm(row)}>Update</Button>
              {row.group_id && <Button leftIcon={<IconTrash size={14} />} size="xs" color="red" variant="light" onClick={() => remove(value)}>Delete</Button>}
            </Group>
          }
        ]}
        refetch={[refetch, setRefetch]}
      />
    </div>
    <Form data={openForm} onClose={() => setOpenForm(undefined)} onSave={() => setRefetch(true)} />
  </Drawer>
}