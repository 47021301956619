import { Container, Group, Stack, Text, Title } from '@mantine/core'
import { ReactNode } from 'react'

export default function ({ label, description, icon }: { label: string, description: string, icon?: ReactNode }) {
  return <Container sx={() => ({
    minHeight: 'calc(100vh - 389px)', '@media (max-width: 767px)': { minHeight: 'calc(100vh - 390px)' }
  })} style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative' }}>
    <Stack spacing="xs">
      <Group position="center">
        <Title order={3}>
          {label}
        </Title>
        {icon}
      </Group>
      <Text size="sm" color="dimmed" component="p">
        {description}
      </Text>
    </Stack>
  </Container>
}