import { ActionIcon, Anchor, Avatar, Divider, Group, Navbar, NavLink, ScrollArea, Stack, Text, UnstyledButton } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { IconChevronRight, IconMoon, IconSun } from '@tabler/icons'
import { Link, useNavigate } from 'react-router-dom'
import useNavigation from '../hooks/useNavigation'
import { UserProfile } from '../types'

export default function () {
  const { navs } = useNavigation()
  const navigate = useNavigate()
  const [user] = useLocalStorage<UserProfile | null>({ key: 'user', defaultValue: null })
  const [selectedProfile] = useLocalStorage<Record<string, any> | null>({
    key: 'selected-profile', defaultValue: null })
  const [scheme, setScheme] = useLocalStorage({ key: 'color-scheme', defaultValue: 'light' })

  return <Navbar p="md" width={{ lg: 300 }} hiddenBreakpoint="lg" withBorder>
    <Navbar.Section pl="xs" mt="md" mb="md">
      <Group position="apart">
        <UnstyledButton onClick={() => navigate('/')}>
          <Group spacing={0}>
            <img src="/logo192.png" alt="logo" style={{
              height: 35, marginRight: '10px' }} />
            <Group spacing={6}>
              <Text size={22} weight="bolder">
                hours.online
              </Text>
              <Text size={12}>Beta</Text>
            </Group>
          </Group>
        </UnstyledButton>
        <ActionIcon size="lg" variant="subtle" onClick={() => setScheme(scheme === 'light' ? 'dark' : 'light')}>
          {scheme === 'light' ? <IconMoon size={22} /> : <IconSun size={22} />}
        </ActionIcon>
      </Group>
    </Navbar.Section>
    <Navbar.Section grow mt="lg" component={ScrollArea}>
      {navs.map((item: any) => <NavLink active={item.active} style={{ borderRadius: 8 }}
        label={<Text size="md">{item.label}</Text>} key={item.key} icon={<item.icon />} onClick={item.onClick} mb="xs" p="md" />)}
    </Navbar.Section>
    <Navbar.Section>
      <Stack>
        {/* <Divider size={0.1} /> */}
        <UnstyledButton onClick={() => navigate('/profile')}>
          <Group position="apart" spacing="xs" pt="sm" pb="sm">
            <Group pl="xs" style={{ width: 'calc(100% - 54px)' }}>
              <Avatar alt="profile picture" radius="xl" {...selectedProfile?.integration_profile_data?.slack?.profile?.image_48 ? { src: selectedProfile?.integration_profile_data?.slack?.profile?.image_48 } : {}}>
                {!selectedProfile?.integration_profile_data?.slack?.profile?.image_48 ? selectedProfile?.name?.split(' ').map((w: string) => w[0]).slice(0, 2).join('') : <></>}
              </Avatar>
              <Stack spacing={0} style={{ width: 'inherit' }}>
                <Text style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{selectedProfile?.name || user?.user_metadata?.['full_name']}</Text>
                <Text style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} size="xs" color="dimmed">{selectedProfile?.app?.name || 'Unknown'}</Text>
              </Stack>
            </Group>
            <Text color="dimmed"><IconChevronRight /></Text>
          </Group>
        </UnstyledButton>
        <Divider size={0.1} />
        <Group position="center" spacing="xs">
          <Text color="dimmed" size="xs">v0.1.0</Text>
          <Text color="dimmed">&bull;</Text>
          <Anchor size="xs" component={Link} to="/terms">Terms</Anchor>
          <Text color="dimmed">&bull;</Text>
          <Anchor size="xs" component={Link} to="/privacy">Privacy</Anchor>
          <Text color="dimmed">&bull;</Text>
          <Anchor size="xs" component={Link} to="/support">Support</Anchor>
        </Group>
      </Stack>
    </Navbar.Section>
  </Navbar>
}