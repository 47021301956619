import { Button, Drawer, Input, LoadingOverlay, Text } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { useLocalStorage } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { IconCheck, IconX } from '@tabler/icons'
import { useState } from 'react'
import { TeamInterface } from '.'
import { supabase } from '../../../services/supabase'
import { UserProfile } from '../../../types'

export default function ({ form }: { form: UseFormReturnType<TeamInterface, (values: TeamInterface) => TeamInterface> }) {
  const [user, setUser] = useLocalStorage<UserProfile | null>({ key: 'user', defaultValue: null })
  const [deleteConfirmation, setDeleteConfirmation] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const deleteApp = async (id: string) => {
    setLoading(true)
    await new Promise(res => setTimeout(res, 1000))
    try {
      await supabase.from('billings').delete().eq('app_id', id)
      await supabase.from('enabled_integrations').delete().eq('app_id', id)
      await supabase.from('notifications').delete().eq('app_id', id)
      await supabase.from('groups').delete().eq('app_id', id)
      await supabase.from('profiles').delete().eq('app_id', id)
      await supabase.from('invitations').delete().eq('app_id', id)
      await supabase.from('apps').delete().eq('id', id)
      setUser({
        ...user,
        profiles: user?.profiles?.filter((profile: any) => profile.app_id !== id),
      } as any)
      form.reset()
      setDeleteConfirmation('')
      showNotification({
        id: 'app-deletion-succeed',
        icon: <IconCheck />,
        color: 'green',
        title: 'Team deleted successfully',
        message: 'Your team has been deleted',
      })
    } catch (error: any) {
      showNotification({
        id: 'app-deletion-failed',
        icon: <IconX />,
        color: 'red',
        title: 'Something went wrong',
        message: error.response?.data.error || error.message || 'Please reload and try again',
      })
    } finally {
      setLoading(false)
    }
  }

  return <Drawer padding="xl" size="xl" position="right" opened={!!form.values.id} onClose={() => form.reset()} title="Delete confirmation">
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} />
      <Text component="p">Are you sure you want to delete <Text component="strong">{form.values?.name}</Text>?</Text>
      <form onSubmit={form.onSubmit(values => deleteApp(values.id as string))}>
        <Input.Wrapper>
          <Input placeholder="Type your team name for confirmation..." value={deleteConfirmation} onChange={(e?: any) => setDeleteConfirmation(e?.target.value)} />
        </Input.Wrapper>
        <div style={{ textAlign: 'right' }}>
          <Button mt="md" color="red" type="submit" disabled={deleteConfirmation !== form.values?.name}>
            Delete
          </Button>
        </div>
      </form>
    </div>
  </Drawer>
}