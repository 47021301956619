import { Accordion, Drawer, Group, LoadingOverlay, Text } from '@mantine/core'
import { IconPlugConnected } from '@tabler/icons'
import { useEffect, useState } from 'react'
import { request } from '../../../services/request'
import { supabase } from '../../../services/supabase'
import Integration from './Integration/index'

export default function ({ app, setApp }: { app: any, setApp: (app: any) => void }) {
  const [loading, setLoading] = useState<boolean>(false)
  const [integrations, setIntegrations] = useState<any[]>([])
  const [enabledIntegrations, setEnabledIntegrations] = useState<any[]>([])
  const [value, setValue] = useState<string | null>(null)

  const fetchIntegrations = async () => {
    setLoading(true)
    await Promise.allSettled([
      (async () => {
        const { data: integrations } = await supabase
          .from('integrations')
          .select()
        setIntegrations(integrations || [])
      })(),
      (async () => {
        const { data } = await request.get('/integrations/list', {
          params: { app_id: app.id }
        })
        setEnabledIntegrations(data || [])
      })()
    ])
    setLoading(false)
    setValue('slack')
  }

  useEffect(() => {
    if (app) {
      fetchIntegrations()
    }
  }, [app])

  return <Drawer padding="xl" size="xl" position="right" opened={!!app} onClose={() => setApp(undefined)} title="Integrations">
    <div style={{ position: 'relative', minHeight: '80px' }}>
      <LoadingOverlay visible={loading} />
      <Accordion variant="default" value={value} onChange={setValue}>
        {integrations.map((integration: any) => <Accordion.Item value={integration.name.toLowerCase()} key={integration.id}>
          <Accordion.Control icon={integration.logo_url ? <img src={integration.logo_url} style={{ height: '20px' }} /> : <IconPlugConnected size={20} />}>
            <Group position="apart">
              <Text component="div">{integration.name}</Text>
              <Text component="div" size="sm" color={enabledIntegrations?.find((e: any) => e.integration_id === integration.id) ? 'blue' : 'dimmed'}>
                {enabledIntegrations?.find((e: any) => e.integration_id === integration.id) ? 'Enabled' : 'Disabled'}
              </Text>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            {!loading && <Integration name={integration.name} setLoading={setLoading} data={{
              app_id: app?.id,
              integration_id: integration.id,
              ...enabledIntegrations?.find((e: any) => e.integration_id === integration.id)
            }} onSave={() => {
              fetchIntegrations()
              setValue(null)
            }} />}
          </Accordion.Panel>
        </Accordion.Item>)}
      </Accordion>
    </div>
  </Drawer>
}